import { posthog } from "posthog-js";
import { useEffect } from "react";
import PostHogEvents from "@/utils/postHogEvents";
import { analyticsAccepted } from "@/utils/analyticsAccepted";
import { useLocation } from "react-router";
import { PostHogEvent } from "@/utils/interface/PostHogEvent";
import { getOnboardingStepFromCurrentRoute } from "@/context/TrackingContext";
import { PathValues } from "@/routing/AllRoutes";
import conf from "@/env";

export const initializeTracking = () => {
  if (analyticsAccepted()) {
    posthog.init(conf.postHogApiKey, {
      // eslint-disable-next-line camelcase
      api_host: conf.postHogApiHost,
      autocapture: false,
      // eslint-disable-next-line camelcase
      capture_pageview: false
    });
  }
};

export const usePageViewTracking = () => {
  const location = useLocation();

  const locationPathName = location.pathname as PathValues;

  useEffect(() => {
    posthog.capture("$pageview", { Component: getOnboardingStepFromCurrentRoute() });
    const pageStartTime = Date.now();
    return () => {
      trackEvent(PostHogEvents.timeOnPage(pageStartTime, locationPathName));
    };
  }, [locationPathName]);
};

export const trackEvent = ({ eventName, properties }: PostHogEvent) => {
  posthog.capture(eventName, properties, { timestamp: new Date() });
};

const identify = () => {
  if (analyticsAccepted()) {
    // const id = Storage.get(CPO_ONBOARDING_ID);
    // Storage.set(ONBOARDING_START_TIME, Date.now().toString());
    // posthog.identify(id);
  }
};

const reset = () => {
  // if (analyticsAccepted() && Storage.get(ONBOARDING_START_TIME)) {
  //   trackEvent(postHogEvents.onboardingTime());
  //   posthog.reset(true);
  // }
};

export const usePostHog = (): {
  trackEvent: (event: PostHogEvent) => void;
  identify: () => void;
  reset: () => void;
} => {
  return { trackEvent, identify, reset };
};
