import React, { useMemo, useState } from "react";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import { BorderPanel } from "@/components/Panel/styles";
import { Flex, useToast } from "@emonvia/web-components";
import Table from "@/components/Table/Table";
import useModelConfigurationColumns from "@/containers/ModelConfigurations/hooks/useModelConfigurationColumns";
import { IConfigurationTemplate, IModelDetails } from "@/api/ApiTypes";
import EditModelFallbackConfigurationModal from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/modals/EditModelFallbackConfigurationModal";
import {
  fallbackConfigurationMapper,
  filterAuthorizationConfigurationKeys,
  filterDefaultConfigurationKeys,
  formValuesToRequest
} from "@/containers/VendorConfigurations/utils/mapper";
import { EditConfigKeysFormValues } from "@/components/EditConfigModal/hooks/useEditConfigForm";
import ReevApiCalls from "@/api/ReevApiCalls";

interface IProps extends Partial<IModelDetails> {
  modelFallbackConfigurationTemplates: IConfigurationTemplate[];
  refetchDetails: () => void;
}

const ModelFallbackConfigurationTemplateSection: React.FC<IProps> = ({
  modelFallbackConfigurationTemplates,
  vendor,
  model,
  refetchDetails
}) => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useToast();
  const [isModelFallbackModalOpen, setIsModelFallbackModalOpen] = useState(false);

  const defaultConfigColumns = useModelConfigurationColumns();
  const authConfigColumns = useModelConfigurationColumns(true);

  const config = useMemo(
    () => fallbackConfigurationMapper(modelFallbackConfigurationTemplates),
    [modelFallbackConfigurationTemplates]
  );
  const defaultConfigs = useMemo(() => filterDefaultConfigurationKeys(config), [config]);
  const authorizationConfigs = useMemo(
    () => filterAuthorizationConfigurationKeys(config),
    [config]
  );

  const onEditConfigFirmwareSubmit = async (values: EditConfigKeysFormValues) => {
    const configValues = formValuesToRequest(values);
    try {
      await ReevApiCalls.updateModelFallback({
        pathParams: { vendor, model },
        payload: { ...configValues }
      });
      notifySuccess(t("CONFIGURATION_SUBMITTED_SUCCESSFULLY"));
      setIsModelFallbackModalOpen(false);
      refetchDetails();
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  return (
    <BorderPanel
      title={t("MODEL_FALLBACK_CONFIGURATION_TEMPLATE")}
      tooltipText={t("THE_CONFIGURATION_TEMPLATE_FOR_THE_MODEL_FOR_UNKNOWN_FIRMWARE_VERSION")}
      onEditClick={() => setIsModelFallbackModalOpen(true)}
      showAction
    >
      <Flex flexDirection="column" width={"100%"}>
        <Table
          data={defaultConfigs}
          columns={defaultConfigColumns}
          meta={{}}
          inTotalTranslation={t("IN_TOTAL")}
        />
        {authorizationConfigs.length > 0 && (
          <Table
            data={authorizationConfigs}
            columns={authConfigColumns}
            meta={{}}
            inTotalTranslation={t("IN_TOTAL")}
          />
        )}
      </Flex>
      {isModelFallbackModalOpen && (
        <EditModelFallbackConfigurationModal
          onClose={() => setIsModelFallbackModalOpen(false)}
          onSubmit={onEditConfigFirmwareSubmit}
          config={config}
        />
      )}
    </BorderPanel>
  );
};
export default ModelFallbackConfigurationTemplateSection;
