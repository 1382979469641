import Dropdown from "@/components/Dropdown/Dropdown";
import SearchBox from "@/components/SearchBox/SearchBox";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import { Flex, isNotEmpty, useToast } from "@emonvia/web-components";
import { FC } from "react";
import { useQuery } from "react-query";
import ReevApiCalls from "@/api/ReevApiCalls";
import { ApiErrorResponse } from "@/utils/enums/ApiErrorResponse";
import { OptionsType } from "@/@types/utils";
import ModelQueryKeys from "@/containers/ModelConfigurations/utils/QueryKeys";
import { useVendorsQuery } from "@/containers/VendorConfigurations/hooks/useVendorsQuery";

export type IModelConfigurationsFilters = {
  searchQuery?: string;
  vendor?: string;
  model?: string;
};

type IProps = {
  onChange: (filters: IModelConfigurationsFilters) => void;
  filters: IModelConfigurationsFilters;
};

const ModelConfigurationsTableFilters: FC<IProps> = ({ onChange, filters }) => {
  const { t } = useTranslation();
  const { notifyError } = useToast();

  const { data: vendorsData, isLoading: isVendorsLoading } = useVendorsQuery({});

  const vendorOptions: OptionsType<string> = vendorsData?.vendors.map((vendor: string) => ({
    label: vendor,
    value: vendor
  }));

  const { data: models, isLoading: isModelLoading } = useQuery(
    [ModelQueryKeys.MODEL_LIST, filters.vendor],
    () =>
      ReevApiCalls.getSupportedModelsByVendor({
        queryParams: null,
        pathParams: { vendor: filters.vendor }
      }),
    {
      enabled: isNotEmpty(filters.vendor),
      onError: (err: ApiErrorResponse) => notifyError(translateError(err))
    }
  );

  const modelOptions: OptionsType<string> = models?.map(({ name }) => ({
    label: name,
    value: name
  }));

  const handleFilterChange = (newFilters: IModelConfigurationsFilters) => {
    onChange({ ...filters, ...newFilters });
  };

  const onReset = () => {
    onChange({});
  };

  return (
    <Flex gap={20}>
      <Dropdown
        placeholder={t("VENDOR")}
        options={vendorOptions}
        value={filters.vendor}
        onChange={(vendor) => handleFilterChange({ vendor })}
        width="277px"
        isLoading={isVendorsLoading}
        onReset={onReset}
      />
      <Dropdown
        placeholder={t("MODEL")}
        options={modelOptions}
        value={filters.model}
        onChange={(model) => handleFilterChange({ model })}
        width="277px"
        isLoading={isModelLoading}
        disabled={!filters.vendor}
      />
      <SearchBox
        allowClear
        onChange={(searchQuery) => handleFilterChange({ searchQuery })}
        label={t("SEARCH")}
      />
    </Flex>
  );
};

export default ModelConfigurationsTableFilters;
