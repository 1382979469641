import { requiredStringSchemaWithMaxChars } from "@/utils/validation/schemas";
import { isNotEmpty } from "@emonvia/web-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InferType, array, object, string } from "yup";

const defaultConfigurationKeySchema = object().shape(
  {
    key: string().when("value", {
      is: (value: string) => isNotEmpty(value),
      then: (schema) => requiredStringSchemaWithMaxChars({ schema, max: 50 })
    }),
    value: string().when("key", {
      is: (key: string) => isNotEmpty(key),
      then: (schema) => requiredStringSchemaWithMaxChars({ schema, max: 500 })
    })
  },
  [
    ["key", "value"],
    ["value", "key"]
  ]
);

const defaultConfigurationKeysSchema = object().shape({
  configs: array().of(defaultConfigurationKeySchema).nullable().notRequired()
});

export type AddDefaultKeysFormValues = InferType<typeof defaultConfigurationKeysSchema>;

export default function useAddDefaultKeysForm() {
  return useForm({
    resolver: yupResolver<AddDefaultKeysFormValues>(defaultConfigurationKeysSchema),
    mode: "onChange",
    defaultValues: {
      configs: [
        {
          key: "",
          value: ""
        }
      ]
    }
  });
}
