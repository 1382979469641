import { StyledLabelWithSublabel } from "@/containers/NewIntegration/Steps/Report/styles";
import { FC } from "react";

type IProps = {
  label: string;
  subLabel?: string;
};

const LabelWithSublabel: FC<IProps> = ({ label, subLabel }) => {
  return (
    <StyledLabelWithSublabel flexDirection="column">
      <span>{label}</span>
      {subLabel && <span>{subLabel}</span>}
    </StyledLabelWithSublabel>
  );
};

export default LabelWithSublabel;
