import Accordion from "@/components/Accordian/Accordian";
import LabeledContent from "@/components/LabeledContent/LabeledContent";
import { useIntegration } from "@/hooks/useIntegration";
import { useTranslation } from "@/utils/translations/Translator";
import buildings from "@/assets/images/buildings.svg";
import { Divider, IconTypes } from "@emonvia/web-components";
import LabelWithSublabel from "@/containers/NewIntegration/Steps/Report/components/LabelWithSublabel";
import AllConfigurationKeysList from "@/containers/NewIntegration/Steps/Report/components/AllConfigurationKeysList";

const AccordionVendor = () => {
  const { data } = useIntegration();
  const { t } = useTranslation();

  return (
    <Accordion title={t("VENDOR_DETAILS_AND_CONFIG_DEFAULT")}>
      <LabeledContent
        label={<LabelWithSublabel label={data?.vendor} subLabel={t("VENDOR_NAME")} />}
        img={<img src={buildings} alt={`${t("VENDOR_NAME")}-icon`} />}
      />
      <Divider spacing={16} />
      <LabeledContent
        label={
          <LabelWithSublabel
            label={data?.ocppNames?.map(({ name }) => name).join(", ")}
            subLabel={t("VENDOR_OCPP_NAME")}
          />
        }
        icon={"las la-comment-alt" as IconTypes}
      />
      <Divider spacing={16} />
      <AllConfigurationKeysList />
    </Accordion>
  );
};

export default AccordionVendor;
