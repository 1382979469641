import { useCallback } from "react";

function useLocalStorage() {
  const getItem = useCallback((key: string): string | null => {
    return localStorage.getItem(key);
  }, []);

  const setItem = useCallback((key: string, value: string) => {
    localStorage.setItem(key, value);
  }, []);

  const removeItem = useCallback((key: string) => {
    localStorage.removeItem(key);
  }, []);

  return { getItem, setItem, removeItem };
}

export default useLocalStorage;
