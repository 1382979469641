import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import conf from "@/env";

const nameSpaceKey = "charge-point-integration-web";

export type WhiteListedLanguage = "en" | "de";

const whiteListedLanguages: WhiteListedLanguage[] = ["en", "de"];
const fallbackLanguage: WhiteListedLanguage = "en";

const options = {
  order: ["localStorage", "navigator"]
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: whiteListedLanguages,
    returnEmptyString: false,
    lng: fallbackLanguage,
    fallbackLng: fallbackLanguage,
    detection: options,
    debug: conf.debugMode,
    ns: [nameSpaceKey],
    defaultNS: nameSpaceKey,
    react: {
      useSuspense: true
    },
    backend: {
      loadPath: `/locales/{{lng}}.json?version=${conf.version}`
    }
  });

export const getCurrentLanguage = (): WhiteListedLanguage => {
  return i18n.language as WhiteListedLanguage;
};

export default i18n;
