import React, { useEffect, useMemo, useState } from "react";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import { BorderPanel } from "@/components/Panel/styles";
import { Button, Flex, IconButton, IconTypes, useToast } from "@emonvia/web-components";
import PanelText from "@/components/Panel/PanelText";
import Dropdown from "@/components/Dropdown/Dropdown";
import ReevApiCalls from "@/api/ReevApiCalls";
import { IConfigurationTemplate, IModelDetails, IModelRequest, ISmvProperty } from "@/api/ApiTypes";
import {
  calibrationOptions,
  connectionSchemaOptions,
  ocppMessageModelPublicKeyOptions,
  verificationTypeOfModelsSignedMeterValueOptions
} from "@/containers/NewIntegration/Steps/Model/hooks/useModelForm";
import { useQueryClient } from "react-query";
import ModelQueryKeys from "@/containers/ModelConfigurations/utils/QueryKeys";
import AddFirmwareVersionForModelConfigModal, {
  FirmwareVersions
} from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/modals/AddFirmwareVersionForModelConfigModal/AddFirmwareVersionForModelConfigModal";
import { EditConfigKeysFormValues } from "@/components/EditConfigModal/hooks/useEditConfigForm";
import { formValuesToRequest } from "@/containers/VendorConfigurations/utils/mapper";
import TextOrInputField from "@/components/TextOrInputField";
import useModelOverviewSectionForm, {
  ModelOverviewSectionFormValues
} from "@/containers/ModelConfigurations/hooks/useModelOverviewSectionForm";
import { Controller, SubmitHandler } from "react-hook-form";

interface IProps extends Partial<IModelDetails> {
  isAccessibleViaWeb?: boolean;
  refetchDetails: () => void;
  config: IConfigurationTemplate[];
  isApproval?: boolean;
}

const ModelOverviewSection: React.FC<IProps> = ({
  vendor,
  model,
  firmwareVersion,
  calibrationType,
  verificationType,
  refetchDetails,
  config,
  startupConfiguration,
  isApproval,
  interfacePort,
  connectionSchema
}) => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useToast();
  const queryClient = useQueryClient();
  const [isAddFirmwareModalOpen, setIsAddFirmwareModalOpen] = useState(false);
  const defaultValue = useMemo(
    () => ({
      calibrationType,
      connectionSchema,
      interfacePort,
      ocppAction: (startupConfiguration?.ocppAction ??
        "") as ISmvProperty["ocppMessageModelPublicKey"],
      configurationKey: startupConfiguration?.publicKeyConfigurationKeys?.[0] ?? "",
      verificationType
    }),
    [calibrationType, connectionSchema, interfacePort, startupConfiguration, verificationType]
  );
  const { control, handleSubmit, formState, watch, reset } =
    useModelOverviewSectionForm(defaultValue);
  const currentCalibrationType = watch("calibrationType");

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue]);

  const handleChange: SubmitHandler<ModelOverviewSectionFormValues> = async (values) => {
    const payload: IModelRequest = {
      calibrationType: values.calibrationType,
      verificationType: values.verificationType,
      name: model,
      isAccessibleViaWeb: values.connectionSchema === "HTTP" || values.connectionSchema === "HTTPS",
      startupConfiguration: {
        ocppAction: values.ocppAction,
        publicKeyConfigurationKeys: [values.configurationKey]
      },
      interfacePort: values.interfacePort,
      connectionSchema: values.connectionSchema
    };

    await ReevApiCalls.updateModel({
      pathParams: { vendorName: vendor, modelName: model },
      payload
    });
    await fetchQueries();
  };

  const onAddFirmwareSubmit = async (values: EditConfigKeysFormValues & FirmwareVersions) => {
    const configValues = formValuesToRequest(values);
    try {
      const firmwarePromises = values.firmwareVersions.map((newFirmware: string) =>
        ReevApiCalls.addFirmwareConfigTemplate({
          pathParams: { vendor, model, firmware: newFirmware },
          payload: { firmwareVersion: newFirmware, ...configValues }
        })
      );
      await Promise.all(firmwarePromises);

      notifySuccess(t("FIRMWARE_VERSIONS_ADDED_SUCCESSFULLY"));
      setIsAddFirmwareModalOpen(false);
      await fetchQueries();
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  const fetchQueries = async () => {
    await queryClient.resetQueries(
      isApproval ? ModelQueryKeys.APPROVAL_LIST : ModelQueryKeys.MODEL_LIST
    );
    refetchDetails();
  };

  return (
    <BorderPanel title={t("OVERVIEW")}>
      <Flex flexDirection="column" gap={16} flex={1}>
        <Flex flexDirection="row" margin={[4, 0]}>
          <PanelText text={t("VENDOR_NAME")} />
          <PanelText text={vendor} light />
        </Flex>
        <Flex flexDirection="row" margin={[4, 0]}>
          <PanelText text={t("MODEL_NAME")} />
          <PanelText text={model} light />
        </Flex>
        <Flex flexDirection="row">
          <PanelText text={t("FIRMWARE_VERSIONS")} />
          <Flex gap={18} justifyContent="space-between" style={{ whiteSpace: "nowrap" }}>
            <PanelText text={firmwareVersion} light />
            <IconButton
              icon={IconTypes.plus}
              shape="round"
              look="secondary"
              onClick={() => setIsAddFirmwareModalOpen(true)}
            />
          </Flex>
        </Flex>

        <Flex flexDirection="row">
          <PanelText text={t("CONNECTION_SCHEMA")} />
          <Controller
            control={control}
            render={({ field }) => (
              <Flex flexDirection="column" flex={1}>
                <Dropdown
                  value={field.value}
                  disabled={field.disabled}
                  onChange={field.onChange}
                  options={connectionSchemaOptions}
                  showDownArrow
                />
              </Flex>
            )}
            name="connectionSchema"
          />
        </Flex>

        <Flex flexDirection="row">
          <PanelText text={t("INTERFACE_PORT")} />
          <Controller
            control={control}
            render={({ field }) => (
              <Flex flexDirection="column" flex={1}>
                <TextOrInputField
                  value={String(field.value)}
                  onUpdate={(value) => field.onChange(Number(value))}
                />
              </Flex>
            )}
            name="interfacePort"
          />
        </Flex>

        <Flex flexDirection="row">
          <PanelText text={t("CALIBRATION_TYPE")} />
          <Controller
            control={control}
            render={({ field }) => (
              <Flex flexDirection="column" flex={1}>
                <Dropdown
                  value={field.value}
                  onChange={field.onChange}
                  options={calibrationOptions}
                  showDownArrow
                />
              </Flex>
            )}
            name="calibrationType"
          />
        </Flex>
        {currentCalibrationType === "SMV" && (
          <>
            <Flex flexDirection="row">
              <PanelText text={t("VERIFICATION_TYPE")} />
              <Controller
                control={control}
                render={({ field }) => (
                  <Flex flexDirection="column" flex={1}>
                    <Dropdown
                      value={field.value}
                      onChange={field.onChange}
                      options={verificationTypeOfModelsSignedMeterValueOptions}
                      placeholder={t("PLEASE_SELECT")}
                      showDownArrow
                    />
                  </Flex>
                )}
                name="verificationType"
              />
            </Flex>
            <Flex flexDirection="row">
              <PanelText text={t("PUBLIC_KEY_OCPP_ACTION")} />
              <Controller
                control={control}
                render={({ field }) => (
                  <Flex flexDirection="column" flex={1}>
                    <Dropdown
                      value={field.value}
                      onChange={field.onChange}
                      options={ocppMessageModelPublicKeyOptions}
                      placeholder={t("PLEASE_SELECT")}
                      showDownArrow
                    />
                  </Flex>
                )}
                name="ocppAction"
              />
            </Flex>
            <Flex flexDirection="row">
              <PanelText text={t("PUBLIC_KEY_CONFIGURATION_KEY")} />
              <Controller
                control={control}
                render={({ field }) => (
                  <Flex flexDirection="column" flex={1}>
                    <TextOrInputField value={field.value} onUpdate={(val) => field.onChange(val)} />
                  </Flex>
                )}
                name="configurationKey"
              />
            </Flex>
          </>
        )}
        {formState.isDirty && (
          <Flex flexDirection="row-reverse">
            <Button
              type="submit"
              label={t("SAVE")}
              onClick={handleSubmit(handleChange)}
              useNunitoFont
            />
          </Flex>
        )}
      </Flex>
      {isAddFirmwareModalOpen && (
        <AddFirmwareVersionForModelConfigModal
          config={config}
          onSubmit={onAddFirmwareSubmit}
          onClose={() => setIsAddFirmwareModalOpen(false)}
        />
      )}
    </BorderPanel>
  );
};
export default ModelOverviewSection;
