import styled from "styled-components";
import Panel from "@/components/Panel/Panel";
import theme from "@/utils/theme/theme";
import { ReactNode } from "react";

export const BorderPanel = styled(Panel)`
  .body {
    box-shadow: none !important;
    border-style: solid;
    border-width: 1px;
    border-color: ${theme.colors.grey350};
  }
`;

export const StyledPanel = styled.div<{
  margin?: Array<string | number>;
  padding?: Array<string | number>;
  children: ReactNode;
}>`
  width: 100%;
  overflow-y: visible;
  overflow-x: visible;

  .body {
    background: ${({ theme }) => theme.colors.primaryWhite};
    border-radius: 12px;
    box-shadow: ${(props) => props.theme.shadows.mainShadow};
    padding: ${({ padding }) => (padding ? padding : "24px 20px")};
  }
`;
