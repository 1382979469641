/* eslint-disable @typescript-eslint/no-explicit-any */

type TWindow = Window & {
  appConfig: {
    ENV: string;
    ROOT_URL: string;
    API_URL: string;
    POSTHOG_API_KEY: string;
    POSTHOG_API_HOST: string;
    USERSNAP_GLOBAL_API_KEY: string;
    USERSNAP_PROJECT_KEY: string;
    SENTRY_DSN?: string;
    DEBUG_MODE?: boolean;
    SENTRY_SAMPLE_RATE: number;
  };
};

declare const window: TWindow;

const version = __VERSION__;

const conf = {
  rootUrl: window.appConfig.ROOT_URL,
  apiUrl:
    typeof import.meta.env.VITE_API_URL !== "undefined"
      ? import.meta.env.VITE_API_URL
      : window.appConfig.API_URL,
  version,
  stage: window.appConfig.ENV,
  debugMode: window.appConfig.DEBUG_MODE,
  postHogApiKey: window.appConfig.POSTHOG_API_KEY,
  postHogApiHost: window.appConfig.POSTHOG_API_HOST,
  usersnapGlobalApiKey: window.appConfig.USERSNAP_GLOBAL_API_KEY,
  usersnapProjectKey: window.appConfig.USERSNAP_PROJECT_KEY,
  sentryDsn: window.appConfig.SENTRY_DSN,
  sentryTraceSampleRate: window.appConfig.SENTRY_SAMPLE_RATE
};

export default conf;
