import { RequireExactlyOne } from "@/@types/utils";
import { IconBadgeContainer } from "@/components/IconBadge/styles";
import { Icon, IconTypes } from "@emonvia/web-components";
import { FC, ReactNode } from "react";
import { useTheme } from "styled-components";

type IProps = RequireExactlyOne<
  {
    icon: IconTypes;
    img: ReactNode;
    backgroundColor?: string;
    size?: number;
    className?: string;
  },
  "icon" | "img"
>;

const IconBadge: FC<IProps> = ({ icon, backgroundColor, className, img, size = 32 }) => {
  const theme = useTheme();

  return (
    <IconBadgeContainer
      className={className}
      backgroundColor={backgroundColor || theme.colors.main500}
      size={size}
    >
      {icon && <Icon type={icon} />}
      {img}
    </IconBadgeContainer>
  );
};

export default IconBadge;
