import React, { PropsWithChildren } from "react";
import env from "@/env";
import SentryErrorBoudary from "@/components/ErrorMonitoring/SentryErrorBoudary";
import { DefaultErrorBoundary } from "@/components/ErrorMonitoring/DefaultErrorBoundary";

const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  if (env.sentryDsn) {
    return <SentryErrorBoudary dsn={env.sentryDsn}>{children}</SentryErrorBoudary>;
  } else {
    return <DefaultErrorBoundary>{children}</DefaultErrorBoundary>;
  }
};

export default ErrorBoundary;
