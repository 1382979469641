import { useTranslation } from "@/utils/translations/Translator";
import { Types, useColumns } from "@emonvia/web-components";

export default function useVendorConfigurationsTableColumns() {
  const { t } = useTranslation();

  return useColumns([
    {
      Header: t("VENDOR"),
      accessor: "vendor",
      labelType: Types.ns_18_sb
    },
    {
      Header: t("MODEL"),
      accessor: "model",
      labelType: Types.ns_18_sb
    },
    {
      id: "firmware",
      Header: t("FIRMWARE_VERSION"),
      accessor: "firmwareVersion",
      labelType: Types.ns_18_sb
    },
    {
      id: "calibration",
      Header: t("CALIBRATION_TYPE"),
      accessor: "calibrationType",
      labelType: Types.ns_18_sb
    },
    {
      id: "verification",
      Header: t("VERIFICATION_TYPE"),
      accessor: "verificationType",
      labelType: Types.ns_18_sb
    }
  ]);
}
