import { FC } from "react";
import { Flex } from "@emonvia/web-components";
import Step from "src/components/SubHeader/components/ProgressBar/components/Step/Step";
import { IProgressBar } from "@/components/SubHeader/components/types";

const ProgressBar: FC<IProgressBar> = ({ totalSteps, activeStep }) => {
  return (
    <Flex width="100%" flexWrap="nowrap" justifyContent="center" alignItems="center">
      {[...Array(totalSteps).keys()].map((step) => (
        <Step
          key={step}
          filled={step < activeStep}
          current={step === activeStep}
          lineBefore={step !== 0}
        />
      ))}
    </Flex>
  );
};

export default ProgressBar;
