import React from "react";
import { Flex, useToast } from "@emonvia/web-components";
import { useNavigate, useParams } from "react-router-dom";
import AllRoutes from "@/routing/AllRoutes";
import Sidebar from "@/components/Sidebar/Sidebar";
import VendorOverviewSection from "@/containers/VendorConfigurations/components/VendorConfigurationDetails/section/VendorOverviewSection";
import VendorFallbackConfigurationTemplateSection from "@/containers/VendorConfigurations/components/VendorConfigurationDetails/section/VendorFallbackConfigurationTemplateSection";
import SidebarHeader from "@/components/Sidebar/SidebarHeader";
import { useQuery } from "react-query";
import VendorQueryKeys from "@/containers/VendorConfigurations/utils/QueryKeys";
import ReevApiCalls from "@/api/ReevApiCalls";
import { ApiErrorResponse } from "@/utils/enums/ApiErrorResponse";
import { translateError } from "@/utils/translations/Translator";

const VendorConfigurationDetails: React.FC = () => {
  const { vendor } = useParams();
  const navigate = useNavigate();
  const { notifyError } = useToast();

  const { data, isLoading, refetch } = useQuery(
    [VendorQueryKeys.DETAIL, vendor],
    () => ReevApiCalls.getVendorFallbackConfig({ pathParams: { vendor } }),
    {
      onError: (e: ApiErrorResponse) => notifyError(translateError(e))
    }
  );

  const { name, ocppNames, fallbackConfiguration = [] } = data || {};

  const closeSidebar = () => {
    navigate(AllRoutes.VENDOR_CONFIGURATIONS_INDEX);
  };

  return (
    <Sidebar title={<SidebarHeader label={vendor} />} onClose={closeSidebar} isLoading={isLoading}>
      <Flex flexDirection="column" padding={[24, 0, 0]} gap={48}>
        <Flex flexDirection="row">
          <VendorOverviewSection vendorName={name} vendorOcppNames={ocppNames} />
        </Flex>
        <Flex flexDirection="row">
          <VendorFallbackConfigurationTemplateSection
            fallbackConfiguration={fallbackConfiguration}
            refetchDetails={refetch}
          />
        </Flex>
      </Flex>
    </Sidebar>
  );
};

export default VendorConfigurationDetails;
