import styled from "styled-components";

export const StyledOrderedList = styled.ol`
  list-style: none;
  counter-reset: list-counter;
  display: flex;
  flex-direction: column;
  gap: 12px;

  li {
    counter-increment: list-counter;
    display: inline-flex;
  }
  li::before {
    content: counter(list-counter) ". ";
    margin-right: 12px;
    white-space: nowrap;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`;
