import Accordion from "@/components/Accordian/Accordian";
import LabeledContent from "@/components/LabeledContent/LabeledContent";
import LabelWithSublabel from "@/containers/NewIntegration/Steps/Report/components/LabelWithSublabel";
import { useIntegration } from "@/hooks/useIntegration";
import { useTranslation } from "@/utils/translations/Translator";
import { Divider, Flex, IconTypes, isEmpty } from "@emonvia/web-components";
import EvStation from "@/assets/images/ev_station.svg";
import Sdk from "@/assets/images/sdk.svg";
import Message from "@/assets/images/message.svg";

const AccordionModel = () => {
  const { data } = useIntegration();
  const { t } = useTranslation();

  if (isEmpty(data.models)) return null;

  return (
    <Accordion title={t("MODEL_DETAILS")}>
      {data.models?.map((model, i) => (
        <Accordion
          key={`${model.name}-${i}`}
          title={`${t("MODEL")} #${i + 1}`}
          isInitiallyOpen={i === 0}
        >
          <Flex flexDirection="column">
            <LabeledContent
              label={<LabelWithSublabel label={model.name} subLabel={t("MODEL_NAME")} />}
              img={<img src={EvStation} alt={`${t("MODEL_NAME_FROM_BOOT_NOTIFICATION")} icon`} />}
            />
            <Divider spacing={16} />
            <LabeledContent
              label={
                <LabelWithSublabel label={model.firmwareVersion} subLabel={t("FIRMWARE_VERSION")} />
              }
              img={<img src={Sdk} alt={`${t("FIRMWARE_VERSION")} icon`} />}
            />
            <Divider spacing={16} />
            <LabeledContent
              label={
                <LabelWithSublabel
                  label={model.electricMeterCalibrationTypeOfModel}
                  subLabel={t("SMW_CALIBARION_TYPE_MODEL")}
                />
              }
              icon={IconTypes.bolt}
            />
            {model.electricMeterCalibrationTypeOfModel === "SMV" && (
              <>
                <Divider spacing={16} />
                <LabeledContent
                  label={
                    <LabelWithSublabel
                      label={model.smvProperty?.verificationTypeOfModelsSignedMeterValue}
                      subLabel={t("SMW_VERIFICATION_TYPE")}
                    />
                  }
                  icon={IconTypes.fingerprint}
                />
                <Divider spacing={16} />
                <LabeledContent
                  label={
                    <LabelWithSublabel
                      label={model.smvProperty?.ocppMessageModelPublicKey}
                      subLabel={t("OCPP_MESSAGE_MODEL_PUBLIC_KEY")}
                    />
                  }
                  img={<img src={Message} alt={`${t("OCPP_MESSAGE_MODEL_PUBLIC_KEY")} icon`} />}
                />
                <Divider spacing={16} />
                <LabeledContent
                  label={
                    <LabelWithSublabel
                      label={model.smvProperty?.vendorSpecificConfigKeyForPublicKey}
                      subLabel={t("VENDOR_SPECIFIC_CONFIG_KEY")}
                    />
                  }
                  icon={IconTypes.key}
                />
              </>
            )}
          </Flex>
        </Accordion>
      ))}
    </Accordion>
  );
};

export default AccordionModel;
