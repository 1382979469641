import { IModelDetails } from "@/api/ApiTypes";
import { DateFormats, formatDate } from "@/utils/formatDate";
import { useTranslation } from "@/utils/translations/Translator";
import { Types, useColumns } from "@emonvia/web-components";

const useChargePointApprovalColumns = () => {
  const { t } = useTranslation();

  return useColumns<IModelDetails>([
    {
      id: "vendor",
      Header: t("VENDOR"),
      accessor: "vendor",
      labelType: Types.ns_18_sb
    },
    {
      id: "model",
      Header: t("MODEL"),
      accessor: "model",
      labelType: Types.ns_18_sb
    },
    {
      id: "firmware",
      Header: t("FIRMWARE_VERSION"),
      accessor: "firmwareVersion",
      labelType: Types.ns_18_sb
    },
    {
      id: "createdOn",
      Header: t("CREATED_ON"),
      accessor: (row: IModelDetails) => formatDate(row.createdOn, DateFormats.dateTimeSeconds),
      disableSortBy: true,
      labelType: Types.ns_18_sb
    }
  ]);
};

export default useChargePointApprovalColumns;
