import React, { useEffect } from "react";
import env from "@/env";

const FaviconManager: React.FC = () => {
  const basePath = `${env.rootUrl}/favicons/`;

  useEffect(() => {
    const fallbackStage = "prod";
    const currentStage = env.stage || fallbackStage;
    const faviconPath = `favicon-${currentStage}.png`;

    // Select the existing link elements or create new ones
    let faviconLink: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
    let maskIconLink: HTMLLinkElement | null = document.querySelector("link[rel='mask-icon']");

    if (!faviconLink) {
      faviconLink = document.createElement("link");
      faviconLink.rel = "icon";
      document.head.appendChild(faviconLink);
    }

    faviconLink.href = basePath + faviconPath;

    // for safari
    if (!maskIconLink) {
      maskIconLink = document.createElement("link");
      maskIconLink.rel = "mask-icon";
      document.head.appendChild(maskIconLink);
    }

    maskIconLink.href = basePath + faviconPath;
    maskIconLink.setAttribute("sizes", "18x18");
    return () => {
      if (faviconLink) {
        document.head.removeChild(faviconLink);
      }
      if (maskIconLink) {
        document.head.removeChild(maskIconLink);
      }
    };
  }, [env.stage]);

  return null;
};

export default FaviconManager;
