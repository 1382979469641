import styled from "styled-components";
import { ReactNode } from "react";

export const AccordionContainer = styled.div`
  overflow: hidden;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.frameBodyBackground};
`;

export const AccordionArrow = styled.div<{ isOpen: boolean; children: ReactNode }>`
  transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
  transition: transform 0.3s ease-in-out;
`;

export const AccordionContent = styled.div<{ isOpen: boolean; children: ReactNode }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  max-height: ${(props) => (props.isOpen ? "auto" : 0)};
  overflow: hidden;
  padding: 0 20px ${({ isOpen }) => (isOpen ? "20px" : 0)} 20px;
`;
