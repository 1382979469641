import Frame from "@/components/Frame/Frame";
import Text from "@/components/Text/Text";
import { ReportUnorderedList } from "@/containers/NewIntegration/Steps/Report/styles";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import { Flex } from "@emonvia/web-components";
import { FC } from "react";
import { Trans } from "react-i18next";

const ReportDescriptionFrame = () => {
  const { t } = useTranslation();

  return (
    <Frame>
      <Flex flexDirection="column" gap={12}>
        <Text weight={700}>{t("ALL_CONFIGURATION_CREATED")}</Text>
        <Flex flexDirection="column" gap={5}>
          <Text weight={300}>
            <Trans
              i18nKey="LIST_WITH_VENDOR_MODEL_AND_CONF_TMPL"
              components={{
                u: <u />,
                b: <strong />
              }}
            />
          </Text>
          <List list={["VENDOR_FALLBACK_TEMPLATE", "MODEL_TEMPLATES", "MODEL_FALLBACK_TEMPLATE"]} />
          <Text margin={[12, 0]}>{t("CHECK_BEFORE_SUBMITTING")}</Text>
          <Text>
            <Trans
              i18nKey="INTEGRATION_NEEDS_TO_BE_TESTED"
              components={{
                u: <u />,
                b: <strong />
              }}
            />
          </Text>
        </Flex>
      </Flex>
    </Frame>
  );
};

const List: FC<{ list: TransKey[] }> = ({ list }) => {
  return (
    <ReportUnorderedList>
      {list.map((item) => (
        <li key={item}>
          <Trans
            i18nKey={item}
            components={{
              u: <u />,
              b: <strong />
            }}
          />
        </li>
      ))}
    </ReportUnorderedList>
  );
};

export default ReportDescriptionFrame;
