import React, { PropsWithChildren, useState } from "react";
import { Flex, Icon, IconTypes } from "@emonvia/web-components";
import { AccordionArrow, AccordionContainer, AccordionContent } from "./styles";
import Text from "@/components/Text/Text";

interface AccordionProps {
  title: string;
  isInitiallyOpen?: boolean;
}

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  title,
  isInitiallyOpen = false,
  children
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const toggleAccordion = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <AccordionContainer>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding={[20]}
        style={{ cursor: "pointer" }}
        onClick={toggleAccordion}
      >
        <Text clickable weight={400}>
          {title}
        </Text>
        <AccordionArrow isOpen={isOpen}>
          <Icon size={20} type={IconTypes.angleDown} />
        </AccordionArrow>
      </Flex>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
