import { FC } from "react";
import { FormLayoutContainer } from "@/components/layouts/FormLayout/styles";
import { Outlet } from "react-router-dom";
import BaseLayout from "@/components/layouts/BaseLayout/BaseLayout";

const FormLayout: FC = () => {
  return (
    <BaseLayout>
      <FormLayoutContainer>
        <Outlet />
      </FormLayoutContainer>
    </BaseLayout>
  );
};

export default FormLayout;
