import LabeledContent from "@/components/LabeledContent/LabeledContent";
import { capitalizeFirstLetter } from "@/utils/helpers";
import { useTranslation } from "@/utils/translations/Translator";
import { Divider, Flex, IconTypes, isEmpty, isNullOrUndefined } from "@emonvia/web-components";
import { FC, Fragment } from "react";

type IProps = {
  list: {
    key: string;
    value: string;
    sublabel?: string;
    isDefault?: boolean;
  }[];
  showDefaultLabel?: boolean;
};
const ConfigurationKeyList: FC<IProps> = ({ list, showDefaultLabel }) => {
  const { t } = useTranslation();

  if (isEmpty(list)) return null;

  return (
    <Flex flexDirection="column">
      {list.map(({ key, value, isDefault, sublabel }, index) => (
        <Fragment key={`${key}-${value}-${index}`}>
          <LabeledContent
            label={capitalizeFirstLetter(key)}
            icon={IconTypes.key}
            orientation="horizontal"
          >
            {isNullOrUndefined(value) || value === "" ? <i>{t("EMPTY")}</i> : String(value)}
            {showDefaultLabel && isDefault && <i style={{ marginLeft: 10 }}>{t("DEFAULT")}</i>}
            {sublabel && <i style={{ marginLeft: 10 }}>{sublabel}</i>}
          </LabeledContent>
          {index < list.length - 1 && <Divider spacing={16} />}
        </Fragment>
      ))}
    </Flex>
  );
};

export default ConfigurationKeyList;
