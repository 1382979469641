import React, { Suspense } from "react";
import Header from "@/components/Header/Header";
import Routes from "@/routing/Routes";
import { TrackingContextProvider } from "@/context/TrackingContext";
import { UsersnapProvider } from "@/context/UserSnapContext";
import FaviconManager from "@/components/FaviconManager";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyle from "@/utils/theme/GlobalStyle";
import theme from "@/utils/theme/theme";
import { ThemeProvider } from "styled-components";
import { GlobalFontsNunito, ToastContextProvider } from "@emonvia/web-components";
import "@/assets/fontline.css";
import "@/utils/i18n";
import { AppRerenderer } from "@/components/withRerenderApp";
import { IntegrationProvider } from "@/context/IntegrationContext";
import { StyledHeader } from "@/components/Header/styles";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false, keepPreviousData: true } }
});
const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <GlobalFontsNunito />
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<StyledHeader />}>
            <ToastContextProvider>
              <AppRerenderer>
                <UsersnapProvider>
                  <Router>
                    <TrackingContextProvider>
                      <IntegrationProvider>
                        <Header />
                        <FaviconManager />
                        <Routes />
                      </IntegrationProvider>
                    </TrackingContextProvider>
                  </Router>
                </UsersnapProvider>
              </AppRerenderer>
            </ToastContextProvider>
          </Suspense>
        </QueryClientProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
