import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InferType, array, object, string } from "yup";

const firmwareVersionsSchema = object().shape({
  firmwareVersions: array().of(
    object().shape({
      firmwareVersion: string()
    })
  )
});

export type FirmwareVersionFormValues = InferType<typeof firmwareVersionsSchema>;

export default function useAddFirmwareVersionForm() {
  return useForm({
    resolver: yupResolver<FirmwareVersionFormValues>(firmwareVersionsSchema),
    mode: "onChange",
    defaultValues: {
      firmwareVersions: [
        {
          firmwareVersion: ""
        }
      ]
    }
  });
}
