import EditConfigModal from "@/components/EditConfigModal/EditConfigModal";
import { EditConfigKeysFormValues } from "@/components/EditConfigModal/hooks/useEditConfigForm";
import { FC } from "react";
import {
  IConfigTableData,
  toConfigFormType
} from "src/containers/VendorConfigurations/utils/mapper";

type IProps = {
  config: IConfigTableData[];
  onClose: () => void;
  onSubmit: (config: EditConfigKeysFormValues) => void;
};

const EditVendorFallbackConfiguration: FC<IProps> = ({ onClose, onSubmit, config }) => {
  return (
    <EditConfigModal
      config={toConfigFormType(config)}
      onSubmit={onSubmit}
      onClose={onClose}
      title="EDIT_VENDOR_FALLBACK_CONFIG"
    />
  );
};

export default EditVendorFallbackConfiguration;
