import { Sidebar as OriginalSidebar } from "@emonvia/web-components";
import React from "react";
import { SidebarContainer } from "@/components/Sidebar/styles";

type SidebarProps = React.ComponentProps<typeof OriginalSidebar>;

interface ISidebarProps extends SidebarProps {
  children: React.ReactNode;
}

const Sidebar: React.FC<ISidebarProps> = ({ children, ...props }) => (
  <SidebarContainer {...props}>{children}</SidebarContainer>
);

export default Sidebar;
