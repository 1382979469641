import Frame from "@/components/Frame/Frame";
import LabeledContent from "@/components/LabeledContent/LabeledContent";
import ValidatedTextInput from "@/components/ValidatedTextInput/ValidatedTextInput";
import {
  ModelFormValues,
  calibrationOptions,
  ocppMessageModelPublicKeyOptions,
  verificationTypeOfModelsSignedMeterValueOptions
} from "@/containers/NewIntegration/Steps/Model/hooks/useModelForm";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import { Divider, IconTypes } from "@emonvia/web-components";
import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import EvStation from "@/assets/images/ev_station.svg";
import Sdk from "@/assets/images/sdk.svg";
import Message from "@/assets/images/message.svg";
import Text from "@/components/Text/Text";
import Dropdown from "@/components/Dropdown/Dropdown";

type IProps = {
  form: UseFormReturn<ModelFormValues>;
};

const ModelForm: FC<IProps> = ({ form }) => {
  const { t } = useTranslation();
  const { control, watch } = form;
  const calibrationTypeWatcher = watch("electricMeterCalibrationTypeOfModel");

  return (
    <Frame header={t("BASIC_MODEL_INFORMATION")}>
      <LabeledContent
        htmlFor="chargePointModel"
        img={<img src={EvStation} alt={`${t("MODEL_NAME_FROM_BOOT_NOTIFICATION")} icon`} />}
        label={t("MODEL_NAME_FROM_BOOT_NOTIFICATION")}
      >
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState: { error } }) => (
            <ValidatedTextInput
              {...field}
              placeholder={t("ENTER_MODEL_NAME")}
              errorMessage={error?.message as TransKey}
              removeSpaces={false}
            />
          )}
        />
      </LabeledContent>
      <Divider spacing={16} />
      <LabeledContent
        img={<img src={Sdk} alt={`${t("FIRMWARE_VERSION")} icon`} />}
        label={t("FIRMWARE_VERSION")}
      >
        <Controller
          control={control}
          name="firmwareVersion"
          render={({ field, fieldState: { error } }) => (
            <ValidatedTextInput
              {...field}
              placeholder={t("ENTER_FIRMWARE_VERSION")}
              errorMessage={error?.message as TransKey}
              removeSpaces={false}
            />
          )}
        />
      </LabeledContent>
      <Divider spacing={16} />
      <Text>{t("SMW_MODEL_INFO")}</Text>
      <LabeledContent icon={IconTypes.bolt} label={t("SMW_CALIBARION_TYPE_MODEL")}>
        <Controller
          control={control}
          name="electricMeterCalibrationTypeOfModel"
          render={({ field, fieldState: { error } }) => (
            <Dropdown
              {...field}
              placeholder={t("SELECT_SMW_CALIBARION_TYPE_MODEL")}
              options={calibrationOptions}
              errorMessage={error?.message as TransKey}
            />
          )}
        />
      </LabeledContent>
      {calibrationTypeWatcher === "SMV" && (
        <>
          <Divider spacing={16} />
          <LabeledContent icon={IconTypes.fingerprint} label={t("SMW_VERIFICATION_TYPE")}>
            <Controller
              control={control}
              name="smvProperty.verificationTypeOfModelsSignedMeterValue"
              render={({ field, fieldState: { error } }) => (
                <Dropdown
                  {...field}
                  placeholder={t("SELECT_SMW_VERIFICATION_TYPE")}
                  options={verificationTypeOfModelsSignedMeterValueOptions}
                  errorMessage={error?.message as TransKey}
                />
              )}
            />
          </LabeledContent>
          <Divider spacing={16} />
          <LabeledContent
            img={<img src={Message} alt={`${t("OCPP_MESSAGE_MODEL_PUBLIC_KEY")} icon`} />}
            label={t("OCPP_MESSAGE_MODEL_PUBLIC_KEY")}
            tooltip={t("OCPP_MESSAGE_MODEL_PUBLIC_KEY_TOOLTIP")}
          >
            <Controller
              control={control}
              name="smvProperty.ocppMessageModelPublicKey"
              render={({ field, fieldState: { error } }) => (
                <Dropdown
                  {...field}
                  placeholder={t("SELECT_OCPP_MESSAGE_MODEL_PUBLIC_KEY")}
                  options={ocppMessageModelPublicKeyOptions}
                  errorMessage={error?.message as TransKey}
                />
              )}
            />
          </LabeledContent>
          <Divider spacing={16} />
          <LabeledContent
            icon={IconTypes.key}
            label={t("VENDOR_SPECIFIC_PUBLIC_KEY")}
            tooltip={t("VENDOR_SPECIFIC_PUBLIC_KEY_TOOLTIP")}
          >
            <Controller
              control={control}
              name="smvProperty.vendorSpecificConfigKeyForPublicKey"
              render={({ field, fieldState: { error } }) => (
                <ValidatedTextInput
                  {...field}
                  placeholder={t("ENTER_VENDOR_SPECIFIC_PUBLIC_KEY")}
                  errorMessage={error?.message as TransKey}
                  removeSpaces={false}
                />
              )}
            />
          </LabeledContent>
        </>
      )}
    </Frame>
  );
};

export default ModelForm;
