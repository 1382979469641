import { isEmpty } from "@emonvia/web-components";
import i18next, { TOptions } from "i18next";
import { useTranslation as _useTranslation } from "react-i18next";
import DefaultLanguageTranslations from "@/assets/locales/en.json";
import { ApiErrorResponse } from "@/utils/enums/ApiErrorResponse";

export type TransKey = keyof typeof DefaultLanguageTranslations;

export const translateToEnglish = (translationValue: string | undefined) => {
  if (typeof translationValue !== "string") {
    return "unknown label";
  }
  const shortenedTranslationValue = shortenTransValue(translationValue);
  const translationArguments = lookupInTranslationLookup(shortenedTranslationValue);
  const translationArgumentsNotFound = !translationArguments;

  if (translationArgumentsNotFound) {
    return translationValue;
  }

  const transKey = translationArguments[0];
  const transOptions = translationArguments[1];

  return translate(transKey, { ...transOptions, lng: "en" });
};

const shortenTransValue = (translationValue: string) => translationValue?.slice(0, 25);

const addToTranslationLookup = (transValue: string, [key, options]: [TransKey, TOptions?]) => {
  const shortenedTranslationValue = shortenTransValue(transValue);
  translationLookup[shortenedTranslationValue] = [key, options];
};

const lookupInTranslationLookup = (translationValue: string) => {
  return translationLookup[translationValue];
};

const translationLookup: {
  [translationValue: string]: [TransKey, TOptions?];
} = {};

export function translate(key: TransKey, options?: TOptions): string {
  const value = i18next.t(key, options);
  addToTranslationLookup(value, [key, options]);
  return value;
}

export const useTranslation = () => {
  _useTranslation();

  return {
    t: translate
  };
};

export function translateError(error: ApiErrorResponse) {
  if (isEmpty(error.detail)) {
    return translate("UNKNOWN_ERROR");
  }

  return error.detail;
}
