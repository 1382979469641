import { StyledOrderedList } from "@/components/OrderedList/styles";
import Text from "@/components/Text/Text";
import { IdProvider } from "@emonvia/web-components";
import { FC, ReactNode } from "react";

type IProps = {
  items: (ReactNode | string)[];
};

const OrderedList: FC<IProps> = ({ items }) => {
  return (
    <StyledOrderedList>
      {items.map((item) => (
        <li key={IdProvider.stringId}>
          {typeof item === "string" ? (
            <Text weight={300} color="grey750">
              {item}
            </Text>
          ) : (
            item
          )}
        </li>
      ))}
    </StyledOrderedList>
  );
};

export default OrderedList;
