import { useTranslation } from "@/utils/translations/Translator";
import { useColumns } from "@emonvia/web-components";
import { ICustomAccessControlMap } from "@/api/ApiTypes";
import { DEFAULT_COLUMN_PROPERTIES } from "@/utils/constants";

export default function useVendorFallbackConfigurationColumns(
  supportsAuthConfigKeys: boolean = false
) {
  const { t } = useTranslation();

  const configKeyHeader = supportsAuthConfigKeys
    ? t("AUTHORIZATION_CONFIGURATION_KEY")
    : t("CONFIGURATION_KEY");

  const columns = [
    {
      id: "configKey",
      Header: configKeyHeader,
      accessor: "configKey",
      ...DEFAULT_COLUMN_PROPERTIES
    },
    {
      id: "value",
      Header: t("VALUE"),
      accessor: "value",
      disableSortBy: true,
      ...DEFAULT_COLUMN_PROPERTIES
    }
  ];

  if (supportsAuthConfigKeys) {
    columns.splice(1, 0, {
      id: "useCase",
      Header: t("USE_CASE"),
      accessor: "useCase",
      disableSortBy: true,
      ...DEFAULT_COLUMN_PROPERTIES
    });
  }

  return useColumns<ICustomAccessControlMap>(columns);
}
