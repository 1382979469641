import Accordion from "@/components/Accordian/Accordian";
import AllConfigurationKeysList from "@/containers/NewIntegration/Steps/Report/components/AllConfigurationKeysList";
import { useTranslation } from "@/utils/translations/Translator";

const AccordionModelConfigurationTemplate = () => {
  const { t } = useTranslation();

  return (
    <Accordion title={t("MODEL_CONFIGURATION_TEMPLATE")}>
      <AllConfigurationKeysList />
    </Accordion>
  );
};

export default AccordionModelConfigurationTemplate;
