import { StringSchema, string } from "yup";

export const requiredStringSchema = (schema?: StringSchema) => {
  const _schema = schema ? schema.required("FIELD_REQUIRED") : string().required("FIELD_REQUIRED");
  return baseStringSchema(_schema);
};

export const baseStringSchema = (schema?: StringSchema) => {
  const base = schema || string();
  return base.trim().max(50, "MAXIMUM_CHARACTERS_50");
};

export const requiredStringSchemaWithMaxChars = ({
  schema,
  max = 50
}: {
  schema?: StringSchema;
  max?: number;
}) => {
  const _schema = schema ? schema.required("FIELD_REQUIRED") : string().required("FIELD_REQUIRED");

  return _schema.transform((value) => value.trim()).max(max, `MAXIMUM_CHARACTERS_${max}`);
};
