import React from "react";
import { CardContainer, CardImage } from "@/components/Card/styles";
import Text from "@/components/Text/Text";

interface CardProps {
  name: string;
  imageSrc: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  disabled?: boolean;
}

const Card: React.FC<CardProps> = ({ name, imageSrc, style, disabled, onClick }) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };
  return (
    <CardContainer onClick={handleClick} disabled={disabled} style={style}>
      <CardImage src={imageSrc} alt={name} />
      <Text weight={700} size={20} maxWidth={200} textAlign="center" clickable>
        {name}
      </Text>
    </CardContainer>
  );
};

export default Card;
