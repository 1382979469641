import { OptionsType } from "@/@types/utils";
import { IModel, ISmvProperty, IModelDetails } from "@/api/ApiTypes";
import { baseStringSchema, requiredStringSchemaWithMaxChars } from "@/utils/validation/schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { InferType, mixed, object } from "yup";

export const calibrationOptions: OptionsType<IModel["electricMeterCalibrationTypeOfModel"]> = [
  {
    value: "SMV",
    label: t("SMV")
  },
  {
    value: "MID",
    label: t("MID")
  },
  {
    value: "STANDARD",
    label: t("STANDARD")
  }
];

export const verificationTypeOfModelsSignedMeterValueOptions: OptionsType<
  ISmvProperty["verificationTypeOfModelsSignedMeterValue"]
> = [
  {
    value: "OCMF",
    label: t("OCMF")
  },
  {
    value: "PCDF",
    label: t("PCDF")
  },
  {
    value: "ISA_EDL_40_P",
    label: t("ISA_EDL_40_P")
  },
  {
    value: "ALFEN",
    label: t("ALFEN")
  },
  {
    value: "EDL_40_P",
    label: t("EDL_40_P")
  },
  {
    value: "EDL_40_SIG",
    label: t("EDL_40_SIG")
  },
  {
    value: "EDL_40_MENNEKES",
    label: t("EDL_40_MENNEKES")
  },
  {
    value: "UNKNOWN",
    label: t("UNKNOWN")
  }
];

export const connectionSchemaOptions: OptionsType<IModelDetails["connectionSchema"]> = [
  {
    value: "NONE",
    label: t("NONE")
  },
  {
    value: "HTTP",
    label: t("HTTP")
  },
  {
    value: "HTTPS",
    label: t("HTTPS")
  }
];

export const ocppMessageModelPublicKeyOptions: OptionsType<
  ISmvProperty["ocppMessageModelPublicKey"]
> = [
  {
    value: "GetConfiguration",
    label: t("GET_CONFIGURATION")
  },
  {
    value: "DataTransfer",
    label: t("DATA_TRANSFER")
  }
];

export const modelFormSchema = object({
  name: requiredStringSchemaWithMaxChars({ max: 20 }),
  firmwareVersion: requiredStringSchemaWithMaxChars({ max: 50 }),
  electricMeterCalibrationTypeOfModel: mixed<IModel["electricMeterCalibrationTypeOfModel"]>()
    .oneOf(calibrationOptions.map(({ value }) => value))
    .required("FIELD_REQUIRED"),
  smvProperty: object()
    .shape({
      verificationTypeOfModelsSignedMeterValue: mixed<
        ISmvProperty["verificationTypeOfModelsSignedMeterValue"]
      >()
        .oneOf(verificationTypeOfModelsSignedMeterValueOptions.map(({ value }) => value))
        .when("electricMeterCalibrationTypeOfModel", {
          is: "SMV",
          then: (schema) => schema.required("FIELD_REQUIRED")
        }),
      ocppMessageModelPublicKey: mixed<ISmvProperty["ocppMessageModelPublicKey"]>()
        .oneOf(ocppMessageModelPublicKeyOptions.map(({ value }) => value))
        .when("electricMeterCalibrationTypeOfModel", {
          is: "SMV",
          then: (schema) => schema.required("FIELD_REQUIRED")
        }),
      vendorSpecificConfigKeyForPublicKey: baseStringSchema().nullable()
    })
    .nullable()
});

export type ModelFormValues = InferType<typeof modelFormSchema>;

export default function useModelForm() {
  return useForm({
    resolver: yupResolver<ModelFormValues>(modelFormSchema),
    mode: "onChange"
  });
}
