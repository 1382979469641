import styled from "styled-components";

export const LandingInternalContainer = styled.section`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  border-radius: 10px;
  width: 100%;

  .grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(min-content, 200px));
    grid-column-gap: 48px;
    grid-row-gap: 20px;
    justify-content: center;
    align-items: center;
  }
`;
