import FieldGroupArray from "@/components/FieldGroupArray/FieldGroupArray";
import Frame from "@/components/Frame/Frame";
import Text from "@/components/Text/Text";
import { AddDefaultKeysFormValues } from "@/containers/NewIntegration/Steps/AddDefaultKeys/hooks/useAddDefaultKeysForm";
import { useTranslation } from "@/utils/translations/Translator";
import { IconTypes } from "@emonvia/web-components";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

type IProps = {
  form: UseFormReturn<AddDefaultKeysFormValues>;
};

const AddDefaultKeysForm: FC<IProps> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <Frame>
      <Text>{t("DEFAULT_CONFIGURATION_KEYS_AND_THEIR_VALUES")}</Text>
      <FieldGroupArray
        form={form}
        group={{
          name: "configs",
          icon: IconTypes.key,
          label: (index) => t("DEFAULT_CONFIGURATION_KEY_NUMBER", { index }),
          fields: [
            {
              name: "key",
              placeholder: t("ENTER_DEFAULT_CONFIGURATION_KEY")
            },
            {
              name: "value",
              placeholder: t("ENTER_DEFAULT_CONFIGURATION_KEY_VALUE")
            }
          ]
        }}
      />
    </Frame>
  );
};

export default AddDefaultKeysForm;
