import Text from "@/components/Text/Text";
import { useTranslation } from "@/utils/translations/Translator";
import { Table as ExternalTable, Flex } from "@emonvia/web-components";
import styled from "styled-components";

const Table = styled(ExternalTable)`
  .header-wrapper section div {
    color: ${({ theme }) => theme.colors.blue900};
  }

  td {
    font-family: ${({ theme }) => theme.fonts.light};
    color: ${({ theme }) => theme.colors.grey750};
    text-wrap: wrap;
    font-size: 1rem;
  }

  // hide action column
  th:last-of-type,
  td:last-of-type {
    section {
      i {
        display: none;
      }
    }
  }
`;

export const NoMatchesMessage = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" padding={[100]}>
      <Text size={20} weight={700} margin={[0, 0, 5, 0]}>
        {t("NO_RESULTS_FOUND")}
      </Text>
      <Text size={18}>{t("NO_SEARCH_MATCHES")}</Text>
    </Flex>
  );
};

export default Table;
