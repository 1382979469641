import { FC, FormEvent, useEffect, useState } from "react";
import { Flex, useToast } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import SubHeader from "@/components/SubHeader/SubHeader";
import { Trans } from "react-i18next";
import Frame from "@/components/Frame/Frame";
import Text from "@/components/Text/Text";
import VendorForm from "@/containers/NewIntegration/Steps/Vendor/components/VendorForm";
import useVendorForm from "@/containers/NewIntegration/Steps/Vendor/hooks/useVendorForm";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useIntegration } from "@/hooks/useIntegration";
import { IChargePointIntegrationVendorRequest } from "@/api/ApiTypes";

const Vendor: FC = () => {
  const [isBusy, setIsBusy] = useState(false);
  const { data, goNextWithUpdate } = useIntegration();
  const form = useVendorForm();
  const { t } = useTranslation();
  const { notifyError } = useToast();

  useEffect(() => {
    form.reset({
      name: data.vendor,
      ocppNames: data.ocppNames?.length > 0 ? data.ocppNames : [{ name: "" }]
    });
  }, [data.vendor, data.ocppNames]);

  const updateIntegrationAndGoToNextStep = async (
    payload: IChargePointIntegrationVendorRequest
  ) => {
    try {
      setIsBusy(true);
      await ReevApiCalls.updateIntegrationVendor({
        pathParams: { integrationId: data?.integrationId },
        payload
      });
      goNextWithUpdate({ vendor: payload.name, ocppNames: payload.ocppNames });
    } catch (e) {
      notifyError(translateError(e));
    } finally {
      setIsBusy(false);
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    form.handleSubmit(updateIntegrationAndGoToNextStep)();
  };

  return (
    <Flex flexDirection="column" gap={20}>
      <SubHeader />
      <form onSubmit={onSubmit}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" gap={20}>
          <Flex flexDirection="column" gap={12}>
            <Frame>
              <Text weight={300} color="grey750">
                <Trans
                  i18nKey="ENTER_CORRECT_INFO_FOR_VENDORS"
                  components={{
                    u: <u />,
                    b: <strong />
                  }}
                />
              </Text>
            </Frame>
            <VendorForm form={form} />
          </Flex>
          <Button
            data-testid={"Vendor_Button"}
            label={t("CONFIRM")}
            useNunitoFont
            width="100%"
            type="submit"
            disabled={!form.formState.isValid || isBusy}
          />
        </Flex>
      </form>
    </Flex>
  );
};

export default Vendor;
