import Frame from "@/components/Frame/Frame";
import OrderedList from "@/components/OrderedList/OrderedList";
import Text from "@/components/Text/Text";
import { useTranslation } from "@/utils/translations/Translator";
import { Trans } from "react-i18next";

const AddCustomAccessControlKeysDescriptionFrame = () => {
  const { t } = useTranslation();

  return (
    <Frame>
      <OrderedList
        items={[
          <Text key={1} weight={300} color="grey750">
            <Trans
              i18nKey="ENTER_CUSTOM_ACCESS_CONTROL_KEYS"
              components={{
                u: <u />,
                b: <strong />
              }}
            />
          </Text>,
          <Text key={2} weight={300} color="grey750">
            <Trans
              i18nKey="SELECT_VALUES_FOR_ON_AND_OFF"
              components={{
                u: <u />,
                b: <strong />
              }}
            />
          </Text>,
          t("ADD_OTHER_ACCESS_CONTROL_KEYS"),
          t("CROSS_CHECK_ACCESS_CONTROL_KEYS")
        ]}
      />
    </Frame>
  );
};

export default AddCustomAccessControlKeysDescriptionFrame;
