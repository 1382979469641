import { FC } from "react";
import { OptionsType } from "@/@types/utils";
import { StyledSearchableDropdown } from "@/components/Dropdown/styles";
import { Flex } from "@emonvia/web-components";
import AlertTriangle from "@/assets/images/alert-triangle.svg";
import Text from "@/components/Text/Text";
import { TransKey, useTranslation } from "@/utils/translations/Translator";

type IProps = {
  id?: string;
  value: string;
  onChange: (val: string) => void;
  onReset?: () => void;
  options: OptionsType<string>;
  placeholder?: string;
  ["data-testId"]?: string;
  errorMessage?: TransKey;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  showDownArrow?: boolean;
  width?: string;
};

const Dropdown: FC<IProps> = ({
  id,
  onChange,
  options,
  value,
  placeholder,
  "data-testId": dataTestId,
  required,
  errorMessage,
  disabled,
  width = "100%",
  isLoading,
  onReset,
  showDownArrow = false
}) => {
  const { t } = useTranslation();

  const handleReset = () => {
    if (disabled) return;
    onChange(undefined);
    onReset();
  };

  return (
    <Flex flexDirection="column" gap={10} width={width}>
      <StyledSearchableDropdown
        id={id}
        selected={value}
        onChange={onChange}
        onReset={showDownArrow ? null : handleReset}
        options={options}
        isLoading={isLoading}
        placeholder={placeholder}
        sortLabels={false}
        look="secondary"
        data-testid={dataTestId}
        highlightBorder
        required={required}
        error={!!errorMessage}
        disabled={disabled}
      />
      {errorMessage && (
        <Flex gap={10} alignItems="center">
          <img src={AlertTriangle} width={18} height={18} alt={`${value} error icon`} />
          <Text color="error800" weight={300}>
            {t(errorMessage as TransKey)}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default Dropdown;
