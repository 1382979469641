import React, { FormEvent, useEffect } from "react";
import { Flex, isNotEmpty, useToast } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import SubHeader from "@/components/SubHeader/SubHeader";
import useAddDefaultKeysForm, {
  AddDefaultKeysFormValues
} from "@/containers/NewIntegration/Steps/AddDefaultKeys/hooks/useAddDefaultKeysForm";
import AddDefaultKeysDescriptionFrame from "@/containers/NewIntegration/Steps/AddDefaultKeys/components/AddDefaultKeysDescriptionFrame";
import AddDefaultKeysForm from "@/containers/NewIntegration/Steps/AddDefaultKeys/components/AddDefaultKeysForm";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useIntegration } from "@/hooks/useIntegration";
import { constructRequest } from "@/containers/NewIntegration/Steps/AddDefaultKeys/utils/mapper";

const AddDefaultKeys: React.FC = () => {
  const {
    data: { defaultConfig, integrationId },
    goNextWithUpdate
  } = useIntegration();
  const form = useAddDefaultKeysForm();
  const { t } = useTranslation();
  const { notifyError } = useToast();

  useEffect(() => {
    const additionalConfigs = defaultConfig?.filter((config) => config.isAdditionalDefaultConfig);
    if (isNotEmpty(additionalConfigs)) {
      form.reset({
        configs: additionalConfigs
      });
    }
  }, [defaultConfig]);

  const updateIntegrationAndGoToNextStep = async (values: AddDefaultKeysFormValues) => {
    try {
      const response = await ReevApiCalls.updateAdditionalDefaultConfigs({
        pathParams: { integrationId },
        payload: constructRequest(values)
      });
      goNextWithUpdate({ defaultConfig: response.data.defaultConfig });
    } catch (err) {
      notifyError(translateError(err));
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    form.handleSubmit(updateIntegrationAndGoToNextStep)();
  };

  return (
    <Flex flexDirection="column" gap={20}>
      <SubHeader />
      <form onSubmit={onSubmit}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" gap={20}>
          <Flex flexDirection="column" gap={12} width="100%">
            <AddDefaultKeysDescriptionFrame />
            <AddDefaultKeysForm form={form} />
          </Flex>
          <Button
            data-testid={"AddDefaultKeys_Button"}
            label={t("NEXT")}
            useNunitoFont
            width="100%"
            type="submit"
            disabled={!form.formState.isValid || form.formState.isSubmitting}
          />
        </Flex>
      </form>
    </Flex>
  );
};

export default AddDefaultKeys;
