import FieldArray from "@/components/FieldArray/FieldArray";
import Frame from "@/components/Frame/Frame";
import LabeledContent from "@/components/LabeledContent/LabeledContent";
import ValidatedTextInput from "@/components/ValidatedTextInput/ValidatedTextInput";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import { Divider, IconTypes } from "@emonvia/web-components";
import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import buildings from "@/assets/images/buildings.svg";
import { VendorFormValues } from "@/containers/NewIntegration/Steps/Vendor/hooks/useVendorForm";

type IProps = {
  form: UseFormReturn<VendorFormValues>;
};

const VendorForm: FC<IProps> = ({ form }) => {
  const { t } = useTranslation();
  const { control } = form;

  return (
    <Frame header={t("VENDOR_INFORMATION")}>
      <LabeledContent
        htmlFor="vendor-name"
        img={<img src={buildings} alt={`${t("VENDOR_NAME")}-icon`} />}
        label={t("VENDOR_NAME")}
      >
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState: { error } }) => (
            <ValidatedTextInput
              {...field}
              placeholder={t("ENTER_VENDOR_NAME")}
              errorMessage={error?.message as TransKey}
              removeSpaces={false}
            />
          )}
        />
      </LabeledContent>
      <Divider spacing={16} />
      <LabeledContent
        icon={"las la-comment-alt" as IconTypes}
        label={t("VENDOR_OCPP_NAMES_AS_FOUND_IN_BOOT")}
      >
        <FieldArray control={control} name="ocppNames" placeholder={t("ENTER_VENDOR_OCPP_NAME")} />
      </LabeledContent>
    </Frame>
  );
};

export default VendorForm;
