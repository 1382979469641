import EditConfigModal from "@/components/EditConfigModal/EditConfigModal";
import { EditConfigKeysFormValues } from "@/components/EditConfigModal/hooks/useEditConfigForm";
import FieldGroupArray from "@/components/FieldGroupArray/FieldGroupArray";
import Text from "@/components/Text/Text";
import UnorderedList from "@/components/UnorderedList/UnorderedList";
import useAddFirmwareVersionForm from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/modals/AddFirmwareVersionForModelConfigModal/useAddFirmwareVersionForm";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import { Flex, IconButton, IconTypes } from "@emonvia/web-components";
import { FC } from "react";
import { IConfigurationTemplate } from "@/api/ApiTypes";
import {
  fallbackConfigurationMapper,
  toConfigFormType
} from "@/containers/VendorConfigurations/utils/mapper";

export type FirmwareVersions = {
  firmwareVersions: string[];
};

type IProps = {
  config: IConfigurationTemplate[];
  onClose: () => void;
  onSubmit?: (config: EditConfigKeysFormValues & FirmwareVersions) => void;
};

const AddFirmwareVersionForModelConfigModal: FC<IProps> = ({
  config: modelConfiguration,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation();
  const form = useAddFirmwareVersionForm();
  const descriptionPoints: TransKey[] = [
    "ADD_OR_DELETE_NEW_FIRMWARE_VERSION_INSTRUCTION",
    "ADD_ADDITIONAL_FIRMWARE_VERSIONS_INSTRUCTIONS"
  ];

  const handleSubmit = (config: EditConfigKeysFormValues) => {
    onSubmit({
      ...config,
      firmwareVersions: form
        .getValues()
        .firmwareVersions.map((versions) => versions.firmwareVersion)
        .filter((item) => item !== undefined)
    });
  };

  const config = fallbackConfigurationMapper(modelConfiguration);

  return (
    <EditConfigModal
      config={toConfigFormType(config)}
      onSubmit={handleSubmit}
      onClose={onClose}
      title="ADD_FIRMWARE_VERSIONS_FOR_MODEL_CONFIG"
      firmwareVersions={form.getValues().firmwareVersions}
      description={
        <Flex flexDirection="column" gap={20} margin={[0, 0, 10, 0]}>
          <UnorderedList items={descriptionPoints} />
          <Flex gap={50}>
            <div style={{ paddingTop: 18 }}>
              <Text size={18} weight={300} color="grey750">
                {t("ADDITONAL_FIRMWARE_VERSIONS")}
              </Text>
            </div>
            <FieldGroupArray
              form={form}
              group={{
                name: "firmwareVersions",
                orientation: "horizontal",
                fields: [
                  {
                    name: "firmwareVersion",
                    placeholder: t("ENTER_NEW_FIRMWARE_VERSION"),
                    width: "315px"
                  }
                ],
                hideAppendButton: true,
                hideDivider: true,
                postfix: (_, remove, append, isLast) => (
                  <Flex height="100%" alignItems="center">
                    <IconButton
                      icon={isLast ? IconTypes.plus : IconTypes.trash}
                      look="secondary"
                      onClick={isLast ? append : remove}
                    />
                  </Flex>
                )
              }}
            />
          </Flex>
        </Flex>
      }
    />
  );
};

export default AddFirmwareVersionForModelConfigModal;
