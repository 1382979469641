import { OptionsType } from "@/@types/utils";
import FieldGroupArray from "@/components/FieldGroupArray/FieldGroupArray";
import Frame from "@/components/Frame/Frame";
import { AddCustomAccessControlKeysFormValues } from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/hooks/useAddCustomAccessControlKeysForm";
import { useNotBooleanAccessControlFields } from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/hooks/useNotBooleanAccessControlFields";
import { StyledLinkButtonContainer } from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/styles";
import { useTranslation } from "@/utils/translations/Translator";
import { IconTypes, LinkButton, Types } from "@emonvia/web-components";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

type IProps = {
  form: UseFormReturn<AddCustomAccessControlKeysFormValues>;
};

const AddCustomAccessControlKeysForm: FC<IProps> = ({ form }) => {
  const { addOrRemove, has } = useNotBooleanAccessControlFields(form);
  const { t } = useTranslation();

  const booleanOptions: OptionsType<string> = [
    { label: "true", value: "true" },
    { label: "false", value: "false" }
  ];

  return (
    <Frame>
      <FieldGroupArray
        form={form}
        group={{
          name: "customAccessControlConfig",
          label: (number) => t("ACCESS_CONTROL_KEY_NUMBER", { number }),
          icon: IconTypes.key,
          footer: (index) => (
            <StyledLinkButtonContainer>
              <LinkButton
                look="primary"
                fontType={Types.ns_14_m}
                label={has(index) ? t("USE_BOOLEAN_TYPE") : t("USE_NOT_BOOLEAN_TYPE")}
                onClick={() => addOrRemove(index)}
                style={{ fontStyle: "italic" }}
              />
            </StyledLinkButtonContainer>
          ),
          fields: [
            {
              name: "key",
              placeholder: t("ENTER_ACCESS_CONTROL_KEY")
            },
            {
              name: "restrictedConfigurationKeyValue",
              label: t("ACCESS_CONTROL_ON"),
              placeholder: (index) =>
                has(index)
                  ? t("ENTER_ACCESS_CONTROL_ON_VALUE")
                  : t("SELECT_ACCESS_CONTROL_ON_VALUE"),
              type: (index) => (has(index) ? "text" : "select"),
              selectOptions: booleanOptions,
              orientation: "horizontal"
            },
            {
              name: "openConfigurationKeyValue",
              label: t("ACCESS_CONTROL_OFF"),
              placeholder: (index) =>
                has(index)
                  ? t("ENTER_ACCESS_CONTROL_OFF_VALUE")
                  : t("SELECT_ACCESS_CONTROL_OFF_VALUE"),
              type: (index) => (has(index) ? "text" : "select"),
              selectOptions: booleanOptions,
              orientation: "horizontal"
            }
          ]
        }}
      />
    </Frame>
  );
};

export default AddCustomAccessControlKeysForm;
