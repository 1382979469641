import { useForm } from "react-hook-form";
import { InferType, array, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { requiredStringSchemaWithMaxChars } from "@/utils/validation/schemas";

const ocppNameSchema = {
  name: requiredStringSchemaWithMaxChars({ max: 20 })
};

const vendorFormSchema = object({
  name: requiredStringSchemaWithMaxChars({ max: 20 }),
  ocppNames: array().of(object().shape(ocppNameSchema)).required("FIELD_REQUIRED").min(1, "MIN_1")
});

export type VendorFormValues = InferType<typeof vendorFormSchema>;

export default function useVendorForm() {
  return useForm({
    resolver: yupResolver<VendorFormValues>(vendorFormSchema),
    mode: "onChange",
    defaultValues: {
      ocppNames: [{ name: "" }]
    }
  });
}
