import { AddCustomAccessControlKeysFormValues } from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/hooks/useAddCustomAccessControlKeysForm";
import { reconstuctResponse } from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/utils/mapper";
import { useIntegration } from "@/hooks/useIntegration";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

export const useNotBooleanAccessControlFields = (
  form: UseFormReturn<AddCustomAccessControlKeysFormValues>
) => {
  const { data } = useIntegration();
  const [notBooleanFieldIndexes, setNotBooleanFieldIndexes] = useState<number[]>([]);

  useEffect(() => {
    if (data.customAccessControlConfig) {
      setNotBooleanFieldIndexes(
        reconstuctResponse(data.customAccessControlConfig).map(
          ({ openConfigurationKeyValue, restrictedConfigurationKeyValue }, index) => {
            if (
              (openConfigurationKeyValue !== "true" && openConfigurationKeyValue !== "false") ||
              (restrictedConfigurationKeyValue !== "true" &&
                restrictedConfigurationKeyValue !== "false")
            )
              return index;
          }
        )
      );
    }
  }, [data.customAccessControlConfig]);

  const resetFields = (index: number) => {
    form.setValue(`customAccessControlConfig.${index}.restrictedConfigurationKeyValue`, undefined, {
      shouldValidate: true
    });
    form.setValue(`customAccessControlConfig.${index}.openConfigurationKeyValue`, undefined, {
      shouldValidate: true
    });
  };

  const add = (index: number) => {
    setNotBooleanFieldIndexes((prev) => [...prev, index]);
    resetFields(index);
  };

  const remove = (index: number) => {
    setNotBooleanFieldIndexes((prev) => prev.filter((i) => i !== index));
    resetFields(index);
  };

  const has = (index: number): boolean => {
    return notBooleanFieldIndexes.findIndex((i) => i === index) !== -1;
  };

  const addOrRemove = (index: number) => {
    if (has(index)) remove(index);
    else add(index);
  };

  return { add, remove, has, addOrRemove };
};
