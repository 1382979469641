/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { useState } from "react";

export let rerenderApp = () => {};

export const withRerenderApp = <P,>(Component: React.ComponentType<P>) => {
  return function useRerenderApp(props: P) {
    const [renderKey, setRenderKey] = useState(0);

    rerenderApp = () => {
      setRenderKey((renderKey) => renderKey + 1);
    };

    return <Component {...props} key={renderKey} />;
  };
};

export const AppRerenderer: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const Component = () => <>{children}</>;
  const ComponentWithRerendering = withRerenderApp(Component);
  return <ComponentWithRerendering />;
};
