import {
  ICustomAccessControlMap,
  ICustomAccessControlMapIntegrationRequest_ICustomAccessControlMap
} from "@/api/ApiTypes";
import { AddCustomAccessControlKeysFormValues } from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/hooks/useAddCustomAccessControlKeysForm";
import { isEmpty } from "@emonvia/web-components";

export const constructRequest = (
  values: AddCustomAccessControlKeysFormValues
): ICustomAccessControlMapIntegrationRequest_ICustomAccessControlMap[] => {
  const customAccessControlMap: ICustomAccessControlMapIntegrationRequest_ICustomAccessControlMap[] =
    [];
  values.customAccessControlConfig
    .filter((config) => !isEmpty(config) && !Object.values(config).some((value) => isEmpty(value)))
    .forEach((config) => {
      customAccessControlMap.push({
        configKey: config.key,
        useCase: "OPEN",
        value: config.openConfigurationKeyValue
      });
      customAccessControlMap.push({
        configKey: config.key,
        useCase: "RESTRICTED",
        value: config.restrictedConfigurationKeyValue
      });
    });

  return customAccessControlMap;
};

export const reconstuctResponse = (
  customAccessControlConfig: ICustomAccessControlMap[]
): AddCustomAccessControlKeysFormValues["customAccessControlConfig"] => {
  return customAccessControlConfig?.reduce(
    (acc: AddCustomAccessControlKeysFormValues["customAccessControlConfig"], currentValue) => {
      const existingConfigIndex = acc.findIndex((config) => config.key === currentValue.configKey);
      if (existingConfigIndex > -1) {
        if (currentValue.useCase === "OPEN") {
          acc[existingConfigIndex].openConfigurationKeyValue = currentValue.value;
        } else {
          acc[existingConfigIndex].restrictedConfigurationKeyValue = currentValue.value;
        }
      } else {
        if (currentValue.useCase === "OPEN") {
          acc.push({
            key: currentValue.configKey,
            openConfigurationKeyValue: currentValue.value
          });
        } else {
          acc.push({
            key: currentValue.configKey,
            restrictedConfigurationKeyValue: currentValue.value
          });
        }
      }
      return acc;
    },
    []
  );
};
