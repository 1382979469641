import { IModelIntegrationRequest_IModel } from "@/api/ApiTypes";
import { isEmpty } from "@emonvia/web-components";

export function constructRequest(payload: { models: IModelIntegrationRequest_IModel[] }) {
  return payload.models
    .map((model) => {
      if (
        isEmpty(model.name) ||
        isEmpty(model.firmwareVersion) ||
        isEmpty(model.electricMeterCalibrationTypeOfModel)
      ) {
        return null;
      }
      if (model.electricMeterCalibrationTypeOfModel !== "SMV") {
        model.smvProperty = null;
      }
      return model;
    })
    .filter((model) => model !== null);
}
