import { Types } from "@emonvia/web-components";

export const AGREED_TO_ANALYTICS_CONSENT = "agreedToAnalyticsConsent";
export const INTEGRATION_ID = "integrationId";
export const ACCEPTED = "ACCEPTED";
export const REJECTED = "REJECTED";

export const DEFAULT_COLUMN_PROPERTIES = {
  disableSortBy: true,
  labelType: Types.ns_18_sb
};
