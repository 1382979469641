import { OrientationType } from "@/components/FieldGroupArray/FieldGroupArray";
import { Flex } from "@emonvia/web-components";
import styled from "styled-components";

export const StyledFieldGroupArray = styled(Flex)<{ orientation: OrientationType }>`
  .group {
    display: grid;
    grid-template-columns: min-content auto;
    grid-row-gap: 12px;
    grid-template-areas:
      "group-icon group-label"
      ". group-fields";

    .group-icon {
      grid-area: group-icon;
      margin-right: 12px;
    }

    .group-label {
      grid-area: group-label;
      align-self: center;
    }

    .group-fields {
      display: flex;
      align-items: flex-start;
      flex-direction: ${({ orientation }) => (orientation === "horizontal" ? "row" : "column")};
      grid-area: group-fields;
      gap: 12px;

      .group-field {
        display: grid;
        width: 100%;
        &.vertical {
          grid-template-columns: min-content auto;
          grid-template-areas:
            "field-icon field-label"
            ". field";

          .field-label {
            margin-bottom: 12px;
          }
        }

        &.horizontal {
          grid-template-columns: min-content 1fr 2fr;
          grid-template-areas: "field-icon field-label field";
          align-items: center;
          justify-content: center;

          .field-label {
            margin-right: 12px;
          }
        }

        .field-icon {
          grid-area: field-icon;
          margin-right: 12px;
          margin-bottom: 12px;
        }

        .field-label {
          grid-area: field-label;
          align-self: center;
        }

        .field {
          grid-area: field;
        }
      }
    }
  }
`;
