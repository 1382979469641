import { Language } from "@emonvia/web-components";
import { format, Locale, parse } from "date-fns";
import { de, enGB, fr, it, nl } from "date-fns/locale";
import i18n from "src/utils/i18n";

export enum DateFormats {
  dateTime = "dd.MM.yyyy HH:mm",
  dateTimeSeconds = "yyyy-MM-dd HH:mm:ss",
  localizedDateTime = "PPpp",
  localizedDate = "P",
  time = "p",
  month = "MMMM",
  year = "yyyy",
  dateTimeNoYear = "dd.MM. HH:mm",
  yearMonth = "MMMM yyyy",
  yearMonthWithDash = "yyyy-MM",
  dateWithDash = "yyyy-MM-dd",
  dateWithWeekdayName = "eeeeee dd.MM.",
  date = "dd.MM.yyyy"
}

const DateFnsLocalesByLanguage: { [key in Language]: Locale } = {
  [Language.EN]: enGB,
  [Language.DE]: de,
  [Language.IT]: it,
  [Language.FR]: fr,
  [Language.NL]: nl
};

function getDateFnsLocale() {
  const currentLanguage = i18n.language.toUpperCase() as Language;
  return DateFnsLocalesByLanguage[currentLanguage] || enGB;
}

export function formatDate(date: Date | string | number, type: DateFormats = DateFormats.dateTime) {
  if (!date) {
    return "-";
  }

  if (typeof date === "number" || typeof date === "string") {
    date = new Date(date);
  }

  return format(date, type, { locale: getDateFnsLocale() });
}

export function parseDate(date: string, format: DateFormats = DateFormats.dateWithDash) {
  return parse(date, format, new Date());
}

export function getFirstAndLastDayOfPreviousMonthFrom(someMoment: Date) {
  const sameDayFromPreviousMonth = new Date(someMoment).setMonth(someMoment.getMonth() - 1);
  const { firstDayOfMonth: firstDayOfPreviousMonth, lastDayOfMonth: lastDayOfPreviousMonth } =
    getFirstAndLastDayOfMonthFrom(new Date(sameDayFromPreviousMonth));

  return {
    firstDayOfPreviousMonth,
    lastDayOfPreviousMonth
  };
}

export function getFirstAndLastDayOfMonthFrom(someMoment: Date) {
  return {
    firstDayOfMonth: new Date(someMoment.getFullYear(), someMoment.getMonth(), 1),
    lastDayOfMonth: new Date(someMoment.getFullYear(), someMoment.getMonth() + 1, 0)
  };
}

export function dateToLocalDate(date: Date | string, time: string) {
  return `${date}T${time}`;
}
