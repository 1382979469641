import { requiredStringSchema } from "@/utils/validation/schemas";
import { isNotEmpty } from "@emonvia/web-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InferType, array, object, ref, string } from "yup";

const customAccessControlKeySchema = object().shape(
  {
    key: string().when(["restrictedConfigurationKeyValue", "openConfigurationKeyValue"], {
      is: (restrictedConfigurationKeyValue: string, openConfigurationKeyValue: string) =>
        isNotEmpty(restrictedConfigurationKeyValue) || isNotEmpty(openConfigurationKeyValue),
      then: requiredStringSchema
    }),
    restrictedConfigurationKeyValue: string().when("key", {
      is: (key: string) => isNotEmpty(key),
      then: requiredStringSchema
    }),
    openConfigurationKeyValue: string().when("key", {
      is: (key: string) => isNotEmpty(key),
      then: (schema) =>
        requiredStringSchema(schema).notOneOf(
          [ref("restrictedConfigurationKeyValue")],
          "KEY_VALUES_CANNOT_BE_THE_SAME"
        )
    })
  },
  [
    ["key", "restrictedConfigurationKeyValue"],
    ["key", "openConfigurationKeyValue"]
  ]
);

const customAccessControlKeysSchema = object().shape({
  customAccessControlConfig: array().of(customAccessControlKeySchema).nullable().notRequired()
});

export type AddCustomAccessControlKeysFormValues = InferType<typeof customAccessControlKeysSchema>;

export default function useAddCustomAccessControlKeysForm() {
  return useForm({
    resolver: yupResolver<AddCustomAccessControlKeysFormValues>(customAccessControlKeysSchema),
    mode: "onChange",
    defaultValues: {
      customAccessControlConfig: [{}]
    }
  });
}
