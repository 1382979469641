import React from "react";

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class DefaultErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  public render() {
    const { hasError, error, errorInfo } = this.state;
    if (hasError) {
      return (
        <>
          <h1 style={{ textAlign: "center" }}>
            Something went wrong. please <a href="/start">start</a> again.
          </h1>
          <p>{JSON.stringify(error?.message)}</p>
          <p> {JSON.stringify(errorInfo?.componentStack.trim())}</p>
        </>
      );
    }
    return this.props.children;
  }
}
