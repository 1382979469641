import React from "react";
import { Flex } from "@emonvia/web-components";
import { useNavigate, useParams } from "react-router-dom";
import AllRoutes from "@/routing/AllRoutes";
import Sidebar from "@/components/Sidebar/Sidebar";
import SidebarHeader from "@/components/Sidebar/SidebarHeader";
import ModelOverviewSection from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/sections/ModelOverviewSection";
import ModelConfigurationTemplateSection from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/sections/ModelConfigurationTemplateSection";
import ModelFallbackConfigurationTemplateSection from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/sections/ModelFallbackConfigurationTemplateSection";
import useModelConfigurationQuery from "@/hooks/useModelConfigurationQuery";

const ModelConfigurationsDetails: React.FC = () => {
  const { vendor, model, firmwareId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useModelConfigurationQuery({
    vendor,
    model,
    id: firmwareId
  });

  const {
    model: modelName,
    vendor: vendorName,
    firmware,
    verificationType,
    calibrationType,
    startupConfiguration,
    configurationTemplates = [],
    modelFallbackConfigurationTemplates = [],
    interfacePort,
    connectionSchema
  } = data || {};

  const toggleSidebar = () => {
    navigate(AllRoutes.MODEL_CONFIGURATIONS_INDEX);
  };

  return (
    <Sidebar title={<SidebarHeader label={model} />} onClose={toggleSidebar} isLoading={isLoading}>
      <Flex flexDirection="column" padding={[24, 0, 0]} gap={24}>
        <Flex flexDirection="row">
          <ModelOverviewSection
            vendor={vendorName}
            model={modelName}
            firmwareVersion={firmware}
            calibrationType={calibrationType}
            verificationType={verificationType}
            startupConfiguration={startupConfiguration}
            refetchDetails={refetch}
            config={configurationTemplates}
            interfacePort={interfacePort}
            connectionSchema={connectionSchema}
          />
        </Flex>
        <Flex flexDirection="row">
          <ModelConfigurationTemplateSection
            modelConfigurationTemplates={configurationTemplates}
            firmwareVersion={firmware}
            vendor={vendorName}
            model={modelName}
            refetchDetails={refetch}
          />
        </Flex>
        <Flex flexDirection="row">
          <ModelFallbackConfigurationTemplateSection
            modelFallbackConfigurationTemplates={modelFallbackConfigurationTemplates}
            vendor={vendorName}
            model={modelName}
            refetchDetails={refetch}
          />
        </Flex>
      </Flex>
    </Sidebar>
  );
};

export default ModelConfigurationsDetails;
