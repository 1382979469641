import { Button } from "@emonvia/web-components";
import { translateToEnglish } from "@/utils/translations/Translator";
import { withTracking } from "@/context/TrackingContext";

const TrackedButton = withTracking(Button, {
  actionAccessor: "onClick",
  generateMessage: (props: { label: string }) =>
    `Clicked button with text ${translateToEnglish(props.label)}`
});

export default TrackedButton;
