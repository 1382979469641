import React, { FormEvent, useEffect } from "react";
import { Flex, isNotEmpty, useToast } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import SubHeader from "@/components/SubHeader/SubHeader";
import AddCustomAccessControlKeysDescriptionFrame from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/components/AddCustomAccessControlKeysDescriptionFrame";
import AddCustomAccessControlKeysForm from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/components/AddCustomAccessControlKeysForm";
import useAddCustomAccessControlKeysForm, {
  AddCustomAccessControlKeysFormValues
} from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/hooks/useAddCustomAccessControlKeysForm";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useIntegration } from "@/hooks/useIntegration";
import {
  constructRequest,
  reconstuctResponse
} from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/utils/mapper";

const AddCustomAccessControlKeys: React.FC = () => {
  const {
    data: { integrationId, customAccessControlConfig },
    goNextWithUpdate
  } = useIntegration();
  const { t } = useTranslation();
  const form = useAddCustomAccessControlKeysForm();
  const { notifyError } = useToast();

  useEffect(() => {
    if (isNotEmpty(customAccessControlConfig)) {
      form.reset({ customAccessControlConfig: reconstuctResponse(customAccessControlConfig) });
    }
  }, [customAccessControlConfig]);

  const updateIntegrationAndGoToNextStep = async (values: AddCustomAccessControlKeysFormValues) => {
    try {
      const response = await ReevApiCalls.updateCustomAccessControlMap({
        pathParams: { integrationId },
        payload: { customAccessControlMap: constructRequest(values) }
      });
      goNextWithUpdate({ customAccessControlConfig: response.data.customAccessControlConfig });
    } catch (err) {
      notifyError(translateError(err));
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    form.handleSubmit(updateIntegrationAndGoToNextStep)();
  };

  return (
    <Flex flexDirection="column" gap={20}>
      <SubHeader />
      <form onSubmit={onSubmit}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" gap={20}>
          <AddCustomAccessControlKeysDescriptionFrame />
          <AddCustomAccessControlKeysForm form={form} />
          <Button
            data-testid={"AddCustomAccessControlKeys_Button"}
            label={t("CONFIRM")}
            useNunitoFont
            width="100%"
            margin={[20, 0, 0, 0]}
            type="submit"
            disabled={!form.formState.isValid || form.formState.isSubmitting}
          />
        </Flex>
      </form>
    </Flex>
  );
};

export default AddCustomAccessControlKeys;
