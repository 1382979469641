import { DefaultConfigurationKeysList } from "@/containers/NewIntegration/Steps/DefaultKeys/components/DefaultConfigurationKeysListFrame";
import ProvidedConfigurationKeysList from "@/containers/NewIntegration/Steps/Report/components/ProvidedConfigurationKeysList";

const AllConfigurationKeysList = () => {
  return (
    <>
      <DefaultConfigurationKeysList showDefaultValueLabel />
      <ProvidedConfigurationKeysList />
    </>
  );
};

export default AllConfigurationKeysList;
