import { useQuery } from "react-query";
import ModelQueryKeys from "@/containers/ModelConfigurations/utils/QueryKeys";
import ReevApiCalls from "@/api/ReevApiCalls";
import { ApiErrorResponse } from "@/utils/enums/ApiErrorResponse";
import { translateError } from "@/utils/translations/Translator";
import { IModelDetails } from "@/api/ApiTypes";
import { useToast } from "@emonvia/web-components";

interface IProps extends Partial<IModelDetails> {}

const useModelConfigurationQuery = ({ vendor, model, id: firmwareId }: IProps) => {
  const { notifyError } = useToast();

  const { data, isLoading, refetch } = useQuery(
    [ModelQueryKeys.DETAIL, vendor, model, firmwareId],
    () => ReevApiCalls.getConfigTemplateByFirmwareId({ pathParams: { vendor, model, firmwareId } }),
    {
      onError: (e: ApiErrorResponse) => notifyError(translateError(e))
    }
  );
  return {
    data,
    isLoading,
    refetch
  };
};

export default useModelConfigurationQuery;
