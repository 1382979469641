import React from "react";
import { Flex, useToast } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import SubHeader from "@/components/SubHeader/SubHeader";
import ReportDescriptionFrame from "@/containers/NewIntegration/Steps/Report/components/ReportDescriptionFrame";
import ReportAccordionList from "@/containers/NewIntegration/Steps/Report/components/accordions/ReportAccordionList";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useIntegration } from "@/hooks/useIntegration";

const Report: React.FC = () => {
  const {
    data: { integrationId },
    finish
  } = useIntegration();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useToast();

  const onSubmit = async () => {
    try {
      await ReevApiCalls.integrateNewVendorToIntegrationService({ pathParams: { integrationId } });
      notifySuccess(t("INTEGRATION_REQUEST_COMPLETED"));
      finish();
    } catch (err) {
      notifyError(translateError(err));
    }
  };

  return (
    <Flex flexDirection="column" gap={20}>
      <SubHeader />
      <Flex flexDirection="column" justifyContent="center" alignItems="center" gap={20}>
        <ReportDescriptionFrame />
        <ReportAccordionList />
        <Button
          data-testid={"Submit_Button"}
          label={t("SUBMIT")}
          useNunitoFont
          width="100%"
          onClick={onSubmit}
        />
      </Flex>
    </Flex>
  );
};

export default Report;
