import React, { ReactNode, useEffect, useRef } from "react";
import { TextInput } from "@emonvia/web-components";
import AlertTriangle from "@/assets/images/alert-triangle.svg";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import { StyledValidatedTextInput } from "@/components/ValidatedTextInput/styles";

const AlertElement = <img src={AlertTriangle} width={18} height={18} alt="alert icon" />;

type TextInputProps = React.ComponentProps<typeof TextInput>;

export type IValidatedTextInputProps = TextInputProps & {
  "data-testid"?: string;
  onChange?: (val: string) => void;
  errorMessage?: TransKey;
  valid?: boolean;
  endElement?: ReactNode;
  removeSpaces?: boolean;
};

const getEndElement = (errorMessage: React.ReactNode | string) => {
  const errorMessageWasGiven = !!errorMessage;

  if (errorMessageWasGiven) {
    return AlertElement;
  }
};

const ValidatedTextInput: React.FC<IValidatedTextInputProps> = (props) => {
  const { t } = useTranslation();
  const { errorMessage, onChange, endElement, removeSpaces = true } = props;
  const initialLoad = useRef(true);
  const userHasInteracted = useRef(false);
  const validators = [];

  if (removeSpaces) {
    validators.push("removeSpaces");
  }

  useEffect(() => {
    initialLoad.current = false;
  }, []);

  const onChangeWithTracking = (val: string) => {
    if (onChange) {
      onChange(val);
    }

    if (!initialLoad.current) {
      userHasInteracted.current = true;
    }
  };

  return (
    <StyledValidatedTextInput
      {...props}
      onChange={onChangeWithTracking}
      endElement={getEndElement(errorMessage) || endElement}
      look="secondary"
      asteriskColor="secondary"
      errorMessage={t(errorMessage)}
      validators={validators}
    />
  );
};

export default ValidatedTextInput;
