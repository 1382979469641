import { useState } from "react";

export default function useEditableFields() {
  const [indexes, setIndexes] = useState<number[]>([]);

  const add = (index: number) => {
    setIndexes((prev) => [...prev, index]);
  };

  const remove = (index: number) => {
    setIndexes((prev) => prev.filter((i) => i !== index));
  };

  const has = (index: number): boolean => {
    return indexes.findIndex((i) => i === index) !== -1;
  };

  const toggle = (index: number) => {
    if (has(index)) remove(index);
    else add(index);
  };

  return { add, remove, has, toggle };
}
