import ReactDOM from "react-dom/client";

import App from "@/App";
import ErrorBoundary from "@/components/ErrorMonitoring/ErrorBoundary";

const rootElement: HTMLElement = document.getElementById("root");

ReactDOM.createRoot(rootElement).render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);
