import React, { useMemo, useState } from "react";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import { BorderPanel } from "@/components/Panel/styles";
import useVendorFallbackConfigurationColumns from "@/containers/VendorConfigurations/hooks/useVendorFallbackConfigurationColumns";
import { Flex, useToast } from "@emonvia/web-components";
import Table from "@/components/Table/Table";
import EditVendorFallbackConfiguration from "@/containers/VendorConfigurations/components/VendorConfigurationDetails/modals/EditVendorFallbackConfiguration";
import { IConfigurationTemplate } from "@/api/ApiTypes";
import {
  fallbackConfigurationMapper,
  filterAuthorizationConfigurationKeys,
  filterDefaultConfigurationKeys,
  formValuesToRequest
} from "@/containers/VendorConfigurations/utils/mapper";
import { EditConfigKeysFormValues } from "@/components/EditConfigModal/hooks/useEditConfigForm";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useParams } from "react-router-dom";

type IProps = {
  fallbackConfiguration: IConfigurationTemplate[];
  refetchDetails: () => void;
};

const VendorFallbackConfigurationTemplateSection: React.FC<IProps> = ({
  fallbackConfiguration,
  refetchDetails
}) => {
  const { notifySuccess, notifyError } = useToast();
  const { vendor } = useParams();
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const { t } = useTranslation();

  const defaultConfigColumns = useVendorFallbackConfigurationColumns();
  const authConfigColumns = useVendorFallbackConfigurationColumns(true);

  const handleEditSubmit = async (values: EditConfigKeysFormValues) => {
    const payload = formValuesToRequest(values);
    try {
      await ReevApiCalls.updateVendorFallbackConfig({
        pathParams: { vendor },
        payload
      });
      notifySuccess(t("CONFIGURATION_SUBMITTED_SUCCESSFULLY"));
      setIsEditModalOpened(false);
      refetchDetails();
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  const config = useMemo(
    () => fallbackConfigurationMapper(fallbackConfiguration),
    [fallbackConfiguration]
  );
  const defaultConfigs = useMemo(() => filterDefaultConfigurationKeys(config), [config]);
  const authorizationConfigs = useMemo(
    () => filterAuthorizationConfigurationKeys(config),
    [config]
  );

  return (
    <>
      <BorderPanel
        title={t("VENDOR_FALLBACK_CONFIGURATION_TEMPLATE")}
        tooltipText={t("THE_CONFIGURATION_TEMPLATE_FOR_THE_VENDOR_FOR_UNKNOWN_MODEL")}
        showAction
        onEditClick={() => setIsEditModalOpened(true)}
      >
        <Flex flexDirection="column" width={"100%"}>
          <Table
            data={defaultConfigs}
            columns={defaultConfigColumns}
            meta={{}}
            inTotalTranslation={t("IN_TOTAL")}
          />
          {authorizationConfigs.length > 0 && (
            <Table
              data={authorizationConfigs}
              columns={authConfigColumns}
              meta={{}}
              inTotalTranslation={t("IN_TOTAL")}
            />
          )}
        </Flex>
      </BorderPanel>

      {isEditModalOpened && (
        <EditVendorFallbackConfiguration
          config={config}
          onSubmit={handleEditSubmit}
          onClose={() => setIsEditModalOpened(false)}
        />
      )}
    </>
  );
};

export default VendorFallbackConfigurationTemplateSection;
