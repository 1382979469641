import { IPaginatedModelQueryRequest } from "@/api/ApiTypes";
import ReevApiCalls from "@/api/ReevApiCalls";
import { Status } from "@/utils/enums/Status";
import { translateError } from "@/utils/translations/Translator";
import { ApiErrorResponse, useToast } from "@emonvia/web-components";
import { useQuery } from "react-query";
import { ModelQueryType } from "@/containers/ModelConfigurations/utils/QueryKeys";

interface Filters {
  searchQuery?: string;
  vendor?: string;
  model?: string;
}

interface UseFetchModelsParams {
  page: number;
  pageSize: number;
  sortBy: IPaginatedModelQueryRequest["sortBy"];
  sortOrder: IPaginatedModelQueryRequest["sortOrder"];
  filters: Filters;
  status: Status;
  queryKey: ModelQueryType;
}

export const useFetchModelsQuery = ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  filters,
  status,
  queryKey
}: UseFetchModelsParams) => {
  const { notifyError } = useToast();

  return useQuery(
    [queryKey, page, sortBy, sortOrder, filters],
    () =>
      ReevApiCalls.getAllModels({
        queryParams: {
          request: {
            page,
            pageSize,
            sortBy,
            sortOrder,
            status,
            search: filters.searchQuery,
            vendor: filters.vendor,
            model: filters.model
          }
        }
      }),
    {
      onError: (err: ApiErrorResponse) => notifyError(translateError(err))
    }
  );
};
