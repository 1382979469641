import AccordionFallbackModelConfigurationTemplate from "@/containers/NewIntegration/Steps/Report/components/accordions/items/AccordionFallbackModelConfigurationTemplate";
import AccordionModel from "@/containers/NewIntegration/Steps/Report/components/accordions/items/AccordionModel";
import AccordionModelConfigurationTemplate from "@/containers/NewIntegration/Steps/Report/components/accordions/items/AccordionModelConfigurationTemplate";
import AccordionVendor from "@/containers/NewIntegration/Steps/Report/components/accordions/items/AccordionVendor";
import { Flex } from "@emonvia/web-components";

const ReportAccordionList = () => {
  return (
    <Flex flexDirection="column" width="100%" gap={12}>
      <AccordionVendor />
      <AccordionModel />
      <AccordionModelConfigurationTemplate />
      <AccordionFallbackModelConfigurationTemplate />
    </Flex>
  );
};

export default ReportAccordionList;
