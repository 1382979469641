import { StyledUnorderedList } from "@/components/UnorderedList/styles";
import { TransKey } from "@/utils/translations/Translator";
import { FC } from "react";
import { Trans } from "react-i18next";

type IProps = {
  items: TransKey[];
};

const UnorderedList: FC<IProps> = ({ items }) => {
  return (
    <StyledUnorderedList>
      {items.map((point) => (
        <li key={point}>
          <Trans
            i18nKey={point}
            components={{
              u: <u />,
              b: <strong />
            }}
          />
        </li>
      ))}
    </StyledUnorderedList>
  );
};

export default UnorderedList;
