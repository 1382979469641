import { FC, PropsWithChildren, ReactNode } from "react";
import { FrameContainer } from "@/components/Frame/styles";
import { Flex, StyledText, Types } from "@emonvia/web-components";

export type FrameLook = "primary" | "secondary";

type IProps = {
  header?: ReactNode | string;
  look?: "primary" | "secondary";
};

const Frame: FC<PropsWithChildren<IProps>> = ({ children, header, look = "primary" }) => {
  return (
    <FrameContainer data-testid={"Info_Frame"} frameLook={look}>
      <Flex flexDirection="column" gap={12} padding={[20]}>
        {header && <StyledText text={header} type={Types.ns_16_m} />}
        {children}
      </Flex>
    </FrameContainer>
  );
};

export default Frame;
