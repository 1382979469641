import React from "react";
import { Flex, StyledText, Types, useToast } from "@emonvia/web-components";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import Card from "@/components/Card/Card";
import NewChargePointIntegration from "@/assets/images/new_charge_point_integration.svg";
import ChargePointApproval from "@/assets/images/charge_point_approval.svg";
import VendorLevelConfiguration from "@/assets/images/vendor_level_configuration.svg";
import ModelLevelConfiguration from "@/assets/images/model_level_configuration.svg";
import { useIntegration } from "@/hooks/useIntegration";
import { LandingInternalContainer } from "@/containers/InitialScreens/styles";
import { goToNextStep } from "@/routing/NewIntegrationRoutes";
import { useNavigate } from "react-router-dom";
import AllRoutes from "@/routing/AllRoutes";

const LandingInternal: React.FC = () => {
  const { t } = useTranslation();
  const { start, isLoading } = useIntegration();
  const { notifyError } = useToast();
  const navigate = useNavigate();

  const startIntegration = async () => {
    try {
      await start();
      goToNextStep();
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  const goToVendorConfigurations = () => {
    navigate(AllRoutes.VENDOR_CONFIGURATIONS_INDEX);
  };

  const goToModelConfigurations = () => {
    navigate(AllRoutes.MODEL_CONFIGURATIONS_INDEX);
  };

  const goToApproval = () => {
    navigate(AllRoutes.CHARGE_POINT_APPROVAL_INDEX);
  };

  return (
    <LandingInternalContainer>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={40}
        width={"100%"}
      >
        <StyledText text={t("HARDWARE_INTEGRATION_SERVICE")} type={Types.ns_24_sb} />
        <section className="grid-container">
          <Card
            name={t("ADD_NEW_VENDOR_OR_MODEL")}
            imageSrc={NewChargePointIntegration}
            onClick={startIntegration}
            disabled={isLoading}
          />
          <Card
            name={t("CHARGE_POINT_APPROVAL")}
            imageSrc={ChargePointApproval}
            onClick={goToApproval}
          />
          <Card
            name={t("VENDOR_LEVEL_CONFIGURATION")}
            imageSrc={VendorLevelConfiguration}
            onClick={goToVendorConfigurations}
          />
          <Card
            name={t("MODEL_LEVEL_CONFIGURATION")}
            imageSrc={ModelLevelConfiguration}
            onClick={goToModelConfigurations}
          />
        </section>
      </Flex>
    </LandingInternalContainer>
  );
};

export default LandingInternal;
