/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults } from "axios";
import conf from "@/env";
import i18next from "i18next";
import { ApiErrorResponse } from "@/utils/enums/ApiErrorResponse";

const BASE_URL = conf.apiUrl;

const config: CreateAxiosDefaults = {
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 10000
};

export const unauthApi = axios.create(config);

export const changeBackendUrl = (newUrl: string) => {
  unauthApi.defaults.baseURL = newUrl;
};

export const getBackendUrl = () => {
  return unauthApi.defaults.baseURL;
};

unauthApi.interceptors.response.use(
  (res: any) => res,
  (error: { response?: any }) => {
    if (error.response) {
      return Promise.reject(error.response.data as ApiErrorResponse);
    }
    const { response } = error;
    return Promise.reject(response as ApiErrorResponse);
  }
);

export interface IInvokeOptions {
  noAuthentication?: boolean;
  headers?: string[];
  virtualCPCall?: boolean;
  responseType?: string;
}

class AjaxService {
  private _onSessionExpireHandle?: (error: AxiosError) => void;

  public setOnSessionExpireHandle(onSessionExpireHandle: (error: AxiosError) => void) {
    this._onSessionExpireHandle = onSessionExpireHandle;
  }

  public get endpoint(): string {
    return this.basePath;
  }

  private get basePath() {
    return conf.apiUrl || "";
  }

  private get defaultHeaders() {
    return {
      "Content-Type": "application/json",
      "Accept-Language": i18next.language
    };
  }

  private invoke<T>(
    method: string,
    url: string,
    data?: any,
    options?: IInvokeOptions,
    defaultResponseType = "json"
  ) {
    const defaultHeaders = this.defaultHeaders;
    const responseType = options?.responseType || defaultResponseType;

    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: { request: { responseType: string }; response: { data: Blob } }) => {
        if (
          error.request.responseType === "blob" &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data?.type.toLowerCase().indexOf("json") !== -1
        ) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              if (typeof reader.result === "string") {
                error.response.data = JSON.parse(reader.result);
              }
              resolve(Promise.reject(error));
            };

            reader.onerror = () => {
              reject(error);
            };

            reader.readAsText(error.response.data);
          });
        }

        return Promise.reject(error);
      }
    );

    return axios({
      method,
      url: `${this.basePath}${url}`,
      headers: defaultHeaders,
      data,
      responseType
    } as AxiosRequestConfig)
      .then((response: AxiosResponse<T>) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          const status = error.response.status;
          const sessionExpired = status === 401;

          if (this._onSessionExpireHandle && sessionExpired) {
            this._onSessionExpireHandle(error);
          }

          return Promise.reject(error.response.data as ApiErrorResponse);
        }
        const errorResponse = JSON.parse(JSON.stringify(error));

        return Promise.reject(errorResponse as ApiErrorResponse);
      });
  }

  public get<T = any>(url: string, options?: IInvokeOptions, responseType?: string): Promise<T> {
    return this.invoke<T>("GET", url, null, options, responseType);
  }

  public post<T = any>(url: string, data: any = null, options?: IInvokeOptions): Promise<T> {
    return this.invoke<T>("POST", url, data, options);
  }

  public put<T = any>(url: string, data?: any, options?: IInvokeOptions): Promise<T> {
    return this.invoke<T>("PUT", url, data, options);
  }

  public delete<T = any>(url: string, options?: IInvokeOptions): Promise<T> {
    return this.invoke("DELETE", url, null, options);
  }

  public patch<T = any>(url: string, data?: any, options?: IInvokeOptions): Promise<T> {
    return this.invoke("PATCH", url, data, options);
  }
}

export const ajaxService = new AjaxService();
