import { headerHeightPx } from "@/components/Header/styles";
import styled from "styled-components";

export const MainLayoutContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px;
  margin-top: ${headerHeightPx}px;
`;
