import { useToast } from "@emonvia/web-components";
import { Routes as Paths, Route, useNavigate, Navigate } from "react-router-dom";
import AllRoutes, { globalNavigate, globalNotifyError } from "@/routing/AllRoutes";
import LandingInternal from "@/containers/InitialScreens/LandingInternal";
import Vendor from "@/containers/NewIntegration/Steps/Vendor/Vendor";
import Model from "@/containers/NewIntegration/Steps/Model/Model";
import DefaultKeys from "@/containers/NewIntegration/Steps/DefaultKeys/DefaultKeys";
import AddDefaultKeys from "@/containers/NewIntegration/Steps/AddDefaultKeys/AddDefaultKeys";
import AddCustomAccessControlKeys from "@/containers/NewIntegration/Steps/AddCustomAccessControlKeys/AddCustomAccessControlKeys";
import AdditionalModels from "@/containers/NewIntegration/Steps/AditionalModels/AdditionalModels";
import Report from "@/containers/NewIntegration/Steps/Report/Report";
import FormLayout from "@/components/layouts/FormLayout/FormLayout";
import MainLayout from "@/components/layouts/MainLayout/MainLayout";
import VendorConfigurationsIndex from "@/containers/VendorConfigurations/VendorConfigurationsIndex";
import VendorConfigurationDetails from "@/containers/VendorConfigurations/components/VendorConfigurationDetails/VendorConfigurationDetails";
import ModelConfigurationsIndex from "@/containers/ModelConfigurations/ModelConfigurationsIndex";
import ModelConfigurationsDetails from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/ModelConfigurationsDetails";
import ChargePointApprovalDetails from "@/containers/ChargePointApproval/components/ModelConfigurationsDetails/ChargePointApprovalDetails";
import ChargePointApprovalIndex from "@/containers/ChargePointApproval/index";

const Routes = () => {
  const navigate = useNavigate();
  const { notifyError } = useToast();

  globalNavigate.current = navigate;
  globalNotifyError.current = notifyError;

  return (
    <Paths>
      <Route Component={FormLayout}>
        <Route path={AllRoutes.START} element={<LandingInternal />} />
        <Route path={AllRoutes.VENDOR} element={<Vendor />} />
        <Route path={AllRoutes.MODEL} element={<Model />} />
        <Route path={AllRoutes.DEFAULT_CONFIG} element={<DefaultKeys />} />
        <Route path={AllRoutes.ADDITIONAL_DEFAULT_CONFIG} element={<AddDefaultKeys />} />
        <Route
          path={AllRoutes.CUSTOM_ACCESS_CONTROL_CONFIG_MAP}
          element={<AddCustomAccessControlKeys />}
        />
        <Route path={AllRoutes.ADDITIONAL_MODEL} element={<AdditionalModels />} />
        <Route path={AllRoutes.REVIEW} element={<Report />} />
      </Route>

      <Route Component={MainLayout}>
        <Route path={AllRoutes.CHARGE_POINT_APPROVAL_INDEX} element={<ChargePointApprovalIndex />}>
          <Route
            path=":vendor/:model/:firmwareId/detail"
            element={<ChargePointApprovalDetails />}
          />
        </Route>
        <Route path={AllRoutes.VENDOR_CONFIGURATIONS_INDEX} element={<VendorConfigurationsIndex />}>
          <Route path=":vendor/config" element={<VendorConfigurationDetails />} />
        </Route>
        <Route path={AllRoutes.MODEL_CONFIGURATIONS_INDEX} element={<ModelConfigurationsIndex />}>
          <Route
            path=":vendor/:model/:firmwareId/config"
            element={<ModelConfigurationsDetails />}
          />
        </Route>
      </Route>

      {/* 404 REDIRECT */}
      <Route path="*" element={<Navigate to={AllRoutes.START} replace />} />
    </Paths>
  );
};

export default Routes;
