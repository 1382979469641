import { OrientationType } from "@/components/LabeledContent/LabeledContent";
import styled, { css } from "styled-components";
import { ReactNode } from "react";

export const LabeledContentContainer = styled.section<{
  orientation: OrientationType;
  children: ReactNode;
}>`
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 12px;

  ${({ orientation }) =>
    orientation === "vertical"
      ? css`
          grid-template-areas:
            "icon text-label"
            ". content";

          .content {
            margin-top: 12px;
          }
        `
      : css`
          grid-template-areas: "icon text-label content";
          align-items: center;
          justify-content: start;
        `}

  .icon {
    grid-area: icon;
    align-self: center;
  }

  .label {
    display: flex;
    grid-area: text-label;
    align-self: center;
    align-items: center;
    gap: 5px;
  }

  .content {
    grid-area: content;
  }
`;
