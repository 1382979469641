import ConfigurationKeyList from "@/components/ConfigurationKeyList/ConfigurationKeyList";
import Frame from "@/components/Frame/Frame";
import Text from "@/components/Text/Text";
import { useIntegration } from "@/hooks/useIntegration";
import { useTranslation } from "@/utils/translations/Translator";
import { Flex, isEmpty } from "@emonvia/web-components";
import { FC } from "react";

const DefaultConfigurationKeysListFrame: FC = () => {
  const { t } = useTranslation();

  return (
    <Frame>
      <Flex flexDirection="column" gap={24}>
        <Text>{t("DEFAULT_CONFIGURATION_KEYS_AND_VALUES")}</Text>
        <DefaultConfigurationKeysList />
      </Flex>
    </Frame>
  );
};

export const DefaultConfigurationKeysList: FC<{ showDefaultValueLabel?: boolean }> = () => {
  const { data } = useIntegration();

  if (!data?.defaultConfig || isEmpty(data?.defaultConfig)) return null;

  const defaultConfigEntries = Object.entries(data.defaultConfig)
    .filter(([_, { isAdditionalDefaultConfig }]) => !isAdditionalDefaultConfig)
    .map(([_, { key, value }]) => ({
      key,
      value,
      isDefault: true
    }));

  return <ConfigurationKeyList list={defaultConfigEntries} showDefaultLabel />;
};

export default DefaultConfigurationKeysListFrame;
