import { SearchBox as ExternalSearchBox } from "@emonvia/web-components";
import styled from "styled-components";

const SearchBox = styled(ExternalSearchBox)`
  width: 277px;

  .start-element {
    margin-left: 5px;
  }

  input::placeholder {
    font-family: ${({ theme }) => theme.fonts.light};
    font-size: 14px;
  }

  input {
    text-indent: 10px;
    font-size: 14px;
  }
`;

export default SearchBox;
