import { Icon } from "@emonvia/web-components";
import styled from "styled-components";

export const ClickableIcon = styled(Icon)`
  cursor: pointer;
  transition: opacity 200ms ease-in;

  :hover {
    opacity: 0.6;
  }
`;
