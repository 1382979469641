import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { array, InferType, object } from "yup";
import { modelFormSchema } from "@/containers/NewIntegration/Steps/Model/hooks/useModelForm";

const additionalModelsFormSchema = object().shape({
  models: array().when((arr, schema) => {
    // probably most effiecient way to check if array is deeply empty
    if (JSON.stringify(arr) === "[[{}]]") {
      // just pass with empty array, step is optional so user can just proceed
      return schema;
    } else {
      return schema.of(modelFormSchema);
    }
  })
});

export type AdditionalModelFormValues = InferType<typeof additionalModelsFormSchema>;

export default function useAdditionalModelForm() {
  return useForm({
    resolver: yupResolver<AdditionalModelFormValues>(additionalModelsFormSchema),
    mode: "onChange",
    defaultValues: {
      models: [{}]
    }
  });
}
