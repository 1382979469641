import AllRoutes, { Path, globalNavigate } from "@/routing/AllRoutes";
import { TransKey } from "@/utils/translations/Translator";

const CreateIntegrationRoutesDetails: {
  [key in Path]: {
    stepTitle: TransKey;
  };
} = {
  [AllRoutes.VENDOR]: {
    stepTitle: "ADD_NEW_VENDOR_OR_MODEL_LABEL"
  },
  [AllRoutes.MODEL]: {
    stepTitle: "ADD_NEW_MODEL"
  },
  [AllRoutes.DEFAULT_CONFIG]: {
    stepTitle: "DEFAULT_CONFIGURATION_KEYS"
  },
  [AllRoutes.ADDITIONAL_DEFAULT_CONFIG]: {
    stepTitle: "ADD_ADDITIONAL_KEYS"
  },
  [AllRoutes.CUSTOM_ACCESS_CONTROL_CONFIG_MAP]: {
    stepTitle: "ADD_CUSTOM_KEYS"
  },
  [AllRoutes.ADDITIONAL_MODEL]: {
    stepTitle: "ADDITIONAL_MODELS"
  },
  [AllRoutes.REVIEW]: {
    stepTitle: "INTEGRATION_REPORT"
  }
} as const;

const createIntegrationRoutes = Object.keys(CreateIntegrationRoutesDetails);

const getPreviousPath = async (currentPath: string) => {
  const index = createIntegrationRoutes.findIndex((stepPath) => stepPath === currentPath);
  const previousRouteExists = index !== -1 && index !== 0;
  if (previousRouteExists) {
    return createIntegrationRoutes[index - 1];
  } else {
    return AllRoutes.START;
  }
};

const getNextIntegrationPath = async (currentPath: string) => {
  const index = createIntegrationRoutes.findIndex((stepPath) => stepPath === currentPath);
  const nextRouteExists = index !== -1;
  if (nextRouteExists) {
    return createIntegrationRoutes[index + 1];
  } else {
    return createIntegrationRoutes[0];
  }
};

export const goToPreviousStep = async () => {
  const currentPath = location.pathname;
  const nextPath = await getPreviousPath(currentPath);
  if (nextPath) {
    globalNavigate.current(nextPath);
  }
};

export const goToNextStep = async () => {
  const currentPath = location.pathname;
  const nextPath = await getNextIntegrationPath(currentPath);
  if (nextPath) {
    globalNavigate.current(nextPath);
  }
};

export const getCurrentIntegrationPageInfo = () => {
  const path = location.pathname;

  const pathDetails = CreateIntegrationRoutesDetails[path];
  const activeStepNumber = createIntegrationRoutes.findIndex((route) => route === path);

  return {
    stepTitle: pathDetails.stepTitle,
    activeStepNumber,
    totalSteps: createIntegrationRoutes.length - 1
  };
};
