import React, { FormEvent, useEffect } from "react";
import { Flex, useToast } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import SubHeader from "@/components/SubHeader/SubHeader";
import useModelForm from "@/containers/NewIntegration/Steps/Model/hooks/useModelForm";
import Frame from "@/components/Frame/Frame";
import Text from "@/components/Text/Text";
import { Trans } from "react-i18next";
import ModelForm from "@/containers/NewIntegration/Steps/Model/components/ModelForm";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useIntegration } from "@/hooks/useIntegration";
import { IModelIntegrationRequest_IModel } from "@/api/ApiTypes";

const Model: React.FC = () => {
  const { data, goNextWithUpdate } = useIntegration();
  const form = useModelForm();
  const { t } = useTranslation();
  const { notifyError } = useToast();

  useEffect(() => {
    form.reset(data?.models?.[0]);
  }, [data.models?.[0]]);

  const updateIntegrationAndGoToNextStep = async (payload: IModelIntegrationRequest_IModel) => {
    try {
      if (payload.electricMeterCalibrationTypeOfModel !== "SMV") {
        payload.smvProperty = null;
      }
      await ReevApiCalls.createModel({
        pathParams: { integrationId: data.integrationId },
        payload
      });
      goNextWithUpdate({
        models: [payload]
      });
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    form.handleSubmit(updateIntegrationAndGoToNextStep)();
  };

  return (
    <Flex flexDirection="column" gap={20}>
      <SubHeader />
      <form onSubmit={onSubmit}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" gap={20}>
          <Flex flexDirection="column" gap={12}>
            <Frame>
              <Text weight={300} color="grey750">
                <Trans
                  i18nKey="ENTER_CORRECT_INFO_FOR_MODEL"
                  components={{
                    u: <u />,
                    b: <strong />
                  }}
                />
              </Text>
            </Frame>
            <ModelForm form={form} />
          </Flex>
          <Button
            data-testid={"Model_Button"}
            label={t("CONFIRM")}
            useNunitoFont
            width="100%"
            type="submit"
            disabled={!form.formState.isValid || form.formState.isSubmitting}
          />
        </Flex>
      </form>
    </Flex>
  );
};

export default Model;
