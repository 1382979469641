import styled from "styled-components";

export const headerHeightPx = 90;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${headerHeightPx}px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.colors.primaryBlack};
  z-index: ${(props) => props.theme.zIndex.pageHeader};

  img {
    cursor: pointer;
  }
`;
