import { createGlobalStyle, keyframes } from "styled-components";
import theme from "@/utils/theme/theme";

const appear = keyframes`
  0% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
`;

const GlobalStyles = createGlobalStyle`

  * {
    box-sizing: border-box;
    font-display: optional;

    ::-webkit-scrollbar-track {
      box-shadow: none;
      background: transparent;
    }
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    padding: 0;
    margin: 0;
    font-size: 100%;
    vertical-align: baseline;
    border: 0;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  //ol, ul { list-style: none; }
  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    width: 100%;
    max-width: 100%;
    word-break: break-word;
    font-family: 'NunitoSans-Light', sans-serif;
    color: ${({ theme }) => theme.colors.primaryBlack};
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: ${theme.colors.frameBodyBackground};
  }

  #tooltips {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 10003;

    .withTooltip-tooltip {
      border: 1px solid #eff0f0;
      animation: ${appear} 0.2s ease-in-out 0s;
      position: absolute;
      background: #131923;
      padding: 10px 20px;
      border-radius: 8px;
      max-width: 200px;

      &.large {
        max-width: 450px;
      }

      font-size: 12px;
      color: #fbfbfb;
      line-height: 1.33em;
      text-align: center;

      &.bottom {
        &:before {
          content: "";
          width: 0;
          height: 0;
          border: 10px solid transparent;
          position: absolute;
          top: -16px;
          left: 50%;
          margin-left: -10px;
          border-bottom-color: #131923;
        }
      }

      &.top {
        &:after {
          content: "";
          width: 0;
          height: 0;
          border: 10px solid transparent;
          position: absolute;
          transform: rotate(180deg);
          bottom: -20px;
          left: 50%;
          margin-left: -10px;
          border-bottom-color: #131923;
        }
      }

      &.right {
        &:after {
          content: "";
          width: 0;
          height: 0;
          border: 10px solid transparent;
          position: absolute;
          transform: rotate(270deg);
          bottom: calc(50% - 20px);
          left: -10px;
          margin-left: -10px;
          border-bottom-color: #131923;
        }
      }
    }
  }

  button {
    color: black;
  }
`;

export default GlobalStyles;
