import Frame from "@/components/Frame/Frame";
import OrderedList from "@/components/OrderedList/OrderedList";
import Text from "@/components/Text/Text";
import { useTranslation } from "@/utils/translations/Translator";
import { Flex } from "@emonvia/web-components";
import { Trans } from "react-i18next";

const AdditionalModelsDescriptionFrame = () => {
  const { t } = useTranslation();

  return (
    <Frame>
      <OrderedList
        items={[
          <Text key={1} weight={300} color="grey750">
            <Trans
              i18nKey="ENTER_OR_SELECT_CORRECT_INFORMATION_FOR_ADDITIONAL_MODELS"
              components={{
                u: <u />,
                b: <strong />
              }}
            />
          </Text>,
          <Flex key={2} flexDirection="column">
            <Text weight={300} color="grey750">
              {t("CONFIRM_IF_CORRECT_OR_EDIT")}
            </Text>
          </Flex>
        ]}
      />
    </Frame>
  );
};

export default AdditionalModelsDescriptionFrame;
