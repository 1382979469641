import { FrameLook } from "@/components/Frame/Frame";
import { Frame } from "@emonvia/web-components";
import styled, { css } from "styled-components";

export const FrameContainer = styled(Frame)<{ frameLook: FrameLook }>`
  background-color: ${({ frameLook, theme }) =>
    frameLook === "primary" ? theme.colors.frameBodyBackground : theme.colors.primaryWhite};
  ${({ frameLook, theme }) =>
    frameLook === "secondary" &&
    css`
      border: 1px solid ${theme.colors.grey25};
    `};
  box-shadow: none;
  width: 100%;
  border-radius: 10px;
  padding: 0;
`;
