import Frame from "@/components/Frame/Frame";
import OrderedList from "@/components/OrderedList/OrderedList";
import Text from "@/components/Text/Text";
import { useTranslation } from "@/utils/translations/Translator";
import { Flex } from "@emonvia/web-components";
import { Trans } from "react-i18next";

const AddDefaultKeysDescriptionFrame = () => {
  const { t } = useTranslation();

  return (
    <Frame>
      <OrderedList
        items={[
          <Text key={1} weight={300} color="grey750">
            <Trans
              i18nKey="ENTER_CUSTOM_CONFIGURATION_KEY_AND_VALUE"
              components={{
                u: <u />,
                b: <strong />
              }}
            />
          </Text>,
          t("ADD_OTHER_KEYS_AND_VALUES_IF_NEEDED"),
          <Flex key={3} flexDirection="column">
            <Text weight={300} color="grey750">
              {t("CROSS_CHECK_CONFIGURATION_KEYS_AND_VALUES")}
            </Text>
            <Text weight={300} color="grey750">
              {t("CONFIRM_IF_CORRECT_OR_EDIT")}
            </Text>
          </Flex>
        ]}
      />
    </Frame>
  );
};

export default AddDefaultKeysDescriptionFrame;
