import { RequireExactlyOne } from "@/@types/utils";
import IconBadge from "@/components/IconBadge/IconBadge";
import { LabeledContentContainer } from "@/components/LabeledContent/styles";
import Text from "@/components/Text/Text";
import { IconTypes, TooltipInfoIcon } from "@emonvia/web-components";
import { FC, PropsWithChildren, ReactNode } from "react";

export type OrientationType = "vertical" | "horizontal";

export type IProps = RequireExactlyOne<
  {
    icon?: IconTypes;
    img?: ReactNode;
    label: string | ReactNode;
    htmlFor?: string;
    tooltip?: string;
    orientation?: OrientationType;
  },
  "icon" | "img"
>;

const LabeledContent: FC<PropsWithChildren<IProps>> = ({
  children,
  label,
  icon,
  htmlFor,
  img,
  tooltip,
  orientation = "vertical"
}) => {
  return (
    <LabeledContentContainer orientation={orientation}>
      {img && <IconBadge img={img} className="icon" />}
      {icon && <IconBadge icon={icon} className="icon" data-testid={`Icon_${icon}Icon`} />}
      <label className="label" htmlFor={htmlFor}>
        {typeof label === "string" ? (
          <Text weight={orientation === "vertical" ? 400 : 700}>{label}</Text>
        ) : (
          label
        )}
        {tooltip && <TooltipInfoIcon data-testid="tooltip-icon" tooltip={tooltip} position="top" />}
      </label>
      {children && <div className="content">{children}</div>}
    </LabeledContentContainer>
  );
};

export default LabeledContent;
