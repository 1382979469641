import styled from "styled-components";
import { ReactNode } from "react";

export const IconBadgeContainer = styled.div<{
  children: ReactNode;
  className: string;
  backgroundColor: string;
  size: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
`;
