import { MainLayoutContainer } from "@/components/layouts/MainLayout/styles";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <MainLayoutContainer>
      <Outlet />
    </MainLayoutContainer>
  );
};

export default MainLayout;
