import {
  ConfigKeyType,
  EditConfigKeysFormValues
} from "@/components/EditConfigModal/hooks/useEditConfigForm";
import useEditableFields from "@/components/EditConfigModal/hooks/useEditableFields";
import FieldGroupArray from "@/components/FieldGroupArray/FieldGroupArray";
import { useTranslation } from "@/utils/translations/Translator";
import { Flex, IconButton, IconTypes } from "@emonvia/web-components";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

type IProps = {
  form: UseFormReturn<EditConfigKeysFormValues>;
  onAddField: () => void;
};

const EditConfigForm: FC<IProps> = ({ form, onAddField }) => {
  const { t } = useTranslation();
  const { has, toggle, add } = useEditableFields();

  const handleAddField = (index: number) => {
    add(index);
    onAddField();
  };

  return (
    <FieldGroupArray
      form={form}
      group={{
        name: "config",
        orientation: "horizontal",
        hideDivider: true,
        disabled: (index) => !has(index),
        fields: [
          {
            name: "key",
            width: "50%",
            placeholder: t("ENTER_CONFIGURATION_KEY")
          },
          { name: "value", width: "20%", placeholder: t("ENTER_VALUE") },
          {
            name: "type",
            width: "30%",
            type: "select",
            selectOptions: Object.values(ConfigKeyType).map((type) => ({
              label: type,
              value: type
            })),
            placeholder: t("SELECT_USE_CASE")
          }
        ],
        postfix: (index, remove) => (
          <Flex gap={10} alignItems="center" justifyContent="flex-end">
            <IconButton
              icon={has(index) ? IconTypes.check : IconTypes.pen}
              look="secondary"
              onClick={() => toggle(index)}
            />
            <IconButton icon={IconTypes.trash} look="secondary" onClick={() => remove(index)} />
          </Flex>
        ),
        onAddField: handleAddField
      }}
    />
  );
};

export default EditConfigForm;
