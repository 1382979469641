import { SearchableDropdown } from "@emonvia/web-components";
import styled, { css } from "styled-components";

export const StyledSearchableDropdown = styled(SearchableDropdown)<{ error?: boolean }>`
  height: unset;
  width: auto;
  max-width: 70vw;

  ${({ error }) =>
    error &&
    css`
      .drop-down-control {
        border-color: ${({ theme }) => theme.colors.error800};
      }
    `}

  /* order is important here */
  .drop-down-value div {
    color: ${({ theme }) => theme.colors.primaryBlack};
  }

  .drop-down-placeholder div {
    cursor: pointer;
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.light};
    color: ${({ theme }) => theme.colors.grey550};
  }
  /* order is important here */
`;
