import { theme as componentsTheme } from "@emonvia/web-components";

const theme = {
  colors: {
    ...componentsTheme.colors,
    blue500: "#0093FF",
    blue900: "#1E2531",
    error500: "#FF3B30",
    error800: "#C7442A",
    error900: "#E31D1C",
    grey100: "#E7E9ED",
    grey150: "#E3E4E5",
    grey175: "#E1E2E4",
    grey25: "#F2F2F2",
    grey250: "#DDDDDD",
    grey275: "#D7D4CC",
    grey350: "#CACDD1",
    grey375: "#CBCDD1",
    grey400: "#B3B3B3",
    grey50: "#F5F6F9",
    grey500: "#A0A9B9",
    grey550: "#858B92",
    grey600: "#818181",
    grey650: "#576275",
    grey700: "#545960",
    grey75: "#EFEFEF",
    grey750: "#4B515D",
    grey800: "#494D53",
    grey850: "#363636",
    grey900: "#333333",
    main500: "#FFCA39",
    main600: "#CCA12D",
    main700: "#B28D27",
    primaryBlack: "#131923",
    primaryWhite: "#FFFFFF",
    statusFree500: "#99CF76",
    statusOffline500: "#53585F",
    success100: "#F2FAF4",
    success200: "#B7E1C2",
    success500: "#5BBA6F",
    success800: "#2EA84C",
    warning100: "#FFF5EA",
    warning500: "#FF8600",
    frameBodyBackground: "#F9FAFB",
    logout: "#FF0A00"
  },
  colorsWithOpacity: {
    successBackground: "rgba(46, 168, 76, 0.06)",
    successBorder: "rgba(46, 168, 76, 0.3)"
  },
  shadows: {
    mainShadow: "0px 2px 15px -2.5px rgba(0, 0, 0, 0.2)",
    frameShadow: "0 2px 15px 0 rgba(19, 25, 35, 0.13)"
  },
  states: {
    hover: {
      filter: "brightness(0.8)"
    },
    active: {
      filter: "brightness(0.7)"
    }
  },
  borders: {
    yellowUnderline: "2px solid #ffca39",
    blueUnderline: "2px solid #0093FF",
    orangeFrame: "solid 1px #ff8600",
    frameHeader: "1px solid #e7e9ed",
    dropdown: "1px solid #a0a9b9",
    dropdownItem: "1px solid #e7e9ed"
  },
  dimensions: {
    sideBarWidth: 230
  },
  zIndex: {
    toast: 5000,
    spinner: 4000,
    tooltip: 3000,
    dropdown: 2000,
    modal: 1000,
    sidebar: 500,
    pageHeader: 100,
    background: 10,
    grayedBackground: 999
  },
  fonts: {
    light: "NunitoSans-Light",
    medium: "NunitoSans-Regular",
    bold: "NunitoSans-Bold"
  }
} as const;

export type Theme = typeof theme;
export default theme;
