import Frame from "@/components/Frame/Frame";
import VendorConfigurationsTable from "@/containers/VendorConfigurations/components/VendorConfigurationsTable";
import { Outlet } from "react-router-dom";

const VendorConfigurationsIndex = () => {
  return (
    <Frame look="secondary">
      <VendorConfigurationsTable />
      <Outlet />
    </Frame>
  );
};

export default VendorConfigurationsIndex;
