import Frame from "@/components/Frame/Frame";
import { Outlet } from "react-router-dom";
import ChargePointApprovalTable from "@/containers/ChargePointApproval/components/ChargePointApprovalTable";

const ChargePointApprovalIndex = () => {
  return (
    <Frame look="secondary">
      <ChargePointApprovalTable />
      <Outlet />
    </Frame>
  );
};

export default ChargePointApprovalIndex;
