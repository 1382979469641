import React from "react";
import { Flex, Icon, IconTypes, StyledText, Types } from "@emonvia/web-components";
import { AllRoutes } from "@/routing/AllRoutes";
import { IBackButton } from "@/components/SubHeader/components/types";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import useLocalStorage from "@/hooks/useLocalStorage";
import { INTEGRATION_ID } from "@/utils/constants";
import { goToPreviousStep } from "@/routing/NewIntegrationRoutes";

const BackButtonWithTitle: React.FC<IBackButton> = ({ stepTitle }) => {
  const { t } = useTranslation();
  const { removeItem } = useLocalStorage();

  const goBackWithUpdate = async () => {
    const currentPath = location.pathname;
    if (currentPath === AllRoutes.VENDOR) {
      removeItem(INTEGRATION_ID);
    }
    await goToPreviousStep();
  };
  return (
    <Flex flexDirection="row" onClick={goBackWithUpdate} gap={12}>
      <Icon size={24} type={IconTypes.arrowLeft} style={{ cursor: "pointer" }} />
      <StyledText
        text={t(stepTitle as TransKey)}
        type={Types.ns_18_sb}
        style={{ cursor: "pointer" }}
      />
    </Flex>
  );
};

export default BackButtonWithTitle;
