import { Flex } from "@emonvia/web-components";
import styled from "styled-components";

export const ReportUnorderedList = styled.ul`
  padding-left: 15px;

  li:not(:last-of-type) {
    margin-bottom: 3px;
  }
`;

export const StyledLabelWithSublabel = styled(Flex)`
  font-size: 14px;

  span:first-of-type {
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`;
