import { useQuery } from "react-query";
import VendorQueryKeys from "@/containers/VendorConfigurations/utils/QueryKeys";
import ReevApiCalls from "@/api/ReevApiCalls";
import { ApiErrorResponse } from "@/utils/enums/ApiErrorResponse";
import { translateError } from "@/utils/translations/Translator";
import { useToast } from "@emonvia/web-components";
import { IVendorPaginatedQueryRequest } from "@/api/ApiTypes";

export const useVendorsQuery = ({
  page,
  pageSize,
  search,
  sortBy,
  sortOrder
}: Partial<IVendorPaginatedQueryRequest>) => {
  const { notifyError } = useToast();

  const { data, isLoading, refetch } = useQuery(
    [VendorQueryKeys.LIST, page, search, sortBy, sortOrder],
    () =>
      ReevApiCalls.getAllVendors({
        queryParams: { request: { page, pageSize, search, sortBy, sortOrder } }
      }),
    {
      onError: (e: ApiErrorResponse) => notifyError(translateError(e))
    }
  );
  return {
    data,
    isLoading,
    refetch
  };
};
