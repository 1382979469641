import { useTranslation } from "@/utils/translations/Translator";
import { Types, useColumns } from "@emonvia/web-components";
import { IVendorDetails } from "@/api/ApiTypes";

export default function useModelaConfigurationsTableColumns() {
  const { t } = useTranslation();

  return useColumns<IVendorDetails>([
    {
      id: "name",
      Header: t("VENDOR"),
      accessor: "vendor",
      labelType: Types.ns_18_sb
    },
    {
      id: "ocppNames",
      Header: t("OCPP_NAME"),
      accessor: (row: IVendorDetails) => row?.ocppNames?.join(", "),
      labelType: Types.ns_18_sb
    }
  ]);
}
