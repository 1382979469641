import * as React from "react";
import CSS from "csstype";
import {
  StyledText,
  Types,
  Flex,
  TooltipInfoIcon,
  IconButton,
  IconTypes
} from "@emonvia/web-components";
import { StyledPanel } from "@/components/Panel/styles";

interface IProps {
  title?: string | React.ReactNode;
  tooltipText?: string;
  showAction?: boolean;
  onEditClick?: () => void;
  style?: CSS.Properties;
  padding?: Array<string | number>;
  margin?: Array<string | number>;
  children?: React.ReactNode;
}
const Panel: React.FC<IProps> = ({
  title,
  tooltipText,
  style,
  padding,
  margin,
  children,
  onEditClick,
  showAction
}) => {
  return (
    <StyledPanel margin={margin} padding={padding}>
      <Flex padding={[0, 0, 8]}>
        <Flex gap={16}>
          <Flex padding={[0, 0, 8]}>
            <StyledText text={title} type={Types.ns_24_b} />
          </Flex>
          {tooltipText && (
            <TooltipInfoIcon
              position="bottom"
              tooltip={tooltipText}
              size={40}
              margin={[-4, 0, 0]}
            />
          )}
          {showAction && (
            <IconButton icon={IconTypes.pen} onClick={onEditClick} shape="round" look="secondary" />
          )}
        </Flex>
      </Flex>
      <Flex className="body" style={style}>
        {children}
      </Flex>
    </StyledPanel>
  );
};

export default Panel;
