import EditConfigForm from "@/components/EditConfigModal/EditConfigForm";
import { StyledCancelButton } from "@/components/EditConfigModal/styles";
import useEditConfigForm, {
  EditConfigKeysFormValues
} from "@/components/EditConfigModal/hooks/useEditConfigForm";
import Text from "@/components/Text/Text";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import { Button, Flex, Modal } from "@emonvia/web-components";
import { FC, ReactNode, useRef } from "react";
import { Trans } from "react-i18next";
import UnorderedList from "@/components/UnorderedList/UnorderedList";

type IProps = {
  config: EditConfigKeysFormValues;
  onSubmit: (config: EditConfigKeysFormValues) => void;
  onClose: () => void;
  title: TransKey;
  description?: ReactNode;
  firmwareVersions?: { firmwareVersion?: string }[];
};

const EditConfigModal: FC<IProps> = ({
  config,
  onSubmit,
  onClose,
  title,
  description,
  firmwareVersions = []
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const form = useEditConfigForm(config);

  const descriptionPoints: TransKey[] = [
    "UPDATE_CONFIG_KEY_INSTRUCTION",
    "DELETE_CONFIG_KEY_INSTRUCTION",
    "ADD_ANOTHER_CONFIG_KEY_INSTRUCTION",
    "SAVE_CONFIG_KEY_INSTRUCTION"
  ];

  const handleAddField = () => {
    const container = containerRef.current.parentElement;
    // make sure scroll happens after items gets appended
    setTimeout(() => {
      container.style.scrollBehavior = "smooth";
      container.scrollTo(0, container.scrollHeight);
    }, 0);
  };

  const hasNonEmptyFirmwareVersion = () => {
    return firmwareVersions?.some(({ firmwareVersion }) => firmwareVersion?.trim() !== "");
  };

  return (
    <Modal
      header={
        <Text size={24} weight={700} color="grey750">
          <Trans
            i18nKey={title}
            components={{
              u: <u />,
              b: <strong />
            }}
          />
        </Text>
      }
      headerMarginBottom="32px"
      actions={
        <Flex gap={20}>
          <StyledCancelButton label={t("CANCEL")} onClick={onClose} useNunitoFont />
          <Button
            label={t("SAVE")}
            width="295px"
            onClick={() => form.handleSubmit(onSubmit)()}
            disabled={!form.formState.isDirty && !hasNonEmptyFirmwareVersion()}
            useNunitoFont
          />
        </Flex>
      }
      onClose={onClose}
    >
      <Flex
        ref={containerRef}
        flexDirection="column"
        width="100%"
        height="100%"
        padding={[0, 120]}
        gap={20}
      >
        {description}
        <UnorderedList items={descriptionPoints} />
        <EditConfigForm form={form} onAddField={handleAddField} />
      </Flex>
    </Modal>
  );
};

export default EditConfigModal;
