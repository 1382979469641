import styled from "styled-components";
import { Flex } from "@emonvia/web-components";
import theme from "@/utils/theme/theme";

export const CardContainer = styled(Flex)<{ disabled?: boolean }>`
  width: 266px;
  height: 220px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 15px rgba(19, 25, 35, 0.13);
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  user-select: none;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? "" : theme.colors.grey25)};
  }
  ${({ disabled }) =>
    !disabled &&
    `
      &:active {
        transform: scale(0.95);
      }
    `}
`;

export const CardImage = styled.img<{ src: string; alt: string }>`
  width: 121px;
  height: 121px;
  margin-bottom: 10px;
`;
