import ConfigurationKeyList from "@/components/ConfigurationKeyList/ConfigurationKeyList";
import { useIntegration } from "@/hooks/useIntegration";
import { useTranslation } from "@/utils/translations/Translator";
import { Divider, isEmpty } from "@emonvia/web-components";

const ProvidedConfigurationKeysList = () => {
  const { t } = useTranslation();
  const { data } = useIntegration();

  if (!data) return null;

  const configurationKeysList = data.defaultConfig
    ?.filter(({ isAdditionalDefaultConfig }) => isAdditionalDefaultConfig)
    .concat(
      data.customAccessControlConfig?.map(({ configKey, value, useCase }) => ({
        key: configKey,
        value,
        isAdditionalDefaultConfig: true,
        sublabel: useCase === "OPEN" ? t("OFF") : t("ON")
      }))
    );

  if (isEmpty(configurationKeysList)) {
    return null;
  }

  return (
    <>
      <Divider spacing={16} />
      <ConfigurationKeyList list={configurationKeysList} />
    </>
  );
};

export default ProvidedConfigurationKeysList;
