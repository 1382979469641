import styled from "styled-components";

export const StyledUnorderedList = styled.ul`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey750};
  strong {
    font-family: ${({ theme }) => theme.fonts.medium};
  }
  padding-left: 18px;
`;
