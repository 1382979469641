import EditConfigModal from "@/components/EditConfigModal/EditConfigModal";
import { EditConfigKeysFormValues } from "@/components/EditConfigModal/hooks/useEditConfigForm";
import { FC } from "react";
import { IConfigTableData, toConfigFormType } from "@/containers/VendorConfigurations/utils/mapper";

type IProps = {
  onClose: () => void;
  onSubmit: (config: EditConfigKeysFormValues) => void;
  config: IConfigTableData[];
};

const EditModelFallbackConfigurationModal: FC<IProps> = ({ config, onClose, onSubmit }) => {
  return (
    <EditConfigModal
      config={toConfigFormType(config)}
      onSubmit={onSubmit}
      onClose={onClose}
      title="EDIT_MODE_FALLBACK_CONFIGURATION"
    />
  );
};

export default EditModelFallbackConfigurationModal;
