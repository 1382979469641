import { createContext, FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useToast } from "@emonvia/web-components";
import { translateError } from "@/utils/translations/Translator";
import { INTEGRATION_ID } from "@/utils/constants";
import AllRoutes from "@/routing/AllRoutes";
import useLocalStorage from "@/hooks/useLocalStorage";
import { IChargePointIntegrationResponse } from "@/api/ApiTypes";
import { useNavigate } from "react-router-dom";
import { goToNextStep } from "@/routing/NewIntegrationRoutes";

export interface IIntegrationReturn {
  start: () => Promise<IChargePointIntegrationResponse>;
  finish: () => void;
  get?: () => Promise<IChargePointIntegrationResponse>;
  goNextWithUpdate: (payload: Partial<IChargePointIntegrationResponse>) => void;
  data: Partial<IChargePointIntegrationResponse>;
  isGetLoading?: boolean;
  isStartLoading: boolean;
  isLoading: boolean;
}

const IntegrationContext = createContext<IIntegrationReturn>({
  data: null,
  isGetLoading: false,
  isStartLoading: false,
  isLoading: false,
  goNextWithUpdate: () => undefined,
  get(): Promise<IChargePointIntegrationResponse> {
    return Promise.resolve(null);
  },
  start: () => undefined,
  finish: () => undefined
});

//TODO: will uncomment when apis are ready

export const IntegrationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { notifyError } = useToast();
  const { setItem, getItem, removeItem } = useLocalStorage();
  const navigate = useNavigate();

  // const { identify } = usePostHog();

  const [integrationData, setIntegrationData] = useState<Partial<IChargePointIntegrationResponse>>({
    integrationId: getItem(INTEGRATION_ID)
  });

  const [isGetLoading, setIsGetLoading] = useState<boolean>(false);
  const [isStartLoading, setIsStartLoading] = useState<boolean>(false);

  const getIntegrationHandler = async () => {
    try {
      const id = getItem(INTEGRATION_ID);
      if (!id) {
        navigate(AllRoutes.START);
        return;
      }

      setIsGetLoading(true);

      const data = await ReevApiCalls.getIntegration({
        pathParams: { integrationId: id }
      });

      setIntegrationData(data);
      // identify();

      return data;
    } catch (e) {
      if (e.status !== 400) {
        notifyError(translateError(e));
      } else {
        removeItem(INTEGRATION_ID);
      }
      throw e;
    } finally {
      setIsGetLoading(false);
    }
  };

  const startIntegrationHandler = async () => {
    try {
      setIsStartLoading(true);
      const data = await ReevApiCalls.createNewIntegration();

      setIntegrationData(data);

      setItem(INTEGRATION_ID, data.integrationId);

      return data;
    } catch (e) {
      notifyError(translateError(e));
      throw e;
    } finally {
      setIsStartLoading(false);
    }
  };

  const finishIntegrationHandler = () => {
    setIntegrationData({});
    removeItem(INTEGRATION_ID);
    navigate(AllRoutes.START);
  };

  const goNextWithUpdateHandler = async (payload: Partial<IChargePointIntegrationResponse>) => {
    // const currentPath = location.pathname;
    // if (NEXT_STEP_TO_Integration_STEP[currentPath as Path])
    //   await updateIntegrationHandler({ state: NEXT_STEP_TO_Integration_STEP[currentPath as Path] });
    setIntegrationData((data) => ({ ...data, ...payload }));
    await goToNextStep();
  };

  useEffect(() => {
    const integrationId = getItem(INTEGRATION_ID);
    if (!integrationId) return;
    getIntegrationHandler().then(({ integrationId, state }) => {
      if (integrationId && !state) return navigate(AllRoutes.START);

      navigate(AllRoutes[state]);
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      start: startIntegrationHandler,
      finish: finishIntegrationHandler,
      isLoading: isStartLoading || isGetLoading,
      isStartLoading,
      isGetLoading,
      data: integrationData,
      goNextWithUpdate: goNextWithUpdateHandler
    }),
    [startIntegrationHandler, isStartLoading, integrationData, goNextWithUpdateHandler]
  );

  return (
    <IntegrationContext.Provider value={memoizedValue}>{children}</IntegrationContext.Provider>
  );
};

export default IntegrationContext;
