import { FC, PropsWithChildren } from "react";
import { Types } from "@emonvia/web-components";
import { useTheme } from "styled-components";
import { TextContainer } from "@/components/Text/styles";

type IProps = {
  size?: 14 | 16 | 18 | 20 | 24;
  weight?: 300 | 400 | 700;
  color?: "primary" | "grey750" | "error800";
  textAlign?: "left" | "center" | "right";
  clickable?: boolean;
  maxWidth?: string | number;
  className?: string;
  margin?: Array<number | string>;
};

const weightLabelMap: Record<IProps["weight"], string> = {
  "300": "l",
  "400": "m",
  "700": "b"
};

const Text: FC<PropsWithChildren<IProps>> = ({
  size = 16,
  weight = 400,
  color = "primary",
  clickable,
  textAlign,
  maxWidth,
  className,
  margin,
  children
}) => {
  const theme = useTheme();
  const type = `ns_${size}_${weightLabelMap[weight]}` as keyof typeof Types;

  return (
    <TextContainer
      text={children}
      type={Types[type]}
      color={theme.colors[color]}
      clickable={clickable}
      textAlign={textAlign}
      style={{ maxWidth }}
      className={className}
      margin={margin}
    />
  );
};

export default Text;
