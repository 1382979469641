import { useTranslation } from "@/utils/translations/Translator";
import { Flex, isEmpty, isNotEmpty, Types, useTableQueryParams } from "@emonvia/web-components";
import { FunctionComponent } from "react";
import useChargePointApprovalColumns from "@/containers/ChargePointApproval/hooks/useChargePointApprovalColumns";
import Table, { NoMatchesMessage } from "@/components/Table/Table";
import SearchBox from "@/components/SearchBox/SearchBox";
import { useNavigate } from "react-router-dom";
import { IModelDetails, IPaginatedModelQueryRequest } from "@/api/ApiTypes";
import { Status } from "@/utils/enums/Status";
import { useFetchModelsQuery } from "@/hooks/useFetchModelsQuery";
import { REPLACE_FIRST_VALUE } from "@/utils/regexp";
import ModelQueryKeys from "@/containers/ModelConfigurations/utils/QueryKeys";
import BackButtonWithTitle from "@/components/SubHeader/components/BackButtonWithTitle";

export type IFilters = {
  status?: string;
  searchQuery?: string;
  vendor?: string;
  model?: string;
};

const ChargePointApprovalTable: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns = useChargePointApprovalColumns();

  const { filters, page, pageSize, sortOrder, sortBy, setFilters, setQueryParams } =
    useTableQueryParams<IFilters, IPaginatedModelQueryRequest["sortBy"]>({
      mergeParamsFromSearchQueryIntoFilters: true,
      navigate
    });

  const { data, isLoading } = useFetchModelsQuery({
    queryKey: ModelQueryKeys.APPROVAL_LIST,
    page,
    pageSize,
    sortBy,
    sortOrder,
    status: Status.PENDING,
    filters
  });

  const { data: approvals, ...meta } = data || {};

  const list = approvals?.map((model: IModelDetails) => ({
    ...model,
    id: `${model.id}/${model.vendor}/${model.model}/${model.id}`
  }));

  const goToApprovalDetails = (id: string) => {
    const newId = id.replace(REPLACE_FIRST_VALUE, "");
    navigate(`${newId}/detail`);
  };

  const showNoMatchesMessage = isEmpty(list) && isNotEmpty(filters);

  return (
    <>
      <Flex flexDirection="column" margin={[0, 0, 20, 0]}>
        <BackButtonWithTitle stepTitle={t("CHARGE_POINT_APPROVAL")} />
      </Flex>
      <Flex flexDirection="column" gap={40}>
        <SearchBox
          allowClear
          onChange={(searchQuery) => setFilters({ ...filters, searchQuery })}
          label={t("SEARCH")}
        />
        <Table
          data={list}
          isLoading={isLoading}
          columns={columns}
          setQueryParams={setQueryParams}
          meta={meta}
          inTotalTranslation={t("IN_TOTAL")}
          allowKeyboardNavigation
          onRowClick={goToApprovalDetails}
          rowsSelectable={false}
          paginationTextType={Types.ns_16_l}
        />
        {showNoMatchesMessage && <NoMatchesMessage />}
      </Flex>
    </>
  );
};

export default ChargePointApprovalTable;
