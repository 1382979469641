import { requiredStringSchema } from "@/utils/validation/schemas";
import { isNotEmpty } from "@emonvia/web-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InferType, array, object, string } from "yup";

export enum ConfigKeyType {
  DEFAULT = "default",
  OPEN = "open",
  RESTRICTED = "restricted"
}

const configKeySchema = object().shape(
  {
    key: string()
      .when(["value", "type"], {
        is: (value: string, type: string) => isNotEmpty(value) || isNotEmpty(type),
        then: requiredStringSchema,
        otherwise: (schema) => schema.optional()
      })
      .default(""),

    value: string()
      .when("key", {
        is: (key: string) => isNotEmpty(key),
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.optional()
      })
      .default(""),

    type: string().oneOf(Object.values(ConfigKeyType))
  },
  [
    ["key", "value"],
    ["value", "key"]
  ]
);

const configKeysSchema = object().shape({
  config: array().of(configKeySchema).nullable().notRequired()
});

export type EditConfigKeysFormValues = InferType<typeof configKeysSchema>;

export default function useEditConfigForm(defaultValues: EditConfigKeysFormValues) {
  return useForm({
    resolver: yupResolver<EditConfigKeysFormValues>(configKeysSchema),
    mode: "onChange",
    defaultValues
  });
}
