import React from "react";
import { Flex, IconTypes, Types } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { useTranslation } from "@/utils/translations/Translator";
import { TruncatedTitle } from "@/components/Sidebar/styles";

type IProps = {
  label?: string;
  onApprove?: () => void;
  onDiscard?: () => void;
  showActions?: boolean;
  disableAction?: boolean;
};

const SidebarHeader: React.FC<IProps> = ({
  label,
  onDiscard,
  onApprove,
  showActions = false,
  disableAction
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="row" gap={50}>
      <Flex flexDirection="row" alignItems="center" margin={[8, 0, 0]}>
        <TruncatedTitle text={label} type={Types.ns_28_b} />
      </Flex>
      {showActions && (
        <Flex flexDirection="row" gap={24} margin={[8, 0, 0]}>
          <Button
            data-testid={"Approve_Button"}
            label={t("APPROVE")}
            useNunitoFont
            size="small"
            icon={IconTypes.checkCircle}
            disabled={disableAction}
            onClick={onApprove}
          />
          <Button
            data-testid={"Discard_Button"}
            label={t("DISCARD")}
            useNunitoFont
            size="small"
            icon={IconTypes.trash}
            disabled={disableAction}
            onClick={onDiscard}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default SidebarHeader;
