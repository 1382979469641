import React from "react";
import { Flex, useToast } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import SubHeader from "@/components/SubHeader/SubHeader";
import Frame from "@/components/Frame/Frame";
import Text from "@/components/Text/Text";
import { Trans } from "react-i18next";
import DefaultConfigurationKeysListFrame from "@/containers/NewIntegration/Steps/DefaultKeys/components/DefaultConfigurationKeysListFrame";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useIntegration } from "@/hooks/useIntegration";
import { goToNextStep } from "@/routing/NewIntegrationRoutes";

const DefaultKeys: React.FC = () => {
  const { data } = useIntegration();
  const { notifyError } = useToast();
  const { t } = useTranslation();

  const goToNextStepWithStateUpdate = async () => {
    try {
      await ReevApiCalls.updateState({
        pathParams: { integrationId: data.integrationId, state: "DEFAULT_CONFIG" }
      });
      goToNextStep();
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  return (
    <Flex flexDirection="column" gap={20}>
      <SubHeader />
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex flexDirection="column" gap={12}>
          <Frame>
            <Text weight={300} color="grey750">
              <Trans
                i18nKey="DEFAULT_CONFIGURATION_KEYS_KEYS_LIST_DESC"
                components={{
                  u: <u />,
                  b: <strong />
                }}
              />
            </Text>
            <Text weight={300} color="grey750">
              {t("ADD_MISSING_DEFAULT_CONFIGURATION_KEYS")}
            </Text>
          </Frame>
          <DefaultConfigurationKeysListFrame />
        </Flex>
        <Button
          data-testid={"DefaultKeys_Button"}
          label={t("NEXT")}
          useNunitoFont
          width="100%"
          margin={[20, 0, 0, 0]}
          onClick={goToNextStepWithStateUpdate}
        />
      </Flex>
    </Flex>
  );
};

export default DefaultKeys;
