import React from "react";
import ReevLogo from "@/assets/images/reev-logo.svg";
import { StyledHeader } from "@/components/Header/styles";
import { useNavigate } from "react-router-dom";
import AllRoutes from "@/routing/AllRoutes";
import useLocalStorage from "@/hooks/useLocalStorage";
import { INTEGRATION_ID } from "@/utils/constants";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { removeItem } = useLocalStorage();

  const goToLandingPage = () => {
    navigate(AllRoutes.START);
    removeItem(INTEGRATION_ID);
  };

  return (
    <StyledHeader>
      <img
        id="reev-logo"
        alt="reev"
        src={ReevLogo}
        width={112}
        height={42}
        onClick={goToLandingPage}
      />
    </StyledHeader>
  );
};

export default Header;
