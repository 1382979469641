import { OptionsType } from "@/@types/utils";
import Dropdown from "@/components/Dropdown/Dropdown";
import { ClickableIcon } from "@/components/FieldArray/styles";
import { StyledFieldGroupArray } from "@/components/FieldGroupArray/styles";
import IconBadge from "@/components/IconBadge/IconBadge";
import Text from "@/components/Text/Text";
import ValidatedTextInput from "@/components/ValidatedTextInput/ValidatedTextInput";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import { Divider, Flex, IconTypes, LinkButton, Types } from "@emonvia/web-components";
import { FC, Fragment, ReactNode, useRef } from "react";
import {
  Controller,
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormReturn,
  useFieldArray
} from "react-hook-form";

type InputType = "text" | "select";

export type OrientationType = "vertical" | "horizontal";

type IFieldProps = {
  name: string;
  icon?: IconTypes | JSX.Element;
  label?: string;
  placeholder?: string | ((index: number) => string);
  hidden?: boolean | ((index: number) => boolean);
  type?: InputType | ((index: number) => InputType);
  selectOptions?: OptionsType<string>;
  orientation?: OrientationType;
  width?: string;
  hideErrorMessage?: boolean;
};

type IProps = {
  form: UseFormReturn<FieldValues>;
  group: {
    name: string;
    icon?: IconTypes;
    label?: (index: number) => string;
    disabled?: (index: number) => boolean;
    footer?: ReactNode | ((index: number) => ReactNode);
    orientation?: OrientationType;
    hideDivider?: boolean;
    hideAppendButton?: boolean;
    postfix?: (
      index: number,
      remove: UseFieldArrayRemove,
      append: UseFieldArrayAppend<FieldValues>,
      isLast: boolean
    ) => ReactNode;
    fields: IFieldProps[];
    onAddField?: (index: number) => void;
  };
};

const FieldGroupArray: FC<IProps> = ({ form: { control, watch }, group }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: group.name
  });

  const handleAddField = () => {
    append({});
    group.onAddField?.(fields.length);
  };

  return (
    <StyledFieldGroupArray
      ref={containerRef}
      flexDirection="column"
      gap={20}
      orientation={group.orientation}
    >
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <div className="group">
            {group.icon && <IconBadge icon={group.icon} className="group-icon" />}
            {group.label && (
              <Flex gap={12} alignItems="center" className="group-label">
                <Text>{group.label(index + 1)}</Text>
                {index > 0 && (
                  <ClickableIcon
                    type={IconTypes.trash}
                    color="primaryBlack"
                    onClick={() => remove(index)}
                  />
                )}
              </Flex>
            )}
            <div className="group-fields">
              {group.fields.map((groupField) => {
                const shouldHide =
                  groupField.hidden &&
                  (typeof groupField.hidden === "function"
                    ? groupField.hidden(index)
                    : groupField.hidden === true);

                if (shouldHide) {
                  return null;
                }
                return (
                  <div
                    className={`group-field${
                      groupField.orientation === "horizontal" ? " horizontal" : " vertical"
                    }`}
                    style={{ width: groupField.width }}
                    key={`${groupField.name}-${field.id}`}
                  >
                    {!!groupField.icon && (
                      <>
                        {typeof groupField.icon === "string" ? (
                          <IconBadge icon={groupField.icon} className="field-icon" />
                        ) : (
                          <div className="field-icon">{groupField.icon}</div>
                        )}
                      </>
                    )}
                    {groupField.label && <Text className="field-label">{groupField.label}</Text>}
                    <div className="field">
                      <Controller
                        name={`${group.name}[${index}][${groupField.name}]`}
                        control={control}
                        render={({ fieldState: { error }, field: fieldProps }) => {
                          const inputType =
                            typeof groupField.type === "function"
                              ? groupField.type(index)
                              : groupField.type;

                          if (inputType === "select") {
                            return (
                              <Dropdown
                                {...fieldProps}
                                value={watch(fieldProps.name)}
                                placeholder={
                                  typeof groupField.placeholder === "function"
                                    ? groupField.placeholder(index)
                                    : groupField.placeholder
                                }
                                options={groupField.selectOptions}
                                errorMessage={
                                  !groupField.hideErrorMessage
                                    ? (error?.message as TransKey)
                                    : undefined
                                }
                                disabled={group.disabled?.(index)}
                              />
                            );
                          } else {
                            return (
                              <ValidatedTextInput
                                {...fieldProps}
                                value={watch(fieldProps.name)}
                                removeSpaces={false}
                                placeholder={
                                  typeof groupField.placeholder === "function"
                                    ? groupField.placeholder(index)
                                    : groupField.placeholder
                                }
                                errorMessage={
                                  !groupField.hideErrorMessage
                                    ? (error?.message as TransKey)
                                    : undefined
                                }
                                disabled={group.disabled?.(index)}
                              />
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              {group.postfix?.(index, remove, append, index === fields.length - 1)}
            </div>
          </div>
          {typeof group.footer === "function" ? group.footer(index) : group.footer}
          {index < fields.length - 1 && !group.hideDivider && <Divider />}
        </Fragment>
      ))}
      {!group.hideAppendButton && (
        <LinkButton
          onClick={handleAddField}
          label={`+ ${t("ADD_ANOTHER")}`}
          look="primary"
          fontType={Types.ns_14_m}
          color="primaryBlack"
          width="fit-content"
        />
      )}
    </StyledFieldGroupArray>
  );
};

export default FieldGroupArray;
