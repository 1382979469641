import React, { ReactNode } from "react";
import { Flex, StyledText, Types } from "@emonvia/web-components";

export interface IProps {
  text: string | ReactNode;
  light?: boolean;
}

const PanelText: React.FC<IProps> = ({ text, light }) => {
  return (
    <Flex flexDirection="row" alignItems="center" style={{ gap: 10 }} width={"44%"}>
      <StyledText text={text} type={light ? Types.ns_20_l : Types.ns_20_b} />
    </Flex>
  );
};

export default PanelText;
