import styled from "styled-components";

export const StyledLinkButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  div {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-weight: 400;
    font-style: italic;
  }
`;
