import React, { FormEvent, useEffect } from "react";
import { Flex, isNotEmpty, useToast } from "@emonvia/web-components";
import { Button } from "@/components/TrackedComponents/reevComponents";
import { translateError, useTranslation } from "@/utils/translations/Translator";
import SubHeader from "@/components/SubHeader/SubHeader";
import AdditionalModelsDescriptionFrame from "@/containers/NewIntegration/Steps/AditionalModels/components/AdditionalModelsDescriptionFrame";
import AdditionalKeysModelsForm from "@/containers/NewIntegration/Steps/AditionalModels/components/AdditionalKeysModelsForm";
import useAdditionalModelForm from "@/containers/NewIntegration/Steps/AditionalModels/hooks/useAdditionalModelForm";
import ReevApiCalls from "@/api/ReevApiCalls";
import { useIntegration } from "@/hooks/useIntegration";
import { IModelIntegrationRequest } from "@/api/ApiTypes";
import { constructRequest } from "@/containers/NewIntegration/Steps/AditionalModels/utils/utils";

const AdditionalModels: React.FC = () => {
  const {
    data: { integrationId, models },
    goNextWithUpdate
  } = useIntegration();
  const { t } = useTranslation();
  const form = useAdditionalModelForm();
  const { notifyError } = useToast();

  useEffect(() => {
    const additionalModels =
      isNotEmpty(models) && models.filter((model) => model.isAdditionalModel === true);
    if (isNotEmpty(additionalModels)) {
      form.reset({ models: additionalModels });
    }
  }, [models]);

  const updateIntegrationAndGoToNextStep = async (payload: IModelIntegrationRequest) => {
    try {
      const modifiedPayload = constructRequest(payload);
      const response = await ReevApiCalls.updateAdditionalModels({
        pathParams: { integrationId },
        payload: { models: modifiedPayload }
      });
      goNextWithUpdate({
        models: response.data.models
      });
    } catch (e) {
      notifyError(translateError(e));
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    form.handleSubmit(updateIntegrationAndGoToNextStep)();
  };

  return (
    <Flex flexDirection="column" gap={20}>
      <SubHeader />
      <form onSubmit={onSubmit}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" gap={20}>
          <Flex flexDirection="column" gap={12} width="100%">
            <AdditionalModelsDescriptionFrame />
            <AdditionalKeysModelsForm form={form} />
          </Flex>
          <Button
            data-testid={"AdditionalModels_Button"}
            label={t("CONFIRM")}
            useNunitoFont
            width="100%"
            type={"submit"}
            margin={[20, 0, 0, 0]}
            disabled={!form.formState.isValid || form.formState.isSubmitting}
          />
        </Flex>
      </form>
    </Flex>
  );
};

export default AdditionalModels;
