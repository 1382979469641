import React, { useContext, useEffect } from "react";
import { initializeTracking, usePageViewTracking, usePostHog } from "@/hooks/usePostHog";
import AllRoutes, { PathValues } from "@/routing/AllRoutes";

export const DefaultOnboardingStepNameByRoute: {
  [key in PathValues]: string;
} = {
  [AllRoutes.START]: "Start Integration",
  [AllRoutes.VENDOR]: "Vendor",
  [AllRoutes.MODEL]: "Model",
  [AllRoutes.DEFAULT_CONFIG]: "Default Keys",
  [AllRoutes.ADDITIONAL_DEFAULT_CONFIG]: "Add Default Keys",
  [AllRoutes.CUSTOM_ACCESS_CONTROL_CONFIG_MAP]: "Add Custom Keys",
  [AllRoutes.ADDITIONAL_MODEL]: "Additional Models",
  [AllRoutes.REVIEW]: "Report"
};

export const getOnboardingStepFromCurrentRoute = () => {
  return DefaultOnboardingStepNameByRoute[window.location.pathname as PathValues] || "Unknown step";
};

export const useTracking = <
  Callback extends FunctionType,
  CallbackArgs extends Parameters<Callback>
>({
  callback,
  eventMessage
}: {
  callback: Callback;
  eventMessage: string | ((...args: CallbackArgs) => string);
}) => {
  const { shouldTrack, onboardingStep } = useTrackingContext();
  const { trackEvent } = usePostHog();

  if (typeof callback !== "function") {
    return callback;
  }

  if (!shouldTrack) {
    return callback;
  }

  return (...args: CallbackArgs) => {
    let message: string;

    if (typeof eventMessage === "function") {
      message = eventMessage(...args);
    } else {
      message = eventMessage;
    }

    trackEvent({
      eventName: message,
      properties: {
        Component: onboardingStep || getOnboardingStepFromCurrentRoute()
      }
    });
    return callback(...args);
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionType = (...args: any[]) => any;

export const withTracking = <
  Props extends { [key in ActionPropName]?: Callback },
  ActionPropName extends keyof Props,
  Callback extends FunctionType,
  ActionArgs extends Parameters<Props[ActionPropName]>
>(
  Component: React.FC<Props>,
  {
    actionAccessor,
    generateMessage
  }: {
    actionAccessor: ActionPropName;
    generateMessage: (props: Props, ...actionArgs: ActionArgs) => string;
  }
) => {
  type TrackedComponentProps = Props & { shouldTrack?: boolean };

  const WrappedComponent: React.FC<TrackedComponentProps> = (props) => {
    const untrackedHandler = props[actionAccessor];

    const trackedHandler = useTracking({
      callback: untrackedHandler,
      eventMessage: (...args: ActionArgs) => generateMessage(props, ...args)
    });

    const actionHandler = props.shouldTrack === false ? untrackedHandler : trackedHandler;

    const componentProps = {
      ...props,
      [actionAccessor]: actionHandler
    };

    return <Component {...componentProps} />;
  };

  return WrappedComponent;
};

interface ITrackingContext {
  shouldTrack?: boolean;
  onboardingStep?: string;
  children?: React.ReactNode;
}

const TrackingContext = React.createContext<ITrackingContext>({
  shouldTrack: true,
  onboardingStep: null
});

export const TrackingContextProvider: React.FC<ITrackingContext> = (props) => {
  const { children, shouldTrack = true, onboardingStep } = props;

  useEffect(initializeTracking, []);
  usePageViewTracking();

  return (
    <TrackingContext.Provider value={{ shouldTrack, onboardingStep }}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTrackingContext = () => {
  return useContext(TrackingContext);
};
