import React, { useState } from "react";
import { Flex, useToast } from "@emonvia/web-components";
import { useNavigate, useParams } from "react-router-dom";
import AllRoutes from "@/routing/AllRoutes";
import Sidebar from "@/components/Sidebar/Sidebar";
import SidebarHeader from "@/components/Sidebar/SidebarHeader";
import ModelOverviewSection from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/sections/ModelOverviewSection";
import VendorFallbackConfigurationTemplateSection from "@/containers/VendorConfigurations/components/VendorConfigurationDetails/section/VendorFallbackConfigurationTemplateSection";
import ModelConfigurationTemplateSection from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/sections/ModelConfigurationTemplateSection";
import ModelFallbackConfigurationTemplateSection from "@/containers/ModelConfigurations/components/ModelConfigurationsDetails/sections/ModelFallbackConfigurationTemplateSection";
import useModelConfigurationQuery from "@/hooks/useModelConfigurationQuery";
import ReevApiCalls from "@/api/ReevApiCalls";
import ModelQueryKeys from "@/containers/ModelConfigurations/utils/QueryKeys";
import { useQueryClient } from "react-query";
import { Status } from "@/utils/enums/Status";
import { translateError, useTranslation } from "@/utils/translations/Translator";

type StatusType = keyof typeof Status;

const ChargePointApprovalDetails: React.FC = () => {
  const { vendor, model, firmwareId } = useParams();
  const { notifySuccess, notifyInfo, notifyError } = useToast();
  const [disableAction, setDisableAction] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useModelConfigurationQuery({
    vendor,
    model,
    id: firmwareId
  });

  const {
    model: modelName,
    vendor: vendorName,
    firmware,
    verificationType,
    calibrationType,
    startupConfiguration,
    vendorFallbackConfigurationTemplates = [],
    configurationTemplates = [],
    modelFallbackConfigurationTemplates = [],
    connectionSchema,
    interfacePort
  } = data || {};
  const toggleSidebar = () => {
    navigate(AllRoutes.CHARGE_POINT_APPROVAL_INDEX);
  };

  const handleNotification = (status: StatusType) => {
    if (status === "APPROVED") {
      notifySuccess(t("CONFIGURATION_TEMPLATE_PROMOTED"));
    } else if (status === "DISCARDED") {
      notifyInfo(t("CONFIGURATION_TEMPLATE_DELETED"));
    }
  };

  const onStatusHandler = async (status: StatusType) => {
    try {
      await ReevApiCalls.updateFirmwareStatus({
        pathParams: { firmwareId, status }
      });
      setDisableAction(true);
      handleNotification(status);
      toggleSidebar();
      await fetchQueries();
    } catch (e) {
      setDisableAction(false);
      notifyError(translateError(e));
    }
  };

  const fetchQueries = async () => {
    await queryClient.resetQueries(ModelQueryKeys.APPROVAL_LIST);
    await refetch();
  };

  return (
    <Sidebar
      title={
        <SidebarHeader
          label={`${vendor}, ${model}`}
          showActions
          disableAction={disableAction}
          onApprove={() => onStatusHandler(Status.APPROVED)}
          onDiscard={() => onStatusHandler(Status.DISCARDED)}
        />
      }
      onClose={toggleSidebar}
      isLoading={isLoading}
    >
      <Flex flexDirection="column" padding={[24, 0, 0]} gap={48}>
        <Flex flexDirection="row">
          <ModelOverviewSection
            vendor={vendorName}
            model={modelName}
            firmwareVersion={firmware}
            calibrationType={calibrationType}
            verificationType={verificationType}
            startupConfiguration={startupConfiguration}
            refetchDetails={refetch}
            config={configurationTemplates}
            interfacePort={interfacePort}
            connectionSchema={connectionSchema}
            isApproval
          />
        </Flex>
        <Flex flexDirection="row">
          <VendorFallbackConfigurationTemplateSection
            fallbackConfiguration={vendorFallbackConfigurationTemplates}
            refetchDetails={refetch}
          />
        </Flex>
        <Flex flexDirection="row">
          <ModelConfigurationTemplateSection
            modelConfigurationTemplates={configurationTemplates}
            firmwareVersion={firmware}
            refetchDetails={refetch}
            vendor={vendorName}
            model={modelName}
          />
        </Flex>
        <Flex flexDirection="row">
          <ModelFallbackConfigurationTemplateSection
            modelFallbackConfigurationTemplates={modelFallbackConfigurationTemplates}
            refetchDetails={refetch}
            vendor={vendorName}
            model={modelName}
          />
        </Flex>
      </Flex>
    </Sidebar>
  );
};

export default ChargePointApprovalDetails;
