import { IModelDetails, IPaginatedModelQueryRequest } from "@/api/ApiTypes";
import Table, { NoMatchesMessage } from "@/components/Table/Table";
import ModelConfigurationsTableFilters, {
  IModelConfigurationsFilters
} from "@/containers/ModelConfigurations/components/ModelConfigurationsTableFilters";
import useModelConfigurationsTableColumns from "@/containers/ModelConfigurations/hooks/useModelConfigurationsTableColumns";
import { useTranslation } from "@/utils/translations/Translator";
import { Flex, isEmpty, isNotEmpty, Types, useTableQueryParams } from "@emonvia/web-components";
import { useNavigate } from "react-router-dom";
import { REPLACE_FIRST_VALUE } from "@/utils/regexp";
import { Status } from "@/utils/enums/Status";
import { useFetchModelsQuery } from "@/hooks/useFetchModelsQuery";
import ModelQueryKeys from "@/containers/ModelConfigurations/utils/QueryKeys";
import BackButtonWithTitle from "@/components/SubHeader/components/BackButtonWithTitle";

const ModelConfigurationsTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns = useModelConfigurationsTableColumns();

  const { filters, setFilters, page, pageSize, setQueryParams, sortOrder, sortBy } =
    useTableQueryParams<IModelConfigurationsFilters, IPaginatedModelQueryRequest["sortBy"]>({
      mergeParamsFromSearchQueryIntoFilters: true,
      navigate
    });

  const { data, isLoading } = useFetchModelsQuery({
    queryKey: ModelQueryKeys.MODEL_LIST,
    page,
    pageSize,
    sortBy,
    sortOrder,
    status: Status.APPROVED,
    filters
  });

  const { data: models, ...meta } = data || {};

  const list = models?.map((model: IModelDetails) => ({
    ...model,
    id: `${model.id}/${model.vendor}/${model.model}/${model.id}`
  }));

  const goToModelDetails = (id: string) => {
    const newId = id.replace(REPLACE_FIRST_VALUE, "");
    navigate(`${newId}/config`);
  };

  const showNoMatchesMessage = isEmpty(list) && Object.values(filters).some(isNotEmpty);

  return (
    <>
      <Flex flexDirection="column" margin={[0, 0, 20, 0]}>
        <BackButtonWithTitle stepTitle={t("MODEL_LEVEL_CONFIGURATION")} />
      </Flex>
      <Flex flexDirection="column" gap={40}>
        <ModelConfigurationsTableFilters filters={filters} onChange={setFilters} />
        <Table
          data={list}
          isLoading={isLoading}
          columns={columns}
          setQueryParams={setQueryParams}
          meta={meta}
          inTotalTranslation={t("IN_TOTAL")}
          onRowClick={goToModelDetails}
          allowKeyboardNavigation
          paginationTextType={Types.ns_16_l}
        />
        {showNoMatchesMessage && <NoMatchesMessage />}
      </Flex>
    </>
  );
};

export default ModelConfigurationsTable;
