import { InferType, number, object, string } from "yup";
import { IModelDetails, ISmvProperty } from "@/api/ApiTypes";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type CalibrationType = IModelDetails["calibrationType"];
const calibrationTypes: CalibrationType[] = ["SMV", "MID", "STANDARD"];

type ConnectionSchema = IModelDetails["connectionSchema"];
const connectionSchemas: ConnectionSchema[] = ["NONE", "HTTP", "HTTPS"];

type OcppAction = ISmvProperty["ocppMessageModelPublicKey"] | "";
const ocppActions: OcppAction[] = ["DataTransfer", "GetConfiguration", ""];

type VerificationType = ISmvProperty["verificationTypeOfModelsSignedMeterValue"];
const verificationTypes: VerificationType[] = [
  "OCMF",
  "ALFEN",
  "EDL_40_MENNEKES",
  "EDL_40_P",
  "EDL_40_SIG",
  "ISA_EDL_40_P",
  "PCDF",
  "UNKNOWN"
];

const schema = object().shape({
  calibrationType: string().oneOf(calibrationTypes),
  connectionSchema: string().oneOf(connectionSchemas),
  interfacePort: number(),
  ocppAction: string().oneOf(ocppActions),
  configurationKey: string(),
  verificationType: string().oneOf(verificationTypes)
});

export type ModelOverviewSectionFormValues = InferType<typeof schema>;

const useModelOverviewSectionForm = (defaultValues: ModelOverviewSectionFormValues) => {
  return useForm({
    resolver: yupResolver<ModelOverviewSectionFormValues>(schema),
    defaultValues
  });
};

export default useModelOverviewSectionForm;
