import queryString from "query-string";
import { ajaxService, IInvokeOptions } from "src/api/AjaxService";
import {
  IModelRequest,
  IChargePointIntegrationVendorRequest,
  IChargePointIntegrationVendorResponse,
  IModel,
  IModelIntegrationResponse,
  ICustomAccessControlMapIntegrationRequest,
  ICustomAccessControlMapIntegrationResponse,
  IModelIntegrationRequest,
  IDefaultConfigIntegrationRequest,
  IDefaultConfigIntegrationResponse,
  IVendorConfigurationResponse,
  IFallbackConfiguration,
  IConfigurationTemplateIntegration,
  IChargePointIntegrationResponse,
  IChargePointIntegrationCompleteResponse,
  IConfigurationTemplateResponse,
  IVendorPaginatedQueryRequest,
  IVendorsResponse,
  IModelResponse,
  IPaginatedModelQueryRequest,
  IModelsResponse
} from "./ApiTypes";

export default class ReevApiCalls {
  public static updateModel(
    request: { pathParams: { vendorName: string; modelName: string }; payload: IModelRequest },
    options?: IInvokeOptions
  ) {
    return ajaxService.put(
      `/models/${request.pathParams.vendorName}/models/${request.pathParams.modelName}`,
      request.payload,
      options
    );
  }

  public static updateIntegrationVendor(
    request: {
      pathParams: { integrationId: string };
      payload: IChargePointIntegrationVendorRequest;
    },
    options?: IInvokeOptions
  ) {
    return ajaxService.put<IChargePointIntegrationVendorResponse>(
      `/integrations/${request.pathParams.integrationId}/vendor`,
      request.payload,
      options
    );
  }

  public static updateState(
    request: {
      pathParams: {
        integrationId: string;
        state:
          | "START"
          | "VENDOR"
          | "MODEL"
          | "ADDITIONAL_MODEL"
          | "DEFAULT_CONFIG"
          | "ADDITIONAL_DEFAULT_CONFIG"
          | "CUSTOM_ACCESS_CONTROL_CONFIG_MAP"
          | "REVIEW"
          | "COMPLETE";
      };
    },
    options?: IInvokeOptions
  ) {
    return ajaxService.put(
      `/integrations/${request.pathParams.integrationId}/state/${request.pathParams.state}`,
      null,
      options
    );
  }

  public static createModel(
    request: { pathParams: { integrationId: string }; payload: IModel },
    options?: IInvokeOptions
  ) {
    return ajaxService.put<IModelIntegrationResponse>(
      `/integrations/${request.pathParams.integrationId}/models`,
      request.payload,
      options
    );
  }

  public static updateCustomAccessControlMap(
    request: {
      pathParams: { integrationId: string };
      payload: ICustomAccessControlMapIntegrationRequest;
    },
    options?: IInvokeOptions
  ) {
    return ajaxService.put<ICustomAccessControlMapIntegrationResponse>(
      `/integrations/${request.pathParams.integrationId}/custom-access-control-map`,
      request.payload,
      options
    );
  }

  public static updateAdditionalModels(
    request: { pathParams: { integrationId: string }; payload: IModelIntegrationRequest },
    options?: IInvokeOptions
  ) {
    return ajaxService.put<IModelIntegrationResponse>(
      `/integrations/${request.pathParams.integrationId}/additional-models`,
      request.payload,
      options
    );
  }

  public static updateAdditionalDefaultConfigs(
    request: { pathParams: { integrationId: string }; payload: IDefaultConfigIntegrationRequest },
    options?: IInvokeOptions
  ) {
    return ajaxService.put<IDefaultConfigIntegrationResponse>(
      `/integrations/${request.pathParams.integrationId}/additional-default-configs`,
      request.payload,
      options
    );
  }

  public static updateFirmwareStatus(
    request: { pathParams: { firmwareId: string; status: "APPROVED" | "PENDING" | "DISCARDED" } },
    options?: IInvokeOptions
  ) {
    return ajaxService.put(
      `/firmwares/${request.pathParams.firmwareId}/status/${request.pathParams.status}`,
      null,
      options
    );
  }

  public static getVendorFallbackConfig(
    request: { pathParams: { vendor: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IVendorConfigurationResponse>(
      `/fallback-templates/vendors/${request.pathParams.vendor}`,
      options
    );
  }

  public static updateVendorFallbackConfig(
    request: { pathParams: { vendor: string }; payload: IFallbackConfiguration },
    options?: IInvokeOptions
  ) {
    return ajaxService.put(
      `/fallback-templates/vendors/${request.pathParams.vendor}`,
      request.payload,
      options
    );
  }

  public static updateModelFallback(
    request: { pathParams: { vendor: string; model: string }; payload: IFallbackConfiguration },
    options?: IInvokeOptions
  ) {
    return ajaxService.put(
      `/fallback-templates/vendors/${request.pathParams.vendor}/models/${request.pathParams.model}`,
      request.payload,
      options
    );
  }

  public static updateFirmwareConfigTemplate(
    request: {
      pathParams: { vendor: string; model: string; firmwareVersion: string };
      payload: IConfigurationTemplateIntegration;
    },
    options?: IInvokeOptions
  ) {
    return ajaxService.put(
      `/configs/vendors/${request.pathParams.vendor}/models/${request.pathParams.model}/firmwareVersions/${request.pathParams.firmwareVersion}`,
      request.payload,
      options
    );
  }

  public static createNewIntegration(options?: IInvokeOptions) {
    return ajaxService.post<IChargePointIntegrationResponse>(`/integrations`, null, options);
  }

  public static integrateNewVendorToIntegrationService(
    request: { pathParams: { integrationId: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.post<IChargePointIntegrationCompleteResponse>(
      `/integrations/${request.pathParams.integrationId}/integrate`,
      null,
      options
    );
  }

  public static getConfigTemplate(
    request: { pathParams: { vendor: string; model: string; firmware: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IConfigurationTemplateResponse>(
      `/configs/vendors/${request.pathParams.vendor}/models/${request.pathParams.model}/firmwareVersions/${request.pathParams.firmware}`,
      options
    );
  }

  public static addFirmwareConfigTemplate(
    request: {
      pathParams: { vendor: string; model: string; firmware: string };
      payload: IConfigurationTemplateIntegration;
    },
    options?: IInvokeOptions
  ) {
    return ajaxService.post(
      `/configs/vendors/${request.pathParams.vendor}/models/${request.pathParams.model}/firmwareVersions/${request.pathParams.firmware}`,
      request.payload,
      options
    );
  }

  public static getAllVendors(
    request: { queryParams: { request: IVendorPaginatedQueryRequest } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IVendorsResponse>(
      `/vendors?${buildQueryString(request?.queryParams)}`,
      options
    );
  }

  public static getSupportedModelsByVendor(
    request: {
      queryParams: { request: IVendorPaginatedQueryRequest };
      pathParams: { vendor: string };
    },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<Array<IModelResponse>>(
      `/vendors/${request.pathParams.vendor}/models?${buildQueryString(request?.queryParams)}`,
      options
    );
  }

  public static getAllModels(
    request: { queryParams: { request: IPaginatedModelQueryRequest } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IModelsResponse>(
      `/models?${buildQueryString(request?.queryParams)}`,
      options
    );
  }

  public static getIntegration(
    request: { pathParams: { integrationId: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IChargePointIntegrationResponse>(
      `/integrations/${request.pathParams.integrationId}`,
      options
    );
  }

  public static getConfigTemplateByFirmwareId(
    request: { pathParams: { vendor: string; model: string; firmwareId: string } },
    options?: IInvokeOptions
  ) {
    return ajaxService.get<IConfigurationTemplateResponse>(
      `/configs/vendors/${request.pathParams.vendor}/models/${request.pathParams.model}/firmwareId/${request.pathParams.firmwareId}`,
      options
    );
  }
}

export function buildQueryString(queryParams?: Record<string, unknown>, post?: string) {
  return `${
    !!queryParams
      ? `${queryString.stringify(queryParams.request, { skipNull: true })}${
          !!post ? "&" + post : ""
        }`
      : ""
  }`;
}
