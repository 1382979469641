import styled from "styled-components";
import { headerHeightPx } from "@/components/Header/styles";
import theme from "@/utils/theme/theme";
import { Sidebar, StyledText } from "@emonvia/web-components";

export const SidebarContainer = styled(Sidebar)`
  margin-top: ${headerHeightPx}px;
  & .sidebar-header {
    position: absolute;
  }
  & .sidebar-content {
    background-color: ${theme.colors.primaryWhite};
    margin: ${headerHeightPx}px 0;
  }
  & .close-button {
    top: 36px;
  }
`;

export const TruncatedTitle = styled(StyledText)`
  width: 324px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
