import Frame from "@/components/Frame/Frame";
import ModelConfigurationsTable from "@/containers/ModelConfigurations/components/ModelConfigurationsTable";
import { Outlet } from "react-router-dom";

const ModelConfigurationsIndex = () => {
  return (
    <Frame look="secondary">
      <ModelConfigurationsTable />
      <Outlet />
    </Frame>
  );
};

export default ModelConfigurationsIndex;
