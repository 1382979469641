import {
  ConfigKeyType,
  EditConfigKeysFormValues
} from "@/components/EditConfigModal/hooks/useEditConfigForm";
import {
  IConfigurationTemplate,
  ICustomAccessControlMap,
  IFallbackConfiguration
} from "@/api/ApiTypes";

export interface IConfigTableData extends ICustomAccessControlMap {
  id: string;
}

type useCaseLowerCaseTypes = "open" | "restricted" | "default";

export function fallbackConfigurationMapper(
  configuration: IConfigurationTemplate[]
): IConfigTableData[] {
  const configurations: IConfigTableData[] = [];

  configuration?.forEach((config) => {
    Object.entries(config.properties ?? {}).forEach(([key, value]) => {
      configurations.push({
        id: key,
        configKey: key,
        value: value,
        useCase:
          config.useCase === "DEFAULT"
            ? null
            : (config.useCase.toLowerCase() as IConfigTableData["useCase"])
      });
    });
  });

  return configurations;
}

export function filterDefaultConfigurationKeys(config: IConfigTableData[]): IConfigTableData[] {
  return config.filter((it) => it.useCase == null || it.useCase.toUpperCase() === "DEFAULT");
}

export function filterAuthorizationConfigurationKeys(
  config: IConfigTableData[]
): IConfigTableData[] {
  return config.filter((it) => it.useCase != null && it.useCase.toUpperCase() !== "DEFAULT");
}

export function toConfigFormType(config: IConfigTableData[]) {
  return {
    config: config.map(({ configKey, value, useCase }) => ({
      key: configKey,
      value,
      type: useCaseToType(useCase as useCaseLowerCaseTypes)
    }))
  };
}

export function formValuesToRequest(values: EditConfigKeysFormValues): IFallbackConfiguration {
  const request: IFallbackConfiguration = {
    defaultUseCase: {},
    openUseCase: {},
    restrictedUseCase: {}
  };

  values.config.forEach(({ key, value, type }) => {
    switch (type) {
      case ConfigKeyType.OPEN:
        request.openUseCase[key] = value;
        break;
      case ConfigKeyType.RESTRICTED:
        request.restrictedUseCase[key] = value;
        break;
      case ConfigKeyType.DEFAULT:
        request.defaultUseCase[key] = value;
        break;
    }
  });

  return request;
}

function useCaseToType(useCase: useCaseLowerCaseTypes): ConfigKeyType {
  switch (useCase) {
    case "open":
      return ConfigKeyType.OPEN;
    case "restricted":
      return ConfigKeyType.RESTRICTED;
    default:
      return ConfigKeyType.DEFAULT;
  }
}
