import { StyledText } from "@emonvia/web-components";
import styled from "styled-components";

export const TextContainer = styled(StyledText)`
  display: inline-block;
  line-height: 24px;

  strong,
  b {
    font-family: ${({ theme }) => theme.fonts.medium};
  }
`;
