import { ClickableIcon } from "@/components/FieldArray/styles";
import ValidatedTextInput from "@/components/ValidatedTextInput/ValidatedTextInput";
import { TransKey, useTranslation } from "@/utils/translations/Translator";
import { Flex, IconTypes, LinkButton, Types } from "@emonvia/web-components";
import { FC } from "react";
import { Control, Controller, FieldValues, useFieldArray } from "react-hook-form";

type IProps = {
  name: string;
  control: Control<FieldValues>;
  placeholder?: string;
};

const FieldArray: FC<IProps> = ({ name, control, placeholder }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  return (
    <Flex flexDirection="column" gap={20}>
      {fields.map((field, index) => (
        <Controller
          key={field.id}
          name={`${name}[${index}].name`}
          control={control}
          render={({ fieldState: { error }, field: fieldProps }) => (
            <ValidatedTextInput
              {...fieldProps}
              placeholder={placeholder}
              errorMessage={error?.message as TransKey}
              removeSpaces={false}
              endElement={
                index > 0 && (
                  <ClickableIcon
                    type={IconTypes.trash}
                    color="primaryBlack"
                    onClick={() => remove(index)}
                  />
                )
              }
            />
          )}
        />
      ))}
      <LinkButton
        onClick={() => append({})}
        label={`+ ${t("ADD_ANOTHER")}`}
        look="primary"
        fontType={Types.ns_14_m}
        color="primaryBlack"
        width="fit-content"
      />
    </Flex>
  );
};

export default FieldArray;
