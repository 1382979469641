import { IDefaultConfigIntegrationRequest } from "@/api/ApiTypes";
import { AddDefaultKeysFormValues } from "@/containers/NewIntegration/Steps/AddDefaultKeys/hooks/useAddDefaultKeysForm";

export const constructRequest = (
  formValues: AddDefaultKeysFormValues
): IDefaultConfigIntegrationRequest => {
  return {
    configs: formValues.configs
      .filter(({ key, value }) => key && value)
      .map((config) => ({ ...config, isAdditionalDefaultConfig: true }))
  } as IDefaultConfigIntegrationRequest;
};
