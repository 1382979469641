import React, { useState } from "react";
import { TextInput, IconButton, StyledText, Types, IconTypes, Flex } from "@emonvia/web-components";
import styled from "styled-components";
import theme from "@/utils/theme/theme";

interface EditableTextFieldProps {
  value: string;
  onUpdate: (newValue: string) => void;
  inputWidth?: number;
  color?: string;
}

export const TextInputData = styled(TextInput)`
  padding: 0;
`;

const TextOrInputField: React.FC<EditableTextFieldProps> = ({
  value,
  onUpdate,
  inputWidth = 300,
  color = theme.colors.primaryBlack
}) => {
  const [contentEditable, setContentEditable] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleUpdate = () => {
    onUpdate(inputValue);
    setContentEditable(false);
  };

  return (
    <Flex alignItems="baseline">
      {!contentEditable && <StyledText text={value} type={Types.ns_20_l} color={color} />}
      {contentEditable && (
        <TextInputData
          width={inputWidth}
          value={inputValue}
          onChange={(value) => setInputValue(value)}
          enterPressHandler={handleUpdate}
        />
      )}
      <IconButton
        icon={contentEditable ? IconTypes.check : IconTypes.pen}
        look="secondary"
        shape="round"
        margin={[0, 16, 8]}
        onClick={() => {
          if (contentEditable) {
            handleUpdate();
          } else {
            setContentEditable(true);
          }
        }}
      />
    </Flex>
  );
};

export default TextOrInputField;
