import Frame from "@/components/Frame/Frame";
import { useTranslation } from "@/utils/translations/Translator";
import { IconTypes } from "@emonvia/web-components";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import FieldGroupArray from "@/components/FieldGroupArray/FieldGroupArray";
import {
  calibrationOptions,
  ocppMessageModelPublicKeyOptions,
  verificationTypeOfModelsSignedMeterValueOptions
} from "@/containers/NewIntegration/Steps/Model/hooks/useModelForm";
import { AdditionalModelFormValues } from "@/containers/NewIntegration/Steps/AditionalModels/hooks/useAdditionalModelForm";
import EvStation from "@/assets/images/ev_station.svg";
import Sdk from "@/assets/images/sdk.svg";
import Message from "@/assets/images/message.svg";
import IconBadge from "@/components/IconBadge/IconBadge";

type IProps = {
  form: UseFormReturn<AdditionalModelFormValues>;
};

const IconManager = (icon: string) => {
  return <IconBadge img={<img src={icon} alt={`${icon}-icon`} />} size={32} />;
};

const AdditionalKeysModelsForm: FC<IProps> = ({ form }) => {
  const { t } = useTranslation();
  const { watch } = form;
  const additionalModelConfig = watch("models");

  return (
    <Frame>
      <FieldGroupArray
        form={form}
        group={{
          name: "models",
          label: (number) => t("INFORMATION_FOR_MODEL_NUMBER", { number: number + 1 }),
          fields: [
            {
              name: "name",
              label: t("MODEL_NAME_FROM_BOOT_NOTIFICATION"),
              icon: IconManager(EvStation),
              placeholder: t("ENTER_MODEL_NAME")
            },
            {
              name: "firmwareVersion",
              label: t("FIRMWARE_VERSION"),
              icon: IconManager(Sdk),
              placeholder: t("ENTER_FIRMWARE_VERSION")
            },
            {
              name: "electricMeterCalibrationTypeOfModel",
              label: t("SMW_CALIBARION_TYPE_MODEL"),
              placeholder: t("SELECT_SMW_CALIBARION_TYPE_MODEL"),
              type: "select",
              icon: IconTypes.bolt,
              selectOptions: calibrationOptions
            },
            {
              name: "smvProperty.verificationTypeOfModelsSignedMeterValue",
              label: t("SMW_VERIFICATION_TYPE"),
              placeholder: t("SELECT_SMW_VERIFICATION_TYPE"),
              type: "select",
              icon: IconTypes.fingerprint,
              hidden: (index: number) =>
                additionalModelConfig?.[index]?.electricMeterCalibrationTypeOfModel !== "SMV",
              selectOptions: verificationTypeOfModelsSignedMeterValueOptions
            },
            {
              name: "smvProperty.ocppMessageModelPublicKey",
              label: t("OCPP_MESSAGE_MODEL_PUBLIC_KEY"),
              placeholder: t("SELECT_OCPP_MESSAGE_MODEL_PUBLIC_KEY"),
              type: "select",
              icon: IconManager(Message),
              hidden: (index: number) =>
                additionalModelConfig?.[index]?.electricMeterCalibrationTypeOfModel !== "SMV",
              selectOptions: ocppMessageModelPublicKeyOptions
            },
            {
              name: "smvProperty.vendorSpecificConfigKeyForPublicKey",
              label: t("VENDOR_SPECIFIC_PUBLIC_KEY"),
              placeholder: t("ENTER_VENDOR_SPECIFIC_PUBLIC_KEY"),
              hidden: (index: number) =>
                additionalModelConfig?.[index]?.electricMeterCalibrationTypeOfModel !== "SMV",
              icon: IconTypes.key
            }
          ]
        }}
      />
    </Frame>
  );
};

export default AdditionalKeysModelsForm;
