import React from "react";
import { useTranslation } from "@/utils/translations/Translator";
import { BorderPanel } from "@/components/Panel/styles";
import { Flex } from "@emonvia/web-components";
import PanelText from "@/components/Panel/PanelText";

type IProps = {
  vendorName: string;
  vendorOcppNames: string[];
};

const VendorOverviewSection: React.FC<IProps> = ({ vendorName = "", vendorOcppNames = [""] }) => {
  const { t } = useTranslation();
  return (
    <BorderPanel title={t("OVERVIEW")}>
      <Flex flexDirection="column" gap={16} flex={1}>
        <Flex flexDirection="row">
          <PanelText text={t("NAME")} />
          <PanelText text={vendorName} light />
        </Flex>
        <Flex flexDirection="row">
          <PanelText text={t("OCPP_NAMES")} />
          <PanelText text={vendorOcppNames?.join(", ")} light />
        </Flex>
      </Flex>
    </BorderPanel>
  );
};
export default VendorOverviewSection;
