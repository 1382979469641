const ModelQueryKeys = {
  MODEL_LIST: "MODEL-LIST",
  APPROVAL_LIST: "APPROVAL_LIST",
  DETAIL: "MODEL-DETAIL",
  FIRMWARE_VERSION: "FIRMWARE_VERSION",
  VENDOR_MODELS: "VENDOR_MODELS"
};

export type ModelQueryType = typeof ModelQueryKeys[keyof typeof ModelQueryKeys];

export default ModelQueryKeys;
