import SearchBox from "@/components/SearchBox/SearchBox";
import Table, { NoMatchesMessage } from "@/components/Table/Table";
import useVendorConfigurationsTableColumns from "@/containers/VendorConfigurations/hooks/useVendorConfigurationsTableColumns";
import { useTranslation } from "@/utils/translations/Translator";
import { Flex, isEmpty, isNotEmpty, Types, useTableQueryParams } from "@emonvia/web-components";
import { useNavigate } from "react-router-dom";
import { IVendorPaginatedQueryRequest } from "@/api/ApiTypes";
import { useVendorsQuery } from "@/containers/VendorConfigurations/hooks/useVendorsQuery";
import BackButtonWithTitle from "@/components/SubHeader/components/BackButtonWithTitle";

type SortByType = "NAME" | "OCPP_NAMES";

const VendorConfigurationsTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns = useVendorConfigurationsTableColumns();

  const { filters, setFilters, page, pageSize, setQueryParams, sortOrder, sortBy } =
    useTableQueryParams<Partial<IVendorPaginatedQueryRequest>, SortByType>({
      mergeParamsFromSearchQueryIntoFilters: true,
      navigate
    });

  const { data, isLoading } = useVendorsQuery({
    page,
    pageSize,
    search: filters.search,
    sortBy,
    sortOrder
  });

  const goToVendorDetails = (vendor: string) => {
    navigate(`${vendor}/config`);
  };

  const { vendorDetails, ...meta } = data || {};

  const list =
    vendorDetails?.map(({ vendor, ocppNames }) => ({
      id: vendor,
      vendor,
      ocppNames
    })) || [];

  const showNoMatchesMessage = isEmpty(vendorDetails) && isNotEmpty(filters.search);

  return (
    <>
      <Flex flexDirection="column" margin={[0, 0, 20, 0]}>
        <BackButtonWithTitle stepTitle={t("VENDOR_LEVEL_CONFIGURATION")} />
      </Flex>
      <Flex flexDirection="column" gap={40}>
        <SearchBox
          allowClear
          onChange={(search: string) => setFilters({ search })}
          label={t("SEARCH")}
        />
        <Table
          data={list}
          columns={columns}
          setQueryParams={setQueryParams}
          isLoading={isLoading}
          meta={meta}
          inTotalTranslation={t("IN_TOTAL")}
          onRowClick={goToVendorDetails}
          allowKeyboardNavigation
          paginationTextType={Types.ns_16_l}
        />
        {showNoMatchesMessage && <NoMatchesMessage />}
      </Flex>
    </>
  );
};

export default VendorConfigurationsTable;
