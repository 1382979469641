import EditConfigModal from "@/components/EditConfigModal/EditConfigModal";
import { EditConfigKeysFormValues } from "@/components/EditConfigModal/hooks/useEditConfigForm";
import Text from "@/components/Text/Text";
import { useTranslation } from "@/utils/translations/Translator";
import { FC } from "react";
import { IConfigTableData, toConfigFormType } from "@/containers/VendorConfigurations/utils/mapper";

type IProps = {
  firmwareVersion: string;
  onClose: () => void;
  onSubmit: (config: EditConfigKeysFormValues) => void;
  config: IConfigTableData[];
};

const EditModelConfigurationForFirmwareVersionModal: FC<IProps> = ({
  firmwareVersion,
  onClose,
  onSubmit,
  config
}) => {
  const { t } = useTranslation();

  return (
    <EditConfigModal
      config={toConfigFormType(config)}
      onSubmit={onSubmit}
      onClose={onClose}
      title="EDIT_FIRMWARE_VERSIONS_FOR_MODEL_CONFIG"
      description={
        <Text color="grey750">
          <b>
            <u>{t("FIRMWARE_VERSION")}</u>
          </b>
          : {firmwareVersion}
        </Text>
      }
    />
  );
};

export default EditModelConfigurationForFirmwareVersionModal;
