export const AllRoutes: Record<string, string> = {
  START: "/start",

  // --------- create integration routes
  VENDOR: "/vendor",
  MODEL: "/model",
  DEFAULT_CONFIG: "/default-keys",
  ADDITIONAL_DEFAULT_CONFIG: "/add-default-keys",
  CUSTOM_ACCESS_CONTROL_CONFIG_MAP: "/add-custom-keys",
  ADDITIONAL_MODEL: "/additional-models",
  REVIEW: "/report",
  // ---------- configurations
  CHARGE_POINT_APPROVAL_INDEX: "/approval",
  VENDOR_CONFIGURATIONS_INDEX: "/integrated-vendors",
  MODEL_CONFIGURATIONS_INDEX: "/integrated-models"
} as const;

export type PathValues = typeof AllRoutes[keyof typeof AllRoutes];

export type RouteKey = keyof typeof AllRoutes;
export type Path = typeof AllRoutes[RouteKey];

export const globalNavigate = {
  // @typescript-eslint/no-unused-vars
  current: (_path: string): void | null => null
};

export const globalNotifyError = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  current: (_message: string): void | null => null
};

export default AllRoutes;
