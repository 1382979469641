import React from "react";
import { Flex } from "@emonvia/web-components";
import ProgressBar from "@/components/SubHeader/components/ProgressBar/ProgressBar";
import BackButtonWithTitle from "@/components/SubHeader/components/BackButtonWithTitle";
import { getCurrentIntegrationPageInfo } from "@/routing/NewIntegrationRoutes";

const SubHeader: React.FC = () => {
  const { totalSteps, activeStepNumber, stepTitle } = getCurrentIntegrationPageInfo();
  return (
    <Flex flexDirection="column" gap={20}>
      <BackButtonWithTitle stepTitle={stepTitle} />
      <ProgressBar totalSteps={totalSteps} activeStep={activeStepNumber} />
    </Flex>
  );
};

export default SubHeader;
